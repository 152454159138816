import React, { useState } from "react"
import { Box, Grid, Stack, Typography } from "@mui/material"
import TextInput from "../../components/TextField"
import ButtonComponent from "../../components/Button"
import CheckboxComponent from "../../components/Checkbox"
import RadioButton from "../../components/RadioButton"

import Auth from '../../client/API/Auth'
import { useDispatch } from "react-redux"
import { setMessage } from "../../store/reducers/message"
import { useNavigate } from "react-router-dom"

export default function Signup() {
  const [userObj, setUserObj] = useState({ roles: [] })
  const [error, setError] = useState({})
  const [isChecked, setIsChecked] = useState(false)
  const [loading, setLoading] = useState(false)
  const [displayText, setDisplayText] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const setFormValue = (field, val) => {
    if (field === 'roles' && val === 'Others') {
      setDisplayText(!displayText)
    } else if (field === 'roles') {
      if (userObj.roles.indexOf(val) < 0) {
        val = [...userObj.roles, val]
      } else {
        userObj.roles.splice(userObj.roles.indexOf(val), 1)
        val = [...userObj.roles]
      }
    }
    if (val !== 'Others') {
      setUserObj({
        ...userObj,
        [field]: val
      })
    }
  }

  const signup = async () => {
    let errored = false
    const fields = [
      { id: 'firstName', name: 'First Name' },
      // { id: 'lastName', name: 'Last Name' },
      { id: 'email', name: 'Email Address' },
      { id: 'password', name: 'Password' },
      // { id: 'phoneNumber', name: 'Phone Number' },
      // { id: 'companyType', name: 'company Type' },
      // { id: 'companyName', name: 'Company Name' }
    ]
    fields.some(({ id, name }) => {
      if (!userObj[id]) {
        setError({
          [id]: `Please enter ${name}`
        })
        errored = true
        return true
      }
      return false
    })

    if (!errored) {
      if (userObj.email &&
        !userObj.email.match('^[a-zA-Z0-9.%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9-]+.[a-zA-Z0-9-.]{2,61}$')) {
        return setError({
          email: 'Please Enter work email'
        })
      }
      if (userObj.password && userObj.password.length < 8) {
        return setError({
          password: 'Password should be of 8 characters'
        })
      }
      // if (!userObj.roles.length && !userObj.others) {
      //   return setError({
      //     roles: 'Please select a role'
      //   })
      // }
      if (!isChecked) {
        return setError({
          terms: 'Please read and accept the Terms of Service and Privacy Policy'
        })
      }
      setError({})
      setLoading(true)
      const roles = userObj.roles.toString()
      const res = await Auth.signupAPI({
        firstName: userObj.firstName,
        lastName: userObj.lastName || '',
        user: {
          mailId: userObj.email.trim(),
          password: userObj.password,
          phoneNo: userObj.phoneNumber || '',
          role: 'ROLE_RECRUITER'
        },
        companyType: userObj.companyType || '',
        companyName: userObj.companyName || '',
        rolesRecruited: roles.length ? `${roles}${userObj.others ? `,${userObj.others}` : ''}` : userObj.others || '',
      })
      setLoading(false)
      if (typeof res !== 'string') {
        dispatch(setMessage({
          type: 'success',
          text: `Your Sign up is completed, a community representative will be calling you shortly to get your request approved.
            If you need priority access, text or What’s app on 510-458-8793`,
          autoHide: false,
          horizontal: 'center'
        }))
        navigate('/')
      } else {
        dispatch(setMessage({ type: 'error', text: res }))
      }
    }
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <Box sx={{ backgroundColor: 'colors.white', width: '32rem', padding: { lg: '1.3rem 5rem', md: '1.3rem 5rem', sm: '1.3rem 2rem', xs: '1.3rem 2rem' }, boxShadow: '0px 2px 30px #ccc6', margin: 'auto' }}>

        <Box sx={{ fontWeight: 'bold', fontSize: '1.5rem' }}>
          Try The Talent Community for free
          <Typography component='p' sx={{ fontSize: '1rem', margin: '0.6rem 0' }}>We have 1k+ qualified project managers and java engineers waiting for you! Everyone can work on your W2</Typography>
        </Box>

        <Typography component='form' sx={{ margin: '1.3rem 0' }} onSubmit={e => { e.preventDefault(); signup() }}>
          <Stack direction={'row'} spacing={6}>
            <TextInput
              sx={{ marginBottom: '1.3rem' }}
              value={userObj.firstName || ''}
              variant='outlined'
              name='firstName'
              label='First Name'
              onChange={setFormValue}
              error={error.firstName}
              autoFocus={true}
              required={true}
            />
            <TextInput
              sx={{ marginBottom: '1.3rem' }}
              value={userObj.email || ''}
              variant='outlined'
              name='email'
              label='Email Address'
              onChange={setFormValue}
              error={error.email}
              required={true}
            />
            {/*<TextInput
              sx={{ marginBottom: '1.3rem' }}
              value={userObj.lastName || ''}
              variant='outlined'
              name='lastName'
              label='Last Name'
              onChange={setFormValue}
              error={error.lastName}
              required={true}
            />*/}
          </Stack>
          {/* <TextInput
            sx={{ marginBottom: '1.3rem' }}
            value={userObj.email || ''}
            variant='outlined'
            name='email'
            label='Email Address'
            onChange={setFormValue}
            error={error.email}
            required={true}
          /> */}
          <TextInput
            sx={{ marginBottom: '1.3rem' }}
            value={userObj.password || ''}
            variant='outlined'
            name='password'
            label='Password'
            onChange={setFormValue}
            error={error.password}
            type='password'
            required={true}
          />
          {/* <TextInput
            sx={{ marginBottom: '1.3rem' }}
            value={userObj.phoneNumber || ''}
            variant='outlined'
            name='phoneNumber'
            label='Phone Number (with country code)'
            onChange={setFormValue}
            error={error.phoneNumber}
            required={true}
          /> */}
          {/* <RadioButton
            name='companyType'
            options={[
              {
                label: 'Recruiter / Staffing',
                value: 'Recruiter / Staffing'
              },
              {
                label: 'Corporate / In-house Recruiter',
                value: 'Corporate / In-house Recruiter'
              }
            ]}
            label='What type of Company do you work for?'
            value={userObj.companyType || ''}
            handleChange={setFormValue}
            error={error.companyType}
            required={true}
          /> */}
          {/* <Box sx={{ fontWeight: 'bold', fontSize: '1rem', marginBottom: '0.5rem' }}>
            Top roles you recruit<Typography component={'sup'} sx={{ color: 'colors.error.red' }}>*</Typography>
          </Box> */}
          {error.roles && <Box sx={{ color: 'colors.error.red', margin: '1rem 0' }}>{error.roles}</Box>}
          {/* <Grid container spacing={0}>
            {
              ['Project Manager', 'Product Manager', 'Java', 'Dev Ops', 'Business Analyst', 'Data Analyst', 'Others'].map(data =>
                <Grid key={data} item lg={6}>
                  <CheckboxComponent
                    sx={{ marginBottom: '0rem' }}
                    label={data}
                    onChange={() => setFormValue('roles', data)}
                  />
                </Grid>
              )
            }
          </Grid> */}
          {displayText && <TextInput
            sx={{ marginBottom: '1.3rem', width: '100%' }}
            value={userObj.others || ''}
            variant='outlined'
            name='others'
            label='Other roles you recruit for'
            onChange={setFormValue}
            error={displayText && error.others}
          />}
          {/* <TextInput
            sx={{ marginBottom: '1.3rem' }}
            value={userObj.companyName || ''}
            variant='outlined'
            name='companyName'
            label='Your Company Name'
            onChange={setFormValue}
            error={error.companyName}
            required={true}
          /> */}

          <CheckboxComponent
            checked={isChecked}
            error={error.terms}
            label={
              <div>
                <span>I agree to the </span>
                <a href={'/terms'} target="_blank" rel="noreferrer">Terms of Service</a>
                <span> and </span>
                <a href={'/policy'} target="_blank" rel="noreferrer">Privacy Policy</a>
              </div>
            }
            onChange={value => setIsChecked(value)}
          />
          <Stack direction={{ lg: 'row', md: 'row', sm: 'column', xs: 'column' }} spacing={1}>
            <ButtonComponent sx={{ color: 'primary.main' }} label='Cancel' onClick={() => navigate('/')} variant='outlined' />
            <ButtonComponent label='Sign up' onClick={e => { e.preventDefault(); signup() }} type='submit' loading={loading} />
          </Stack>
        </Typography>

      </Box>
    </Box>
  )
}
