import React from 'react'
import { connect } from 'react-redux'
import { BrowserRouter, Routes as Switch, Route, Navigate } from 'react-router-dom'
import { ThemeProvider, createTheme, Box } from '@mui/material'

import appRoutes from './routes'

import { AppTheme } from '../theme'
import SnackBar from '../components/SnackBar'

const { unProtectedRoute, protectedRoute, adminRoute, candidateRoute } = appRoutes
const theme = createTheme(AppTheme)

class App extends React.Component {
  constructor() {
    super()
    this.state = {
      loggedIn: false,
      verifiedRoute: unProtectedRoute
    }
  }

  componentDidMount() {
    const loggedInUser = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')) : null
    const candidate = localStorage.getItem('candidate') ? JSON.parse(localStorage.getItem('candidate')) : null

    if (loggedInUser) {
      this.setState({ verifiedRoute: loggedInUser.role === 'ROLE_ADMIN' ? adminRoute : protectedRoute })
    } else if (candidate) {
      this.setState({ verifiedRoute: candidateRoute })
    } else {
      this.setState({ verifiedRoute: unProtectedRoute })
    }
  }

  static getDerivedStateFromProps(props, state) {
    const loggedInUser = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')) : null
    const candidate = localStorage.getItem('candidate') ? JSON.parse(localStorage.getItem('candidate')) : null
    let accessibleRoute = unProtectedRoute
    if (props.authenticated) {
      if (loggedInUser?.role === 'ROLE_ADMIN') {
        accessibleRoute = adminRoute
      } else if (candidate) {
        accessibleRoute = candidateRoute
      } else {
        accessibleRoute = protectedRoute
      }
    }
    if (props.authenticated !== state.loggedIn) {
      return {
        loggedIn: props.authenticated,
        verifiedRoute: accessibleRoute
      }
    }
    return null
  }

  render() {
    const { verifiedRoute } = this.state
    const { type, text, autoHide, horizontal, vertical } = this.props.message
    return (
      <Box>
        <ThemeProvider theme={theme}>
          <SnackBar open={text ? true : false} type={type} message={text} autoHide={autoHide} horizontal={horizontal} vertical={vertical} />
          <BrowserRouter>
            <Switch>
              {
                verifiedRoute.map((route, index) =>
                  <Route key={index} path={route.path} element={route.component}></Route>
                )
              }
              <Route path="*" element={<Navigate to="/" replace />} />
            </Switch>
          </BrowserRouter>
        </ThemeProvider>
      </Box>
    );
  }
}

function mapStateToProps(state) {
  return {
    message: state.message,
    authenticated: state.login.loggedIn
  }
}

export default connect(mapStateToProps, {})(App)
