import React, { useState } from 'react'
import { Box, Typography, Stack } from '@mui/material'
import ButtonComponent from '../../components/Button'
import TextInput from '../../components/TextField'
import { useNavigate } from 'react-router-dom'
import Auth from '../../client/API/Auth'
import { useDispatch } from 'react-redux'
import { setMessage } from '../../store/reducers/message'

export default function ForgotPassword() {
  const [obj, setObj] = useState({})
  const [enableField, setEnableField] = useState(false)
  const [error, setError] = useState({})
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const setFormValue = (field, val) => {
    setObj({
      ...obj,
      [field]: val
    })
    setError({})
  }

  const proceed = async (e) => {
    e.preventDefault()
    if (Object.keys(error).length) return
    const { email, otp, password } = obj
    if (!email) {
      setError({
        email: 'Please enter your email address'
      })
      // eslint-disable-next-line
    } else if (enableField && !otp) {
      setError({
        ...error,
        otp: 'Please enter OTP'
      })
    } else if (enableField && !password) {
      setError({
        ...error,
        password: 'Please enter password'
      })
    } else {
      setLoading(true)
      setError({})
      if (!enableField) {
        const res = await Auth.sendOTP(email)
        if (typeof res === 'boolean') {
          dispatch(setMessage({ type: 'success', text: 'OTP sent!' }))
          setEnableField(true)
        } else {
          dispatch(setMessage({ type: 'error', text: res }))
        }
      } else {
        const res = await Auth.changePassword({
          mailId: obj.email,
          otp: obj.otp,
          newPassword: obj.password
        })
        if (typeof res === 'boolean') {
          dispatch(setMessage({ type: 'success', text: 'Password changed successfully' }))
        } else {
          dispatch(setMessage({ type: 'error', text: res }))
        }
        navigate('/login')
      }
      setLoading(false)
    }
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <Box sx={{ backgroundColor: 'colors.white', width: '35rem', padding: '5rem', boxShadow: '0px 2px 30px #ccc6' }}>

        <Typography component='form' sx={{ margin: '2rem 0' }} onSubmit={e => proceed(e)}>
          <TextInput
            sx={{ marginBottom: '2rem' }}
            value={obj.email || ''}
            variant='outlined'
            name='email'
            label='Enter Email Address'
            onChange={setFormValue}
            error={error.email}
            autoFocus={!enableField ? true : false}
            required={true}
          />
          {enableField &&
            <>
              <TextInput
                sx={{ marginBottom: '2rem' }}
                error={error.otp}
                variant='outlined'
                name='otp'
                label='Enter OTP'
                value={obj.otp}
                onChange={setFormValue}
                required={true}
              />
              <TextInput
                sx={{ marginBottom: '2rem' }}
                error={error.password}
                variant='outlined'
                name='password'
                label='Enter Password'
                type='password'
                value={obj.password}
                required={true}
                onChange={setFormValue}
              />
            </>
          }

          <Stack direction={{ lg: 'row', md: 'row', sm: 'column', xs: 'column' }} spacing={1}>
            <ButtonComponent sx={{ color: 'primary.main' }} label='Cancel' onClick={() => navigate('/')} variant='outlined' />
            <ButtonComponent label={enableField ? 'Change Password' : 'Send OTP'} onClick={(e) => proceed(e)} type='submit' loading={loading} />
          </Stack>
        </Typography>

      </Box>
    </Box>
  )
}