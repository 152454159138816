import React from 'react'
import { GoogleOAuthProvider } from '@react-oauth/google';
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from './store/index'
import './index.scss'
import App from './views/App'

ReactDOM.render(
  <GoogleOAuthProvider clientId="41125987509-fc9nmm41iqaqdkp0i63gu9vn14lqq88f.apps.googleusercontent.com">
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  </GoogleOAuthProvider>,
  document.getElementById('root')
);

