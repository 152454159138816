import React from 'react'
import { GoogleLogin } from '@react-oauth/google'
import { jwtDecode } from 'jwt-decode'

import { Box, Typography, Stack } from '@mui/material'

import { useDispatch } from 'react-redux'
import Candidate from '../../client/API/Candidate'
import { candidate } from '../../store/reducers/login'

export default function CandidateLogin() {
  const dispatch = useDispatch()
  
  const responseMessage = async (response) => {
    const user = jwtDecode(response.credential)
    await Candidate.add({
      mailId: user.email,
      firstName: user.given_name,
      lastName: user.family_name
    })
    dispatch(candidate({ ...response, user }))
  }

  const errorMessage = (error) => {
    console.log('error', error);
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', background: 'white' }}>
      <Stack direction={'column'} gap={'1rem'} sx={{ alignItems: 'center' }}>
        <Typography style={{ fontSize: '2rem' }} component={'p'}><strong>Welcome to the Talent Community</strong></Typography>
        <GoogleLogin onSuccess={responseMessage} onError={errorMessage} size='large' width={'500px'} />
      </Stack>
    </Box>
  )
}