import { Grid, Stack, Box, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import Candidate from "../../client/API/Candidate"
import TreeViewComponent from "../../components/TreeView"
import MailComponent from "../../components/Mail"
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

export default function CandidateDashboard() {
  const [sideMenu, setSideMenu] = useState({})
  const [emails, setEmails] = useState([])
  const [expanded, setExpanded] = useState([])
  const [file, setFile] = useState('')
  const [selected, setSelected] = useState([])

  const candidate = localStorage.getItem('candidate') ? JSON.parse(localStorage.getItem('candidate')) : ''

  useEffect(() => {
    getSelectedLeftMenu(candidate?.user?.email)
  }, [])

  const getSelectedLeftMenu = async (email) => {
    const response = await Candidate.getSelectedSideMenu(email)
    if (typeof response !== 'string') {
      setSelected(response)
    }
  }
  const getMenu = async (name) => {
    const response = await Candidate.getSideMenu(name)
    if (typeof response !== 'string') {
      setSideMenu(response)
    }
  }

  const logout = () => {
    localStorage.clear()
    window.location.href = '/candidate/access';
  }

  const Header = () => {
    return (
      <Grid container spacing={2} sx={{
        backgroundColor: 'colors.bgColor', padding: '1rem 1rem', color: 'colors.white',
        fontWeight: 600, fontSize: '1rem', overflow: 'hidden',
        height: '5rem'
      }}
        justifyContent={'flex-start'} alignItems={'center'}>

        <Grid item lg={10} md={10} sm={10} xs={10}>
          <Stack direction={'column'} spacing={1}>
            <Box sx={{ fontSize: '1rem' }}>The Talent Community</Box>
          </Stack>
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={2}>
          <Stack direction={'row'} justifyContent={{ lg: 'flex-end', md: 'flex-end', sm: 'flex-start', xs: 'flex-start' }} alignItems={'right'} spacing={1}>
            <Box sx={{ cursor: 'pointer', fontSize: '0.8rem', marginTop: '2px' }} onClick={() => logout()}>Logout</Box>
          </Stack>
        </Grid>
      </Grid>
    )
  }

  useEffect(() => {
    pickEmail([expanded[0]])
  }, [expanded])

  const pickEmail = (items, values = null) => {
    let selectedEmails = []
    if (typeof items === 'string' && values) {
      selectedEmails = [...emails]
      const index = selectedEmails.findLastIndex(emai => emai.name === items)
      if (index > -1) {
        selectedEmails.splice(index, 1)
      } else {
        selectedEmails.push({
          name: items,
          res: Object.entries(values).map(([key, data]) => ({
            mailId: data.Email,
            firstName: data['First Name']
          }))
        })
      }
    } else if (sideMenu[items[0]]) {
      selectedEmails = []
      Object.entries(sideMenu[items[0]]).forEach(([key, values]) => {
        const data1 = {
          name: `${items[0]}-${key}`,
          res: []
        }
        Object.entries(values).forEach(([_, data]) => {
          data1.res.push({
            mailId: data.Email,
            firstName: data['First Name']
          })
        })
        selectedEmails.push(data1)
      })
    }
    setEmails(selectedEmails)
  }

  const resetEmails = () => {
    setEmails([])
  }

  const handleFileChange = (event) => {
    setFile(event.target.value)
    getMenu(event.target.value)
  }

  const Sidebar = () => {
    return (
      <Box style={{
        minWidth: '18rem', maxWidth: '25rem', height: 'calc(100vh - 5rem)',
        padding: '0.4rem', overflowY: 'auto', borderRight: '1px solid rgba(0, 0, 0, 0.5)',
        background: '#F9F9F9', boxShadow: '5px 0 5px -5px rgba(0, 0, 0, 0.5)'
      }}
      >
        <Box sx={{ marginBottom: '1rem', marginTop: '1rem' }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" sx={{ left: '-12px' }}>Choose Community</InputLabel>
            <Select
              variant="standard"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={file}
              label="Choose Community"
              onChange={handleFileChange}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map((num) =>
                <MenuItem value={`File-${num}`}>Community {num}</MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>
        <TreeViewComponent
          data={sideMenu}
          onSelect={pickEmail}
          selected={emails}
          setExpanded={(itemIds) => setExpanded([itemIds[0]])}
          expanded={expanded}
          selectedMainMenu={selected}
          selectedFile={file}
        />
      </Box>
    )
  }

  return (
    <Box sx={{ color: '#9b9b9b' }}>
      <Header />
      <Stack direction={'row'}>
        <Sidebar />
        <Typography style={{ width: '100%', padding: '1rem', background: 'white' }}>
          <p style={{ fontSize: '1.2rem' }}>
            <strong>Welcome {`${candidate?.user?.given_name || ''} ${candidate?.user?.family_name || ''}`} to the Talent Community.</strong>
          </p>
          <p>
            <ol>
              <b><li>Choose your community</li></b>
              <li style={{fontWeight: 600}}>Select a category: <span style={{fontWeight: 'normal'}}>All companies are selected by default.</span></li>
              <li style={{fontWeight: 600}}>Upload Your Resume: <span style={{fontWeight: 'normal'}}>Draft an email or paste your cover letter, then upload your resume.
Recruiter Contact: Recruiters will reach out to you via email, LinkedIn, or phone to discuss opportunities.</span></li>
              <li>Category <span style={{ color: '#318CE7' }}>text turns blue</span> when your profile is distributed.</li>
            </ol>
          </p>
          <p style={{ fontSize: '0.9rem' }}>
            <i>Note: The Talent Community does not have direct relationships with companies; they were referred by peers. You’ll have access to one community at a time. After sending emails for all categories, you'll be assigned a new community.
            </i>
          </p>
        </Typography>
      </Stack>
      <MailComponent emails={emails} resetEmails={resetEmails} setExpanded={() => setExpanded([])} selectedFile={file} />
    </Box>
  )
}
