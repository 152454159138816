import React, { useState, useEffect } from 'react'

import { Box, Typography, Stack, Grid } from '@mui/material'
import TextInput from '../../components/TextField'
import CheckboxComponent from '../../components/Checkbox'
import ButtonComponent from '../../components/Button'
import DialogComponent from '../../components/Dialog'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import Auth from '../../client/API/Auth'
import { setMessage } from '../../store/reducers/message'

import { useDispatch } from 'react-redux'
import Candidate from '../../client/API/Candidate'
import DropDown from '../../components/DropDown'
import Search from '../../client/API/Search'

const Input = styled('input')({
  display: 'none',
})

export default function CandidateSignup() {
  const [candidateObj, setCandidateObj] = useState({ rateType: 'W2', workType: [], keyVerticals: [] })
  const [error, setError] = useState({})
  const [loading, setLoading] = useState(false)
  const [showTerms, setShowTerms] = useState(false)
  const [fileData, setFileData] = useState(null)
  const [displayText, setDisplayText] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [screen, setScreen] = useState(false)
  const [roles, setRoles] = useState([])

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const getRolesList = async () => {
    let res = {}
    try {
      res = await Search.getRoles()
      if (res) {
        const r = res.filter(d => ['Project Manager', 'Program Manager', 'Java Engineer', 'Data Engineer', 'Python Engineer',
        'Business Analyst', 'Quality Analyst', 'Devops Engineer', 'UX/UI Engineer'].indexOf(d) > -1)
        setRoles(r)
      }
    } catch (error) {
      dispatch(setMessage({ type: 'error', text: res }))
    }
  }

  useEffect(() => {
    getRolesList()
  }, [])

  const setFormValue = (field, val) => {
    if (field === 'workType') {
      if (candidateObj.workType.indexOf(val) < 0) {
        val = [...candidateObj.workType, val]
      } else {
        candidateObj.workType.splice(candidateObj.workType.indexOf(val), 1)
        val = [...candidateObj.workType]
      }
    } else if (field === 'keyVerticals' && val === 'Others') {
      setDisplayText(!displayText)
    } else if (field === 'keyVerticals') {
      if (candidateObj.keyVerticals.indexOf(val) < 0) {
        val = [...candidateObj.keyVerticals, val]
      } else {
        candidateObj.keyVerticals.splice(candidateObj.keyVerticals.indexOf(val), 1)
        val = [...candidateObj.keyVerticals]
      }
    }
    if (val !== 'Others') {
      setCandidateObj({
        ...candidateObj,
        [field]: val
      })
    }
  }

  const signup = () => {
    let errored = false
    const fields = [
      { id: 'firstName', name: 'First Name' },
      { id: 'lastName', name: 'Last Name' },
      { id: 'email', name: 'Email Address' },
      { id: 'password', name: 'Password' },
      { id: 'phoneNo', name: 'Phone Number' },
      { id: 'linkedInUrl', name: 'LinkedIn URL' },
      { id: 'city', name: 'City' },
      { id: 'address', name: 'State' },
      { id: 'currentRole', name: 'Current Role' },
      { id: 'skills', name: 'skills' },
      { id: 'keyVerticals',  name: 'Key Verticals' },
      { id: 'preferedClients', name: 'Preferred Client' },
      { id: 'minRate', name: 'minRate' },
      { id: 'workType', name: 'workType' },
      { id: 'referralinfo', name: 'Work Authorization'}
    ]
    fields.some(({ id, name }) => {
      if (id !== 'minRate' && !candidateObj[id]) {
        setError({
          [id]: `Please enter ${name}`
        })
        errored = true
        return true
      }
      if (id === 'minRate' && Number(candidateObj.minimumW2Rate || 0) <=0 && Number(candidateObj.minimumC2CRate || 0) <=0) {
        errored = true
        setError({
          [id]: true
        })
        return true
      }
      if ((id === 'workType' || id === 'keyVerticals') && !candidateObj[id].length) {
        if (id === 'keyVerticals' && candidateObj.others) {
          return false;
        }
        errored = true
        setError({
          [id]: true
        })
        return true
      }
      return false
    })

    if (!errored) {
      if (!fileData) {
        return setError({
          resume: true
        })
      }
      if (candidateObj.email &&
        !candidateObj.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
        return setError({
          email: 'Please enter valid Email Address'
        })
      }
      if (candidateObj.password && candidateObj.password.length < 8) {
        return setError({
          password: 'Password should be of 8 characters'
        })
      }
      if (candidateObj.phoneNo && candidateObj.phoneNo.replace(' ', '').length < 10) {
        return setError({
          phoneNo: 'Phone number should be 10 digits'
        })
      }
      if (!isChecked) {
        return setError({
          terms: 'Please read and accept the Terms of Service and Privacy Policy'
        })
      }
      setError({})
      // setShowTerms(true)
      onSubmit()
    }
  }

  const renderConfirmDialog = () => {
    return (
      <Box>
        <Typography sx={{ fontSize: '1.2rem', marginBottom: '1rem' }} component={'div'}>1. The community charges $5/hr to you if you get a job from the community.</Typography>
        <Typography sx={{ fontSize: '1.2rem' }} component={'div'}>2. The community pays $2/hr to you for all successful PM and recruiter referrals.</Typography>
      </Box>
    )
  }

  const uploadResume = async (file) => {
    if (file) {
      const formData = new FormData()
      formData.append('file', file);
      try {
        const res = await Candidate.uploadResume(formData)
        return res
      } catch (error) {
        return false
      }
    }
  }

  const onSubmit = async () => {
    setLoading(true)
    // setShowTerms(false)
    let fileName = ''
    if (fileData) {
      fileName = await uploadResume(fileData)
      if (fileName === 'There was a problem communicating with the server. Please try again') {
        return dispatch(setMessage({ type: 'error', text: 'There was a problem communicating with the server. Please try again' }))
      }
    }
    const keyVerticals = candidateObj.keyVerticals.toString()
    const res = await Auth.candidateSignupAPI({
      firstName: candidateObj.firstName,
      lastName: candidateObj.lastName,
      linkedInUrl: candidateObj.linkedInUrl,
      currentAddress: candidateObj.address,
      currentCity: candidateObj.city,
      skills: candidateObj.skills,
      keyIndustries: keyVerticals.length ? `${keyVerticals}${candidateObj.others ? `,${candidateObj.others}` : ''}` : candidateObj.others || '',
      keyClients: candidateObj.preferedClients,
      minW2Rate: candidateObj.minimumW2Rate || 0,
      minC2CRate: candidateObj.minimumC2CRate || 0,
      resume: fileName,
      workType: candidateObj.workType.toString(),
      // rateType: candidateObj.rateType,
      currentRole: candidateObj.currentRole,
      user: {
        mailId: candidateObj.email.trim(),
        password: candidateObj.password,
        phoneNo: `+1${candidateObj.phoneNo.replace(' ', '')}`,
        role: 'ROLE_RECRUITEE'
      },
      referralinfo: candidateObj.referralinfo
    })
    setLoading(false)
    if (typeof res !== 'string') {
      dispatch(setMessage({ type: 'success', text: 'Signup Successfull' }))
      setScreen(true)
      // navigate('/login')
    } else {
      dispatch(setMessage({ type: 'error', text: res }))
    }
  }

  return (
    <><Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {!screen ? <Box sx={{ backgroundColor: 'colors.white', width: '45rem', padding: '2rem', boxShadow: '0px 2px 30px #ccc6' }}>

        <Box sx={{ fontWeight: 'bold', fontSize: '1.5rem' }}>
          We Bring Job Offers to You
          <Typography component='p' sx={{ fontSize: '1rem', margin: '0.6rem 0' }}>Join hundreds of project managers and engineers who’ve found their dream job using the talent community</Typography>
        </Box>

        <Typography component='form' sx={{ margin: '1.3rem 0' }} onSubmit={e => { e.preventDefault(); signup() }}>
          <Stack direction={'row'} spacing={12}>
            <TextInput
              sx={{ marginBottom: '1.3rem', width: '100%' }}
              value={candidateObj.firstName || ''}
              variant='outlined'
              name='firstName'
              label='First Name'
              onChange={setFormValue}
              error={error.firstName}
              autoFocus={true}
              required={true}
              inputProps={{ maxLength: 20 }}
            />
            <TextInput
              sx={{ marginBottom: '1.3rem', width: '100%' }}
              value={candidateObj.lastName || ''}
              variant='outlined'
              name='lastName'
              label='Last Name'
              onChange={setFormValue}
              error={error.lastName}
              required={true}
              inputProps={{ maxLength: 20 }}
            />
          </Stack>
          <Stack direction={'row'} spacing={12}>
            <TextInput
              sx={{ marginBottom: '1.3rem', width: '100%' }}
              value={candidateObj.email || ''}
              variant='outlined'
              name='email'
              label='Email Address'
              onChange={setFormValue}
              error={error.email}
              required={true}
              inputProps={{ maxLength: 30 }}
            />
            <TextInput
              sx={{ marginBottom: '1.3rem', width: '100%' }}
              value={candidateObj.password || ''}
              variant='outlined'
              name='password'
              label='Password'
              onChange={setFormValue}
              error={error.password}
              type='password'
              required={true}
            />
          </Stack>
          <Stack direction={'row'} spacing={12}>
            <TextInput
              sx={{ marginBottom: '1.3rem', width: '100%' }}
              value={candidateObj.phoneNo || ''}
              variant='outlined'
              name='phoneNo'
              label='Phone Number'
              onChange={setFormValue}
              error={error.phoneNo}
              required={true}
              inputProps={{ maxLength: 10 }}
            />
            <TextInput
              sx={{ marginBottom: '1.3rem', width: '100%' }}
              value={candidateObj.linkedInUrl || ''}
              variant='outlined'
              name='linkedInUrl'
              label='LinkedIn URL'
              onChange={setFormValue}
              required={true}
              error={error.linkedInUrl}
            />
          </Stack>
          <Stack direction={'row'} spacing={12}>
            <TextInput
              sx={{ marginBottom: '1.3rem', width: '100%' }}
              value={candidateObj.city || ''}
              variant='outlined'
              name='city'
              label='Your Current City'
              onChange={setFormValue}
              error={error.city}
              required={true}
              inputProps={{ maxLength: 20 }}
            />
            <TextInput
              sx={{ marginBottom: '1.3rem', width: '100%' }}
              value={candidateObj.address || ''}
              variant='outlined'
              name='address'
              label='Your Current State'
              onChange={setFormValue}
              error={error.address}
              required={true}
              inputProps={{ maxLength: 60 }}
            />
          </Stack>
          <DropDown
            label='Current Role'
            data={roles}
            sx={{ minWidth: '15rem', marginBottom: '1.5rem' }}
            handleChange={selected => setFormValue('currentRole', selected)}
            value={candidateObj.currentRole || ''}
            noDefault={true}
            required={true}
          />
          {error.currentRole && <Box sx={{ color: 'colors.error.red', margin: '1rem 0' }}>Please choose your current role</Box>}
          <TextInput
            sx={{ marginBottom: '1.3rem', width: '100%' }}
            value={candidateObj.skills || ''}
            variant='outlined'
            name='skills'
            label='Your Top 5 Technical Skills'
            onChange={setFormValue}
            error={error.skills}
            required={true}
          />
          <Box sx={{ fontWeight: 'bold', fontSize: '1rem', marginBottom: '0.5rem' }}>
            Key Verticals you have worked on<Typography component={'sup'} sx={{ color: 'colors.error.red' }}>*</Typography>
          </Box>
          {error.keyVerticals && <Box sx={{ color: 'colors.error.red', margin: '1rem 0' }}>Please select atleast one</Box>}
          <Grid container spacing={0}>
            {
              ['Retail', 'Technology', 'Banking and Finance', 'Pharmaceutical', 'Automobile', 'Information Technology and Services', 'Others'].map(data =>
                <Grid item key={data} lg={6}>
                  <CheckboxComponent
                    sx={{ marginBottom: '0rem' }}
                    label={data}
                    onChange={() => setFormValue('keyVerticals', data)}
                  />
                </Grid>
              )
            }
          </Grid>
          {displayText && <TextInput
              sx={{ marginBottom: '1.3rem', width: '100%' }}
              value={candidateObj.others || ''}
              variant='outlined'
              name='others'
              label='Other verticals you have worked with'
              onChange={setFormValue}
              error={displayText && error.others}
            />}
          <TextInput
            sx={{ marginBottom: '1.3rem', width: '100%' }}
            value={candidateObj.preferedClients || ''}
            variant='outlined'
            name='preferedClients'
            label='Top preferred clients you would like to work with'
            onChange={setFormValue}
            error={error.preferedClients}
            required={true}
          />
          <Stack direction={'row'} spacing={12}>
            <TextInput
              sx={{ width: '45%' }}
              value={candidateObj.minimumW2Rate || ''}
              variant='outlined'
              name='minimumW2Rate'
              label='Minimum W2 Rate per hour'
              onChange={setFormValue}
              inputProps={{ maxLength: 3 }}
            />
            <Typography component={'span'} sx={{ width: '10%' }}>AND / OR<Typography component={'sup'} sx={{ color: 'colors.error.red' }}>*</Typography></Typography>
            <TextInput
              sx={{ width: '45%' }}
              value={candidateObj.minimumC2CRate || ''}
              variant='outlined'
              name='minimumC2CRate'
              label='Minimum C2C Rate per hour'
              onChange={setFormValue}
              inputProps={{ maxLength: 3 }}
            />
          </Stack>
          {error.minRate && <Box sx={{ color: 'colors.error.red', margin: '1rem 0' }}>Please choose atleast one</Box>}
          <Box sx={{ margin: '1rem 0' }}>
            {error.resume && <Box sx={{ color: 'colors.error.red', margin: '1rem 0' }}>Please upload your Resume</Box>}
            <label htmlFor="contained-button-file">
              {/*".pdf, .doc, .docx"*/}
              <Input accept=".pdf, .doc, .docx" id="contained-button-file" multiple type="file" onChange={(evt) => setFileData(evt.target.files[0])} />
              <Button variant="contained" component="span">
                Upload Resume
              </Button><Typography component={'sup'} sx={{ marginLeft: '1rem', color: 'colors.error.red' }}>*</Typography>
              {fileData && <Typography sx={{ marginLeft: '0.5rem' }} component={'span'}>{fileData.name}</Typography>}
            </label>
          </Box>
          <Box sx={{ fontWeight: 'bold', fontSize: '1rem', marginBottom: '0.5rem' }}>
            Are you looking for<Typography component={'sup'} sx={{ color: 'colors.error.red' }}>*</Typography>
          </Box>
          {error.workType && <Box sx={{ color: 'colors.error.red', margin: '1rem 0' }}>Please choose a work type</Box>}
          <Stack direction={'row'} spacing={3}>
            {
              ['Hybrid', 'In Person', 'Remote'].map(type =>
                <CheckboxComponent
                  key={type}
                  label={type}
                  sx={{ marginBottom: 0 }}
                  onChange={() => setFormValue('workType', type)}
                />
              )
            }
          </Stack>
          {/* <Box sx={{ marginBottom: '0.3rem', fontSize: '0.9rem', fontStyle: 'italic' }}>
            Share your recruiter email or LinkedIn you have worked with in the past for reference purposes.
          </Box>
          <TextInput
            sx={{ marginBottom: '1.3rem', width: '100%' }}
            value={candidateObj.referralinfo || ''}
            variant='outlined'
            name='referralinfo'
            label='Paste your recruiter Email or LinkedIn'
            onChange={setFormValue}
            error={error.referralinfo}
            required={true}
          /> */}
          <Box sx={{ marginBottom: '0.3rem', fontSize: '0.9rem', fontStyle: 'italic' }}>
            Work Authorization
          </Box>
          <TextInput
            sx={{ marginBottom: '1.3rem', width: '100%' }}
            value={candidateObj.referralinfo || ''}
            variant='outlined'
            name='referralinfo'
            label='Work Authorization'
            onChange={setFormValue}
            error={error.referralinfo}
            required={true}
          />
          <CheckboxComponent
            checked={isChecked}
            error={error.terms}
            label={
              <div>
                 <span>I agree to the </span>
                 <a href={'/terms'} target="_blank" rel="noreferrer">Terms of Service</a>
                 <span> and </span>
                 <a href={'/policy'} target="_blank" rel="noreferrer">Privacy Policy</a>
              </div>
              }
            onChange={value => setIsChecked(value)}
          />

          <Stack direction={{ lg: 'row', md: 'row', sm: 'column', xs: 'column' }} spacing={1}>
            <ButtonComponent sx={{ color: 'primary.main' }} label='Cancel' onClick={() => navigate('/')} variant='outlined' />
            <ButtonComponent sx={{}} label='Sign up' onClick={e => { e.preventDefault(); signup() }} type='submit' loading={loading} />
          </Stack>

        </Typography>

      </Box> :
      <Box sx={{ fontSize: '2rem', padding: '0 20rem', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', height: '100vh' }}>
        Your Sign up is completed, a community representative will be getting your request approved. You will be receiving curated roles per your desired preferences. If you need priority access, text or What’s app on 510-458-8793
      </Box>
      }
    </Box>
      {showTerms && <DialogComponent
        title='Per the terms and conditions of the community'
        open={showTerms}
        handleCancel={() => { setShowTerms(false); navigate('/login') }}
        content={renderConfirmDialog()}
        onConnect={onSubmit}
        submitName='Acknowledge'
      />}
    </>
  )
}