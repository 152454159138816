import Login from "./Login"
import Signup from "./Signup"
import Dashboard from "./Dashboard"
import CandidateSignup from "./Signup/candidate"
import AdminDashboard from "./Dashboard/Admin"
import ForgotPassword from "./ForgotPassword"
import Terms from "./Signup/terms"
import Policy from "./Signup/policy"
import Candidate from "./Dashboard/Candidate"
import CandidateLogin from './Login/candidate'

const appRoutes = {
  unProtectedRoute: [
    {
      path: '/',
      component: <Login />
    },
    {
      path: '/signup',
      component: <Signup />
    },
    {
      path: '/candidate/signup',
      component: <CandidateSignup />
    },
    {
      path: '/candidate/access',
      component: <CandidateLogin />
    },
    {
      path: '/forgotPassword',
      component: <ForgotPassword />
    },
    {
      path: '/terms',
      component: <Terms />
    },
    {
      path: '/policy',
      component: <Policy />
    },
  ],
  protectedRoute: [
    {
      path: '/',
      component: <Dashboard />
    },
  ],
  adminRoute: [
    {
      path: '/',
      component: <AdminDashboard />
    },
  ],
  candidateRoute: [
    {
      path: '/',
      component: <Candidate />
    }
  ]
}

export default appRoutes