import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Typography } from '@mui/material';

export default function CheckboxComponent(props) {
  const { label, checked, error, onChange, sx } = props
  return (
    <FormGroup sx={{ marginBottom: '1.5rem', ...sx }}>
      <FormControlLabel control={
        <Checkbox checked={checked} onChange={e => onChange(e.target.checked)} />
      } label={label} />
      <Typography sx={{
        color: 'colors.error.red',
        fontSize: '0.9rem',
        visibility: `${error ? 'visible' : 'hidden'}`,
        opacity: `${error ? 1 : 0}`,
        transition: 'visibility 1s, opacity 0.5s',
        height: '1rem'
      }}
        component='p'>
        {error}
      </Typography>
    </FormGroup>
  );
}