import { configureStore } from '@reduxjs/toolkit'
import login from './reducers/login'
import message from './reducers/message';

import testReducer from './reducers/testAction'

const store = configureStore({
  reducer: {
    login,
    message,
    test: testReducer
  },
});
export default store;
