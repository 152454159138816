import { config } from "../config"
import { Buffer } from 'buffer'

class Client {

  connect = async ({ path, method = 'GET', data, basePath = 'talentCommunity' }) => {

    let user = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')) : null

    user = user ? user : (data && data.mailId && data.password ? data : { mailId: 'admin@admin.com', password: '1234' })

    // const { NODE_ENV } = process.env
    const baseURL = config.url.development // will be changed accordingly
    let response = {}

    const headers = {      
      'Access-Control-Allow-Origin': '*',
      Accept: '*/*'
    }

    let urlPath = `${baseURL}/${basePath}/${path}`

    headers.Authorization = `Basic ${Buffer.from(`${user.mailId}:${user.password}`).toString('base64')}`

    const configuration = {
      method,
      headers
    }

    if (['POST', 'PUT', 'DELETE'].includes(method) && data) {
      if (path === 'signup/resume' || path === 'candidate/leftmenu') {
        configuration.body = data
      } else {
        headers['Content-Type'] = 'application/json'
        configuration.body = JSON.stringify(data)
      }
    }

    try {
      response = await fetch(urlPath, configuration)
      if (response.status === 204 || response.status === 200) {
        if (response.status === 204) {
          return true
        }
        try {
          let res = ''
          if (path.includes('recruiter/resume?fileName=') || path.includes('recruitee/resume?fileName=')) {
            res = await response.blob()
            return res
          } else {
            res = path === 'signup/resume' ? await response.text() : await response.json()
          }
          if (res && res.status) {
            throw 'error'
          }
          return res
        } catch (error) {
          return response
        }
      } else if (response.status === 403) {
        const res = await response.text()
        if (res === 'User is yet to be approved by administrator.') {
          return 'null'
        }
        return res
      }
      try {
        const errorMessage = await response.text()
        if (errorMessage === 'MAIL_LIMIT_EXCEEDS') {
          return errorMessage
        }
        throw 'error'
      } catch (error) {
        throw 'error'
      }
    } catch (error) {
      console.log('API ERROR:', error)
      return 'Please try again later. If problem persist, please contact the administrator.'
    }

  }

  get = async path => {
    const requestObj = {
      path,
      method: 'GET'
    }
    return this.connect(requestObj)
  }

  post = async (path, data) => {
    const requestObj = {
      path,
      method: 'POST',
      data
    }
    return this.connect(requestObj)
  }

  put = async (path, data) => {
    const requestObj = {
      path,
      method: 'PUT',
      data
    }
    return this.connect(requestObj)
  }

}

export default new Client()
