import client from "..";


export default class Auth {

  static loginAPI = async data => {
    const loginResponse = await client.post('login', data)
    return loginResponse
  }

  static signupAPI = async data => {
    const signupResponse = await client.post('signup/recruiter', data)
    return signupResponse
  }

  static candidateSignupAPI = async data => {
    const candidateSignupResponse = await client.post('signup/recruitee', data)
    return candidateSignupResponse
  }

  static sendOTP = async mailId => {
    const response = await client.post(`login/forget/sendotp/${mailId}`)
    return response
  }

  static changePassword = async data => {
    const response = await client.put('login/forget/changePassword', data)
    return response
  }

  static getLoggedInUserInfo = async email => {
    const response = await client.get(`recruiter/${email}`)
    return response
  }
}
