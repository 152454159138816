import React from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { Box, Typography } from '@mui/material'

export default function RadioButton(props) {
  const { options = [], name, label, value, handleChange = () => {}, error, required = false, direction, sx } = props
  
  return (
    <FormControl component="fieldset" sx={{ width: '100%', marginBottom: !direction ? '1.5rem' : 0, ...sx }}>
      {label && <Box sx={{ fontSize: '', color: 'colors.black', marginBottom: '1rem' }}>{label}{required && <Typography component={'sup'} sx={{ color: 'colors.error.red' }}>*</Typography>}</Box>}
      {!direction ? <RadioGroup sx={{ marginLeft: '1.6rem' }} value={value} onChange={e => handleChange(name, e.target.value)}>
        {
          options.map(({value, label}) => <FormControlLabel key={value} value={value} control={<Radio />} label={label} />)
        }
      </RadioGroup> :
      <RadioGroup row value={value} onChange={e => handleChange(name, e.target.value)}>
        {
          options.map(({value, label}) => <FormControlLabel key={value} value={value} control={<Radio />} label={label} />)
        }
      </RadioGroup>
      }
      <Typography sx={{
        color: 'colors.error.red',
        fontSize: '0.9rem',
        visibility: `${error ? 'visible' : 'hidden'}`,
        opacity: `${error ? 1 : 0}`,
        transition: 'visibility 1s, opacity 0.5s',
        height: '1rem',
        marginLeft: '1.6rem'
        }}
        component='p'>
          {error}
      </Typography>
    </FormControl>
  )
}