import React from 'react'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { Typography } from '@mui/material'

export default function DropDown({
  label, value, dataValue = 'value', dataKey = 'name', data = [], variant = 'filled', handleChange, noDefault = false, required = false, defaultText = 'Select Option', sx,
  displayCount = false, total = 0
}) {

  return (
    <Box sx={{ minWidth: '15rem', ...sx }}>
      <FormControl variant={variant} fullWidth>
        <InputLabel>{label} {required ? <Typography sx={{ color: 'colors.error.red' }} component={'span'}>*</Typography> : ''}</InputLabel>
        <Select
          value={value}
          onChange={e => handleChange(e.target.value)}
          label={label}
        >
          {!noDefault && <MenuItem value={''}>{`${defaultText} ${total ? '-' : ''}`} {total ? <Typography sx={{ color: 'colors.error.red', paddingLeft: '0.3rem' }} component={'span'}>{` ${total} New`}</Typography> : ''}</MenuItem>}
          {
            data.map((option, index) =>
              <MenuItem key={index} value={option[dataValue] || option}>
                <Typography component={'span'}>
                  {`${option[dataKey] || option} ${displayCount && option.newCount ? '-' : ''}`}{displayCount && option.newCount ? <Typography sx={{ color: 'colors.error.red' }} component={'span'}>{` ${option.newCount} New`}</Typography> : ''}
                </Typography>
              </MenuItem>
            )
          }
        </Select>
      </FormControl>
    </Box>
  )
}
