import React from 'react'
import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import { DialogContent } from '@mui/material'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function FullScreenDialog ({ open, title = 'Advanced Search', handleClose, children }) {

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative', backgroundColor: 'colors.bgColor' }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1, color: 'colors.white' }} variant="h6" component="div">
            {title}
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon sx={{ color: 'colors.white' }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent sx={{ padding: '2rem 2.5rem', fontSize: '1rem' }}>
        {children}
      </DialogContent>
    </Dialog>
  )
}
