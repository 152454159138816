import React, { useState, useEffect } from 'react'
import { Box, Stack, Grid, Input, Button } from '@mui/material'
import { logout } from '../../store/reducers/login'
import { Logout, Settings } from '@mui/icons-material'
import { useDispatch } from 'react-redux'
import DataTable from '../../components/Datatable'
import Admin from '../../client/API/Admin'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import { setMessage } from '../../store/reducers/message'
import Candidate from '../../client/API/Candidate'
import TextInput from '../../components/TextField'
import DialogComponent from '../../components/Dialog'
import Loader from '../../components/Loader'
import DocViewer, { DocViewerRenderers } from "react-doc-viewer"
import CheckboxComponent from '../../components/Checkbox'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import moment from 'moment'
import { styled } from '@mui/material/styles'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

const Input1 = styled('input')({
  display: 'none',
})

const headers1 = [
  {
    id: 'firstName',
    numeric: false,
    disablePadding: true,
    label: 'First Name',
    sort: true,
  },
  {
    id: 'lastName',
    numeric: false,
    disablePadding: true,
    label: 'Last Name',
    sort: true,
  },
  {
    id: 'companyName',
    numeric: false,
    disablePadding: true,
    label: 'Company Name',
    sort: true,
  },
  {
    id: 'companyType',
    numeric: false,
    disablePadding: true,
    label: 'Type Of Company',
    sort: true,
  },
  {
    id: 'phoneNo',
    label: 'Phone number'
  },
  {
    id: 'rolesRecruited',
    label: 'Top roles you recruit',
    sort: true
  },
  {
    id: 'action',
    label: 'Block / Unblock'
  }
]

const headers2 = [
  {
    id: 'firstName',
    numeric: false,
    disablePadding: true,
    label: 'First Name',
    sort: true,
  },
  {
    id: 'lastName',
    numeric: false,
    disablePadding: true,
    label: 'Last Name',
    sort: true,
  },
  {
    id: 'companyName',
    numeric: false,
    disablePadding: true,
    label: 'Company Name',
    sort: true,
  },
  {
    id: 'companyType',
    numeric: false,
    disablePadding: true,
    label: 'Type Of Company',
    sort: true,
  },
  {
    id: 'phoneNo',
    label: 'Phone number'
  },
  {
    id: 'rolesRecruited',
    label: 'Top roles you recruit',
    sort: true
  },
  {
    id: 'action',
    label: 'Approve / Reject'
  }
]

const connectedHeaders = [
  {
    id: 'firstName',
    numeric: false,
    disablePadding: true,
    label: 'First Name',
    sort: true,
  },
  {
    id: 'lastName',
    numeric: false,
    disablePadding: true,
    label: 'Last Name',
    sort: true,
  },
  {
    id: 'recruiteeMailId',
    numeric: false,
    disablePadding: true,
    label: 'Email Address',
    sort: true,
  },
  {
    id: 'currentRole',
    label: 'Current Role'
  },
  {
    id: 'currentCity',
    label: 'City'
  },
  {
    id: 'currentAddress',
    label: 'Address'
  },
  {
    id: 'clientName',
    numeric: false,
    disablePadding: true,
    label: 'Client Name',
    sort: true,
  },
  {
    id: 'linkedInUrl',
    label: 'LinkedIn URL'
  },
  {
    id: 'minW2Rate',
    numeric: false,
    disablePadding: true,
    label: 'W2 Rate',
    sort: true,
  },
  {
    id: 'minC2CRate',
    numeric: false,
    disablePadding: true,
    label: 'C2C Rate',
    sort: true,
  },
  {
    id: 'skills',
    numeric: false,
    disablePadding: true,
    label: 'Skills',
    sort: true,
  },
  {
    id: 'referralinfo',
    label: 'Referral Info'
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'Status',
    sort: true,
  },
  {
    id: 'action',
    label: 'Block / Unblock'
  }
]

const connectedHeaders1 = [
  {
    id: 'firstName',
    label: 'Candidate Name',
    sort: true
  },
  {
    id: 'recruiteeMailId',
    numeric: false,
    disablePadding: true,
    label: 'Email Address',
    sort: true,
  },
  {
    id: 'phoneNo',
    label: 'Candidate Phone',
    sort: true
  },
  {
    id: 'linkedInUrl',
    label: 'Candidate LinkedIn',
  },
  {
    id: 'currentRole',
    label: 'Candidate Role',
  },
  {
    id: 'keyClients',
    label: 'Key Clients',
  },
  {
    id: 'industries',
    label: 'Industries',
  },
  {
    id: 'skills',
    label: 'Skills',
  },
  {
    id: 'city',
    label: 'Candidate City',
  },
  {
    id: 'address',
    label: 'Candidate State',
  },
  {
    id: 'minW2Rate',
    numeric: false,
    disablePadding: true,
    label: 'W2 Rate',
    sort: true,
  },
  {
    id: 'minC2CRate',
    numeric: false,
    disablePadding: true,
    label: 'C2C Rate',
    sort: true,
  },
  {
    id: 'recruiterName',
    label: 'Recruiter Name'
  },
  {
    id: 'recruiterMailId',
    label: 'Recruiter Email'
  },
  {
    id: 'recruiterPhoneNo',
    label: 'Recruiter Number'
  },
  {
    id: 'rolesRecruited',
    label: 'Roles Recruited'
  },
  {
    id: 'CompanyName',
    label: 'Recruiter Company'
  },
  {
    id: 'companyType',
    label: 'Recruiter Company Type'
  },
  {
    id: 'offeredRate',
    numeric: false,
    disablePadding: true,
    label: 'Offered Rate',
    sort: true,
  },
  {
    id: 'workType',
    label: 'Work Type'
  },
  {
    id: 'clientName',
    numeric: false,
    disablePadding: true,
    label: 'Client Name',
    sort: true,
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'Status',
    sort: true,
  },
  {
    id: 'action',
    label: 'Approve / Reject'
  }
]

const connectedHeaders2 = [
  {
    id: 'firstName',
    label: 'FirstName'
  },
  {
    id: 'lastName',
    label: 'LastName'
  },
  {
    id: 'recruiteeMailId',
    numeric: false,
    disablePadding: true,
    label: 'Email Address',
    sort: true,
  },
  {
    id: 'currentRole',
    label: 'Current Role'
  },
  {
    id: 'currentCity',
    label: 'City'
  },
  {
    id: 'currentAddress',
    label: 'Address'
  },
  {
    id: 'keyClients',
    numeric: false,
    disablePadding: true,
    label: 'Current/Past Clients',
    sort: true,
  },
  {
    id: 'linkedInUrl',
    label: 'LinkedIn URL'
  },
  {
    id: 'minW2Rate',
    numeric: false,
    disablePadding: true,
    label: 'W2 Rate',
    sort: true,
  },
  {
    id: 'minC2CRate',
    numeric: false,
    disablePadding: true,
    label: 'C2C Rate',
    sort: true,
  },
  {
    id: 'skills',
    numeric: false,
    disablePadding: true,
    label: 'Skills',
    sort: true,
  },
  {
    id: 'referralinfo',
    numeric: false,
    disablePadding: true,
    label: 'Work Authorization',
    sort: true,
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'Status',
    sort: true,
  },
  {
    id: 'resume',
    label: 'Resume'
  },
  {
    id: 'action',
    label: 'Approve / Reject'
  },
  {
    id: 'action2',
    label: 'Manage Vendor'
  }
]

const referredHeaders = [
  {
    id: 'name',
    label: 'Name',
    sort: true
  },
  {
    id: 'roles',
    label: 'Role'
  },
  {
    id: 'mailId',
    label: 'Email Address'
  },
  {
    id: 'resume',
    label: 'Resume'
  },
  {
    id: 'recruiterName',
    label: 'Recruiter Name'
  },
  {
    id: 'recruiterMailId',
    label: 'Recruiter MailId'
  },
  {
    id: 'status',
    label: 'Status'
  },
  {
    id: 'earnings',
    label: 'Earnings/Hr'
  }
]

const TabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

export default function AdminDashboard() {
  const [request, setRequest] = useState({})
  const [loading, setLoading] = useState(false)
  const [pageDetails, setPageDetails] = useState({ page: 0, limit: 10 })
  const [value, setValue] = React.useState(0)
  const [openRates, setOpenRates] = React.useState(false)
  const [formValue, setFormValue] = React.useState({})
  const [error, setError] = React.useState({})
  const [rateLoading, setRateLoading] = React.useState(false)
  const [loader, showLoader] = React.useState(false)
  const [data, setData] = React.useState(null)
  const [vendor, setVendor] = React.useState([])
  const [vendorModal, setOpenVendorModal] = React.useState(false)
  const [vendorData, setVendorData] = React.useState({})
  const [mailCount, setMailCount] = React.useState([])
  const [anchorEl, setAnchorEl] = React.useState(null)

  const open = Boolean(anchorEl)
  const dispatch = useDispatch()

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const getCandidateList = async () => {
    setLoading(true)
    const res = await Admin.getRequests(pageDetails)
    if (res && res.connects) {
      setRequest(res)
    } else {
      dispatch(setMessage({ type: 'error', text: res }))
    }
    setLoading(false)
  }

  const getUserList = async () => {
    setLoading(true)
    const res = await Admin.getUserRequests(pageDetails)
    if (res && res.recruiters) {
      setRequest(res)
    } else {
      dispatch(setMessage({ type: 'error', text: res }))
    }
    setLoading(false)
  }

  const getAllRecruiterList = async () => {
    setLoading(true)
    const res = await Admin.getAllRecruiterList(pageDetails)
    if (res && res.recruiters) {
      setRequest(res)
    } else {
      dispatch(setMessage({ type: 'error', text: res }))
    }
    setLoading(false)
  }

  const getVendorList = async () => {
    setLoading(true)
    const res = await Admin.getAllRecruiterList({ page: 0, limit: 10000 })
    if (res && res.recruiters) {
      setVendor(res.recruiters)
    } else {
      dispatch(setMessage({ type: 'error', text: res }))
    }
    setLoading(false)
  }

  const getAllRecruiteeList = async () => {
    setLoading(true)
    const res = await Admin.getAllRecruiteeList(pageDetails)
    if (res && res.recruitees) {
      setRequest(res)
    } else {
      dispatch(setMessage({ type: 'error', text: res }))
    }
    setLoading(false)
  }

  const getCandidateRequests = async () => {
    setLoading(true)
    const res = await Admin.getCandidateRequests(pageDetails)
    if (res && res.recruitees) {
      setRequest(res)
    } else {
      dispatch(setMessage({ type: 'error', text: res }))
    }
    await getVendorList()
    setLoading(false)
  }

  useEffect(() => {
    if (openRates) {
      getRates()
    } else {
      setFormValue({})
    }
  }, [openRates]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRates = async () => {
    setRateLoading(true)
    const res = await Admin.getRates()
    if (typeof res === 'object') {
      setFormValue(res)
    } else {
      dispatch(setMessage({ type: 'error', text: res }))
    }
    setRateLoading(false)
  }

  const getReferredCandidates = async data => {
    setLoading(true)
    const res = await Candidate.getReferred(pageDetails)
    if (res && res.recruiteeReferals) {
      setRequest(res)
    } else {
      dispatch(setMessage({ type: 'error', text: res }))
    }
    setLoading(false)
  }

  const getEmailCount = async () => {
    setLoading(true)
    const res = await Admin.getMailCount(moment().format('YYYY-MM-DD'))
    if (res && res.length >= 0) {
      setMailCount(res)
    } else {
      dispatch(setMessage({ type: 'error', text: res }))
    }
    setLoading(false)
  }

  useEffect(() => {
    setFormValue({})
    setPageDetails({ page: 0, limit: 10 })
  }, [value]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (value === 0) {
      getUserList()
    } else if (value === 1) {
      getCandidateRequests()
    } else if (value === 2) {
      getCandidateList()
    } else if (value === 3) {
      getAllRecruiterList()
    } else if (value === 4) {
      getAllRecruiteeList()
    } else if (value === 6) {
      getEmailCount()
    } else {
      getReferredCandidates()
    }
  }, [pageDetails]) // eslint-disable-line react-hooks/exhaustive-deps

  const uploadResume = async (file) => {
    if (file) {
      const formData = new FormData()
      formData.append('file', file);
      try {
        const res = await Candidate.uploadResume(formData)
        return res
      } catch (error) {
        return false
      }
    }
  }

  const action = async (type, field = 'id', val, message, upload = false, data = null) => {
    setLoading(true)
    if (upload && data) {
      const resume = await uploadResume(upload)
      if (resume === 'There was a problem communicating with the server. Please try again') {
        return dispatch(setMessage({ type: 'error', text: resume }))
      }
      await Candidate.update({ ...data, resume })
    }
    const res = await Admin.approveRejectRequest(type, field, val)
    if (typeof res === 'boolean') {
      dispatch(setMessage({ type: 'success', text: !message ? `Request ${type}ed` : message }))
      setFormValue({})
      if (value === 0) {
        getUserList()
      } else if (value === 1) {
        getCandidateRequests()
      } else if (value === 2) {
        getCandidateList()
      } else if (value === 3) {
        getAllRecruiterList()
      } else if (value === 4) {
        getAllRecruiteeList()
      } else if (value === 6) {
        getEmailCount()
      } else {
        getReferredCandidates()
      }
    } else {
      dispatch(setMessage({ type: 'error', text: res }))
    }
    setLoading(false)
  }

  const onChange = (field, val) => {
    if (!isNaN(val)) {
      setFormValue({
        ...formValue,
        [field]: Number(val)
      })
      setError({})
    }
  }

  const updateRate = async () => {
    if (!formValue.w2) {
      return setError({
        w2: 'W2 is required'
      })
    } else if (!formValue.c2c) {
      return setError({
        c2c: 'C2C is required'
      })
    } else if (!formValue.c2cPercentage) {
      return setError({
        c2cPercentage: 'C2C percentage is required'
      })
    }
    setRateLoading(true)
    const res = await Admin.updateRate(formValue)
    if (typeof res !== 'boolean') {
      dispatch(setMessage({ type: 'error', text: res }))
    }
    setRateLoading(false)
    setOpenRates(false)
  }

  const renderForm = () => {
    return (
      <Typography component={'form'} sx={{ margin: '2rem', maxWidth: '30rem' }}>
        <TextInput
          sx={{ marginBottom: '2rem' }}
          error={error.w2}
          variant='outlined'
          name='w2'
          label='Minimum W2'
          value={formValue.w2 || 0}
          onChange={onChange}
          type='text'
          required={true}
        />
        <TextInput
          sx={{ marginBottom: '2rem' }}
          error={error.c2c}
          variant='outlined'
          name='c2c'
          label='Minimum C2C'
          value={formValue.c2c || 0}
          onChange={onChange}
          type='text'
          required={true}

        />
        <TextInput
          sx={{ marginBottom: '2rem' }}
          error={error.c2cPercentage}
          variant='outlined'
          name='c2cPercentage'
          label='C2C Percentage'
          value={formValue.c2cPercentage || 0}
          onChange={onChange}
          type='text'
          required={true}

        />
      </Typography>
    )
  }

  const selectVendor = async (data) => {
    const selectedVendors = { ...vendorData }
    if (selectedVendors[vendorModal] && selectedVendors[vendorModal].length) {
      const index = selectedVendors[vendorModal].indexOf(data)
      if (index > -1) {
        selectedVendors[vendorModal].splice(index, 1)
      } else {
        selectedVendors[vendorModal].push(data)
      }
    } else {
      selectedVendors[vendorModal] = [data]
    }
    setVendorData(selectedVendors)
  }

  const renderVendorForm = () => {
    const domainsKeyValue = {}
    vendor.forEach(list => {
      if (list.user.status === 'APPROVED') {
        const domain = list.user.mailId.split('@')[1]
        domainsKeyValue[domain] = list.user.mailId
      }
    })
    return (
      <Typography component={'form'} sx={{ margin: '2rem', maxWidth: '30rem' }}>
        {Object.entries(domainsKeyValue).map(([key, value], index) =>
          <CheckboxComponent
            key={index}
            label={key}
            onChange={() => selectVendor(value)}
            sx={{ marginBottom: '0rem !important' }}
          />
        )}
      </Typography>
    )
  }

  const assignVendor = async () => {
    setLoading(true)
    let data = []
    for (const [mail, rec] of Object.entries(vendorData)) {
      if (rec?.length) {
        data = rec
      }
    }
    const res = await Admin.assignVendors({ vendors: data }, vendorModal)
    setLoading(false)
    if (typeof res === 'boolean') {
      dispatch(setMessage({ type: 'success', text: 'Success' }))
      getCandidateRequests()
    } else {
      dispatch(setMessage({ type: 'error', text: res }))
    }
    setOpenVendorModal(false)
    setVendorData({})
  }

  const download = async (resName, name) => {
    setLoading(true)
    const fileType = resName.substring(resName.lastIndexOf('.') + 1, resName.length)
    const res = await Candidate.downloadResume(resName, 'recruitee')
    if (!res.type) {
      dispatch(setMessage({ type: 'error', text: res }))
      return
    }
    const link = document.createElement('a')
    const blobData = new Blob([res])
    // create a blobURI pointing to our Blob
    link.href = URL.createObjectURL(blobData)
    link.download = `${name}_resume.${fileType}`
    document.body.append(link)
    link.click()
    link.remove()
    setLoading(false)
  }

  const viewResume = async (e, name) => {
    e.preventDefault()
    if (name) {
      setData(name)
    }
  }

  const updateEarnings = async (e, email) => {
    if (e.key === 'Enter') {
      let amount = e.target.value
      amount = amount.split('$')
      if (amount.length === 2) {
        amount = amount[1] ?? 0
      } else {
        amount = amount[0] ?? 0
      }
      if (amount === '' || isNaN(amount)) {
        dispatch(setMessage({ type: 'error', text: 'Please enter valid amount' }))
        return
      }
      setLoading(true)
      const res = await Candidate.updateEarnings({ email, amount })
      if (typeof res === 'string') {
        dispatch(setMessage({ type: 'error', text: res }))
      } else {
        request.recruiteeReferals.some(data => {
          if (data.mailId === email) {
            data.earnings = amount
            return true
          }
          return false
        })
        setRequest(request)
      }
      setLoading(false)
    }
  }

  const handleDropdown = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const uploadFile = async (fileData, num) => {
    if (fileData && num) {
      handleClose()
      setLoading(true)
      const formData = new FormData()
      formData.append('file', fileData)
      formData.append('name', `File-${num}`)
      try {
        const res = await Candidate.uploadMenu(formData)
        if (res !== 'There was a problem communicating with the server. Please try again') {
          dispatch(setMessage({ type: 'success', text: 'File uploaded successfully!' }))
        }
      } catch (error) {
        dispatch(setMessage({ type: 'error', text: 'Something went wrong!' }))
      }
      setLoading(false)
    }
  }

  const Header = () => {
    return (
      <Grid container direction={'row'} spacing={2} sx={{ backgroundColor: 'colors.bgColor', padding: '1rem 1rem', color: 'colors.white', fontWeight: 600, fontSize: '1rem', overflow: 'hidden' }}
        justifyContent={'flex-start'} alignItems={'center'}>
        <Grid item lg={9} md={9} sm={9} xs={9}>
          <Stack direction={'row'} justifyContent={'flex-start'} alignItems={'center'}>
            Welcome Admin
          </Stack>
        </Grid>
        {/* <Grid item lg={2} md={2} sm={2} xs={2}>
        </Grid> */}
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Stack direction={'row'} justifyContent={'flex-end'} alignItems={'center'} sx={{ cursor: 'pointer' }}>
            <Typography component={'div'} title='upload file'>
              <UploadFileIcon sx={{ marginRight: '1rem', cursor: 'pointer' }} onClick={handleDropdown} />
              <Menu className='upload-menu'
                sx={{ maxHeight: '25rem' }}
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map((num) =>
                  <label htmlFor={`contained-button-file-${num}`} key={num}>
                    <MenuItem>
                      <Input1 accept=".xlsx" id={`contained-button-file-${num}`} type="file" onChange={(evt) => uploadFile(evt.target.files[0], num)} />
                      <Typography>Community {num}</Typography>
                    </MenuItem>
                  </label>
                )}
              </Menu>
            </Typography>
            <Typography component={'span'} title='Rates'><Settings onClick={() => setOpenRates(true)} /></Typography>
            <Typography component={'span'} title='Logout'><Logout sx={{ marginLeft: '1rem' }} onClick={() => dispatch(logout())} /></Typography>
          </Stack>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <Header />
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
            <Tab label="Pending Recruiters" {...a11yProps(0)} />
            <Tab label="Pending Candidates" {...a11yProps(1)} />
            <Tab label="Pending Connect" {...a11yProps(2)} />
            <Tab label="Approved Recruiters" {...a11yProps(3)} />
            <Tab label="Approved Candidates" {...a11yProps(4)} />
            <Tab label="Referred Candidates" {...a11yProps(5)} />
            <Tab label="Candidates Email Count" {...a11yProps(6)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Box sx={{ margin: '2rem' }}>
            <DataTable
              role='admin-user'
              title={''}
              headers={headers2}
              data={request.recruiters || []}
              totalRecords={request.count}
              action={action}
              showCheckbox={false}
              hideConnect={true}
              loading={loading}
              onPageChange={page => setPageDetails({ ...pageDetails, page })}
              onRowPerPageChange={limit => setPageDetails({ page: 0, limit })}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box sx={{ margin: '2rem' }}>
            <DataTable
              role='admin-candidate'
              title={''}
              headers={connectedHeaders2}
              data={request.recruitees || []}
              totalRecords={request.count}
              action={action}
              showCheckbox={false}
              hideConnect={true}
              loading={loading}
              onPageChange={page => setPageDetails({ ...pageDetails, page })}
              onRowPerPageChange={limit => setPageDetails({ page: 0, limit })}
              downloadResume={download}
              manage={row => vendor.length && setOpenVendorModal(row) && setVendorData({ ...vendorData, [row.emailAddress]: [] })}
              vendor={vendor}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Box sx={{ margin: '2rem' }}>
            <DataTable
              role='pending-candidate'
              title={''}
              headers={connectedHeaders1}
              data={request.connects || []}
              totalRecords={request.count}
              action={action}
              showCheckbox={false}
              hideConnect={true}
              loading={loading}
              onPageChange={page => setPageDetails({ ...pageDetails, page })}
              onRowPerPageChange={limit => setPageDetails({ page: 0, limit })}
              viewResume={viewResume}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Box sx={{ margin: '2rem' }}>
            <DataTable
              role='admin-user'
              title={''}
              headers={headers1}
              data={request.recruiters || []}
              totalRecords={request.count}
              action={action}
              showCheckbox={false}
              hideConnect={true}
              loading={loading}
              onPageChange={page => setPageDetails({ ...pageDetails, page })}
              onRowPerPageChange={limit => setPageDetails({ page: 0, limit })}
              showBlockUnblock={true}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Box sx={{ margin: '2rem' }}>
            <DataTable
              role='admin-candidate'
              title={''}
              headers={connectedHeaders}
              data={request.recruitees || []}
              totalRecords={request.count}
              action={action}
              showCheckbox={false}
              hideConnect={true}
              loading={loading}
              onPageChange={page => setPageDetails({ ...pageDetails, page })}
              onRowPerPageChange={limit => setPageDetails({ page: 0, limit })}
              showBlockUnblock={true}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Box sx={{ margin: '2rem' }}>
            <DataTable
              role='admin'
              title={''}
              viewName={'Referred'}
              headers={referredHeaders}
              data={request.recruiteeReferals}
              totalRecords={request.count}
              // connect={connect}
              showCheckbox={false}
              hideConnect={true}
              loading={loading}
              onPageChange={page => setPageDetails({ ...pageDetails, page })}
              onRowPerPageChange={limit => setPageDetails({ page: 0, limit })}
              downloadResume={download}
              updateEarnings={updateEarnings}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={6}>
          <Box sx={{ margin: '2rem' }}>
            <DataTable
              role='admin'
              title={''}
              viewName={'Email count'}
              headers={
                [{
                  id: 'candidateMailId',
                  label: 'Candidate Mail'
                }, {
                  id: 'count',
                  label: 'Count',
                  sort: true
                }]
              }
              data={mailCount}
              totalRecords={mailCount.length}
              // connect={connect}
              showCheckbox={false}
              hideConnect={true}
              loading={loading}
            // onPageChange={page => setPageDetails({ ...pageDetails, page })}
            // onRowPerPageChange={limit => setPageDetails({ page: 0, limit })}
            />
          </Box>
        </TabPanel>
        {openRates &&
          <DialogComponent
            title='Rates'
            open={openRates}
            handleClose={() => setOpenRates(false)}
            content={renderForm()}
            onConnect={() => updateRate()}
            handleCancel={() => setOpenRates(false)}
            submitName='Update'
            loading={rateLoading}
          />
        }
        {vendorModal &&
          <DialogComponent
            title='Vendor List'
            open={vendorModal}
            handleClose={() => { setOpenVendorModal(false); setVendorData({}) }}
            content={renderVendorForm()}
            onConnect={() => assignVendor()}
            handleCancel={() => { setOpenVendorModal(false); setVendorData({}) }}
            submitName='Assign'
            loading={loading}
            disable={Object.keys(vendorData).length === 0}
          />
        }
      </Box>
      {loader && <Loader show={showLoader} />}
      {data && (data.substring(data.lastIndexOf('.') + 1, data.length) === 'docx' || data.substring(data.lastIndexOf('.') + 1, data.length) === 'doc') ?
        <Loader show={data ? true : false} view={<DocViewer style={{ width: '50%', height: '100vh' }} pluginRenderers={DocViewerRenderers} config={{
          header: {
            disableHeader: true,
            disableFileName: true,
            retainURLParams: false
          },
        }} documents={[{ uri: `https://toptalentcommunity.s3.amazonaws.com/resumes/${data}` }]}
        />} close={() => setData(null)} />
        :
        data && <Loader show={data ? true : false} view={<iframe title='i-1' src={`https://toptalentcommunity.s3.amazonaws.com/resumes/${data}#toolbar=0&view=fitH, 50`} />} close={() => setData(null)} />
      }
    </>
  )
}
