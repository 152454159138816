import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loggedIn: false,
}

const login = createSlice({
  name: 'login',
  initialState,
  reducers: {
    authenticate: (state, { payload }) => {
      state.loggedIn = true
      localStorage.setItem('loggedInUser', JSON.stringify(payload))
    },
    candidate: (state, { payload }) => {
      state.loggedIn = true
      localStorage.setItem('candidate', JSON.stringify({ ...payload }))
    },
    logout: (state) => {
      localStorage.clear()
      state.loggedIn = false
      window.location.reload()
    }
  }
})

// Action creators are generated for each case reducer function
export const { authenticate, logout, candidate } = login.actions

export default login.reducer
