import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'

import TextInput from '../../components/TextField'
import ButtonComponent from '../../components/Button'
import { useNavigate } from 'react-router-dom'
import { authenticate, candidate } from '../../store/reducers/login'
import { useDispatch } from 'react-redux'

import Auth from '../../client/API/Auth'
import { setMessage } from '../../store/reducers/message'

export default function Login() {
  const [loginObj, setLoginObj] = useState({})
  const [enableField, setEnableField] = useState(false)
  const [error, setError] = useState({})
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const setFormValue = (field, val) => {
    setLoginObj({
      ...loginObj,
      [field]: val
    })
    setError({})
  }

  // else if (email && !email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
  //   setError({
  //     email: 'Please enter valid email address'
  //   })
  // } 

  const proceed = async e => {
    e.preventDefault()
    if (Object.keys(error).length) return
    const { email, password } = loginObj
    if (!email) {
      setError({
        email: 'Please enter your email address'
      })
      // eslint-disable-next-line
    } else if (enableField && !password) {
      setError({
        ...error,
        password: 'Please enter your password'
      })
    } else {
      setError({})
      if (!enableField) {
        setEnableField(true)
      } else {
        setLoading(true)
        const res = await Auth.loginAPI({ mailId: loginObj.email, password: loginObj.password })
        setLoading(false)
        if (res && res.role) {
          if (res.role === 'recruitee') {
            dispatch(setMessage({ type: 'error', text: 'There was a problem communicating with the server. Please try again' }))
          } else {
            dispatch(authenticate({ mailId: loginObj.email, password: loginObj.password, role: res.role }))
          }
        } else {
          dispatch(setMessage({
            type: 'error',
            text: res === 'null' ? 'Your Sign up is completed, a community representative will be calling you shortly to get your request approved. If you need priority access, text or What’s app on 510-458-8793' : res,
            autoHide: res !== 'null',
            horizontal: res === 'null' ? 'center' : 'right'
            }))
        }
      }
    }
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <Box sx={{ backgroundColor: 'colors.white', width: '35rem', padding: '5rem', boxShadow: '0px 2px 30px #ccc6' }}>

        <Box sx={{ fontWeight: 'bold', fontSize: '1.5rem' }}>
          Sign in
          <Typography component='p' sx={{ fontSize: '1.2rem' }}>To Access Account</Typography>
        </Box>

        <Typography component='form' sx={{ margin: '2rem 0' }} onSubmit={e => proceed(e)}>
          <TextInput
            sx={{ marginBottom: '2rem' }}
            value={loginObj.email || ''}
            variant='outlined'
            name='email'
            label='Enter Email Address'
            onChange={setFormValue}
            error={error.email}
            autoFocus={!enableField ? true : false}
          />
          {enableField &&
            <TextInput
              autoFocus={enableField ? true : false}
              sx={{ marginBottom: '2rem' }}
              error={error.password}
              variant='outlined'
              name='password'
              label='Enter Password'
              type='password'
              onChange={setFormValue}
            />
          }
          <ButtonComponent label={enableField ? 'Sign in' : 'Next'} onClick={(e) => proceed(e)} type='submit' loading={loading} />
        </Typography>

        <Box sx={{ textAlign: 'center', fontSize: '1.2rem' }}>
          Signup as Recruiter?
          <Typography component={'span'} sx={{ marginLeft: '0.4rem', fontSize: '1.2rem', cursor: 'pointer', color: 'primary.main', fontWeight: 600 }} onClick={() => navigate('/signup')}>
            Click Here!
          </Typography>
          <Box sx={{ margin: '1rem 0rem',  textAlign: 'center', fontSize: '1.2rem' }}>
            Signup as Candidate?
            <Typography component={'span'} sx={{ marginLeft: '0.4rem', fontSize: '1.2rem', cursor: 'pointer', color: 'primary.main', fontWeight: 600 }} onClick={() => navigate('/candidate/signup')}>
              Click Here!
            </Typography>
          </Box>
          <Box sx={{ margin: '1rem 0rem',  textAlign: 'center', fontSize: '1.2rem' }}>
            Candidate Access?
            <Typography component={'span'} sx={{ marginLeft: '0.4rem', fontSize: '1.2rem', cursor: 'pointer', color: 'primary.main', fontWeight: 600 }} onClick={() => navigate('/candidate/access')}>
              Click Here!
            </Typography>
          </Box>

          <Box sx={{ margin: '1rem 0rem',  textAlign: 'center', fontSize: '1.2rem', cursor: 'pointer', color: 'primary.main', fontWeight: 600 }} onClick={() => navigate('/forgotPassword')}>
            Forgot Password?
          </Box>
        </Box>

      </Box>
    </Box>
  )
}
