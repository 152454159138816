import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import { useDispatch } from 'react-redux'
import { resetMessage } from '../store/reducers/message'

export default function SnackBar({ open, type = 'success', message, autoHide = true, horizontal = 'right', vertical = 'top' }) {

  const dispatch = useDispatch()

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  })

  const handleClose = () => {
    dispatch(resetMessage())
  }

  return (
    <Snackbar open={open} autoHideDuration={autoHide ? 6000 : null} anchorOrigin={{ vertical, horizontal }} onClose={handleClose}>
      <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  )
}
