import React from 'react'
import TextField from '@mui/material/TextField'
import { Box, Typography } from '@mui/material'

export default function TextInput(props) {
  const { id, sx, label, onChange = () => { }, name, variant = 'standard', error = null, ...restProps } = props
  return (
    <Box sx={{ ...sx }}>
      <TextField
        id={name}
        sx={{ width: '100%' }}
        label={label}
        variant={variant}
        onChange={(e) => onChange(name, e.target.value)}
        {...restProps}
      />
      <Typography sx={{
        color: 'colors.error.red',
        fontSize: '0.9rem',
        visibility: `${error ? 'visible' : 'hidden'}`,
        opacity: `${error ? 1 : 0}`,
        transition: 'visibility 1s, opacity 0.5s',
        height: '1rem'
        }}
        component='p'>
          {error}
      </Typography>
    </Box>
  )

}
