import React from 'react'
import LoadingButton from '@mui/lab/LoadingButton'

export default function ButtonComponent(props) {
  const { sx, label, variant = 'contained', disable = false, ...restProps } = props
  return (
    <LoadingButton
      sx={{ width: '100%', ...sx }}
      variant={variant}
      disabled={disable}
      {...restProps}
    >
      {label}
    </LoadingButton>
  )

}