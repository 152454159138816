import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  type: 'success',
  text: '',
  autoHide: true,
  horizontal: 'right'
}

const message = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setMessage: (state, { payload }) => {
      state.type = payload.type
      state.text = payload.text
      state.autoHide = payload.autoHide || true
      state.horizontal = payload.horizontal || 'right'
      state.vertical = payload.vertical
    },
    resetMessage: (state) => {
      state.type = 'success'
      state.text = ''
      state.autoHide = true
      state.horizontal = 'right'
    }
  }
})

// Action creators are generated for each case reducer function
export const { setMessage, resetMessage } = message.actions

export default message.reducer
