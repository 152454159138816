import React from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Checkbox from '@mui/material/Checkbox'
import { visuallyHidden } from '@mui/utils'
import ButtonComponent from './Button'
import { Typography } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'

import { DoneAllSharp, DoNotDisturbAltSharp, Download } from '@mui/icons-material'
import DialogComponent from './Dialog'

import moment from 'moment'
import TextInput from './TextField'

const color = 'rgb(156, 39, 176)'

const Input = styled('input')({
  display: 'none',
})

function descendingComparator(a, b, orderBy) {
  if (typeof a[orderBy] === 'string') {
    const aWord = a[orderBy] ? a[orderBy].toString().toLowerCase() : ''
    const bWord = b[orderBy] ? b[orderBy].toString().toLowerCase() : ''
    return bWord.localeCompare(aWord)
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

function EnhancedTableHead(props) {
  const { headers, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, showCheckbox = true } = props

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {showCheckbox && <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>}
        {headers.map((headCell) => (
          !headCell.hide && <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ textTransform: 'uppercase' }}
          >
            {headCell.sort ? <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <strong>{headCell.label}</strong>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel> : <strong>{headCell.label}</strong>}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default function DataTable({ role, viewName, title, headers = [], data = [], totalRecords = 0, connect = () => { },
  showCheckbox, hideConnect = false, loading, onRowPerPageChange, onPageChange, action = () => { }, showBlockUnblock = false, downloadResume,
  updateEarnings, viewResume, manage, downloadAllCanResume, hideDownload = true
}) {
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('firtName')
  const [selected, setSelected] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [openResume, setOpenResume] = React.useState(false)
  const [fileData, setFileData] = React.useState(null)

  React.useEffect(() => {
    if (!openResume) {
      setFileData(null)
    }
  }, [openResume])

  React.useEffect(() => {
    setPage(0)
    setRowsPerPage(10)
  }, [viewName])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name)
  //   let newSelected = []

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name)
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1))
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1))
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1),
  //     )
  //   }

  //   setSelected(newSelected)
  // }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    onPageChange(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    const limit = parseInt(event.target.value, 10)
    setRowsPerPage(limit)
    setPage(0)
    onRowPerPageChange(limit)
  }

  const getIcon = status => {
    switch (status) {
      case 'APPROVED':
        return 'Connected' // <DoneAllSharp sx={{ color: 'colors.green' }} />
      case 'REJECTED':
        return 'Pending for approval' // <DoNotDisturbAltSharp sx={{ color: 'colors.error.red' }} />
      default:
        return 'Pending for approval' // <PendingActionsSharp sx={{ color: 'primary.main' }} />
    }
  }

  const isSelected = (row) => {
    return selected.findIndex(rec => rec.user.mailId === row.user.mailId) !== -1
  }

  const checkAction = row => {
    if (row.resume && downloadResume) {
      setOpenResume(row)
    } else {
      action('approve', row.id ? 'id' : 'mailId', row.id || row.user.mailId)
    }
  }

  const uploadForm = () => {
    return (
      <Box sx={{ margin: '1rem 0' }}>
        <label htmlFor="contained-button-file">
          {/*".pdf, .doc, .docx"*/}
          <Input accept=".pdf, .doc, .docx" id="contained-button-file" multiple type="file" onChange={(evt) => setFileData(evt.target.files[0])} />
          <Button variant="contained" component="span">
            Upload
          </Button>
          {fileData && <Typography sx={{ marginLeft: '0.5rem' }} component={'span'}>{fileData.name}</Typography>}
        </label>
      </Box>
    )
  }

  const getRateType = rate => {
    if (rate === 'W2' || rate === 'C2C') {
      return rate
    }
    if (rate === 'minW2Rate') {
      return 'W2'
    }
    if (rate === 'minC2CRate') {
      return 'C2C'
    }
    return ''
  }

  const handlecheckBoxClick = (e, row) => {
    const newSelected = [...selected]
    const index = newSelected.findIndex(data1 => data1.user.mailId === row.user.mailId)
    if (index > -1) {
      newSelected.splice(index, 1)
    } else {
      data.map(can => {
        if (can.user.mailId === row.user.mailId) {
          newSelected.push(row)
        }
      })
    }
    setSelected(newSelected)
  }

  const downloadAllResume = async record => {
    const promise = []
    for (const rec of record) {
      promise.push(downloadAllCanResume('', rec.resume, rec.firstName, rec.user.mailId))
    }
    await Promise.all(promise)
    setSelected([])
  }

  return (
    <Box sx={{ width: '100%' }}>

      <Typography
        sx={{ flex: '1 1 100%', margin: '2rem 0rem' }}
        variant="h5"
        id="tableTitle"
        component="div"
      >
        {title}
        {!hideDownload ? <ButtonComponent
          sx={{ width: '1rem', fontSize: '0.7rem', color: 'primary.main', marginLeft: '3rem' }}
          label='Download'
          variant='text'
          onClick={e => { e.stopPropagation(); downloadAllResume(selected) }}
          disable={selected.length === 0}
        /> : ''}
      </Typography>
      <TableContainer sx={{ maxHeight: 'calc(100vh - 25vh)' }}>
        {loading && <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>}
        <Table
          stickyHeader
          sx={{ minWidth: 750 }}
          size={viewName === 'Connected' ? 'medium' : 'small'}
        >
          <EnhancedTableHead
            headers={headers}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
            showCheckbox={showCheckbox}
            hideConnect={hideConnect}
          />
          {!!(data.length && !loading) && <TableBody>
            {stableSort(data, getComparator(order, orderBy))
              .map((row, index) => {
                const isItemSelected = isSelected(row)
                const labelId = `enhanced-table-checkbox-${index}`
                if (viewName === 'Connected') {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                    >
                      {showCheckbox && <TableCell padding="checkbox" align='center'>
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>}
                      <TableCell
                        align='left'
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        sx={{ color: `${row.resume ? 'primary.main' : 'color.black'}`, cursor: `${row.resume ? 'pointer' : 'cursor'}` }}
                        onClick={(e) => downloadResume(e, row.resume)}
                      >
                        {row.firstName}
                      </TableCell>
                      <TableCell align='left' title={row.role}>{row.role}</TableCell>
                      <TableCell align='left' title={row.city}>{row.city}</TableCell>
                      <TableCell align='left' title={row.minW2Rate}>{`${row.minW2Rate > 0 ? `$${row.minW2Rate}` : ''}`}</TableCell>
                      <TableCell align='left' title={row.minC2CRate}>{`${row.minC2CRate > 0 ? `$${row.minC2CRate}` : ''}`}</TableCell>
                      <TableCell align='left' title={row.offeredRate}>${row.offeredRate || 0}</TableCell>
                      <TableCell align='left' title={row.workType && row.workType.length > 15 ? row.workType : ''}>{row.workType}</TableCell>
                      <TableCell align='left' title={row.clientName}>{row.clientName}</TableCell>
                      {/* <TableCell align='left' title={row.status || row.user.status}>{getIcon(row.status || row.user.status)}</TableCell> */}
                    </TableRow>
                  )
                }
                if (viewName === 'Referred') {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                    >
                      {showCheckbox && <TableCell padding="checkbox" align='center'>
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>}
                      {role === 'admin' ? <TableCell
                        align='left'
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      // sx={{ color: `${role !== 'admin' && row.resume ? 'primary.main' : 'color.black'}`, cursor: `${row.resume ? 'pointer' : 'cursor'}` }}
                      // onClick={(e) => role !== 'admin' && downloadResume(e, row.resume, row.name)}
                      >
                        {row.name}
                      </TableCell> :
                        <TableCell
                          align='left'
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          <Box sx={{ paddingLeft: '1rem', paddingTop: '0.6rem' }}>
                            <Typography title={row.name} component={'div'} sx={{ marginBottom: '0.1rem' }}><b>{row.name}</b></Typography>
                            <Typography title={row.roles} component={'div'} sx={{ marginBottom: '0.1rem', fontSize: '0.9rem' }}>{row.roles}</Typography>
                            <Typography title={row.mailId} component={'div'} sx={{ marginBottom: '0.1rem', fontSize: '0.9rem' }}>{row.mailId}</Typography>
                            <Typography component={'div'} sx={{ marginBottom: '0.1rem', fontSize: '0.9rem' }}>{moment(row.createdDate).format('DD MMM YYYY HH:mm')}</Typography>
                          </Box>
                        </TableCell>
                      }
                      {/* {role !== 'admin' && <TableCell align='left' title={moment(row.createdDate).format('DD MMM YYYY HH:mm')}>{moment(row.createdDate).format('DD MMM YYYY HH:mm')}</TableCell>} */}
                      {role === 'admin' && <TableCell align='left' title={row.roles}>{row.roles}</TableCell>}
                      {role === 'admin' && <TableCell align='left' title={row.mailId}>{row.mailId}</TableCell>}
                      {role === 'admin' && <TableCell align='left' title='Download Resume'><Download cursor='pointer' onClick={() => downloadResume(row.resume, row.name)} /></TableCell>}
                      {role === 'admin' && <TableCell align='left' title={row.recruiterMailId}>{row.recruiterName}</TableCell>}
                      {role === 'admin' && <TableCell align='left' title={row.recruiterMailId}>{row.recruiterMailId}</TableCell>}
                      {role === 'admin' && <TableCell align='left' title={row.connected > 0 ? 'Connected' : row.status}>{row.connected > 0 ? `${row.connected} Connected` : row.status}</TableCell>}
                      {role === 'admin' &&
                        <TableCell align='left'>
                          <TextInput size='small' sx={{ width: '4rem' }} variant='outlined' defaultValue={`$${row.earnings}`} inputProps={{ maxLength: 11 }} onKeyPress={e => updateEarnings(e, row.mailId, row.earnings)} />
                          <Typography component={'span'}></Typography>
                        </TableCell>
                      }

                      {role !== 'admin' &&
                        <TableCell align='left'>
                          <Box sx={{ width: '15rem', height: '0.3rem', marginBottom: '1.8rem', background: `${['INVITED', 'SIGNEDUP', 'APPROVED'].includes(row.status) ? color : '#808080'}` }}></Box>
                        </TableCell>
                      }
                      {role !== 'admin' &&
                        <TableCell align='left'>
                          <Box sx={{ width: '15rem', height: '0.3rem', marginBottom: '1.8rem', background: `${['SIGNEDUP', 'APPROVED'].includes(row.status) ? color : '#808080'}` }}></Box>
                        </TableCell>
                      }
                      {role !== 'admin' &&
                        <TableCell align='left'>
                          <Box sx={{ width: '15rem', height: '0.3rem', marginBottom: '1.8rem', background: `${(['APPROVED'].includes(row.status) || row.connected) ? color : '#808080'}` }}></Box>
                        </TableCell>
                      }
                      {role !== 'admin' &&
                        <TableCell align='left'>
                          <Box sx={{ width: '15rem', height: '0.3rem', marginBottom: !row.connected ? '1.8rem' : '0.5rem', background: `${row.connected ? color : '#808080'}` }}></Box>
                          {!!row.connected && <Typography component={'span'}>{row.connected} {row.connected > 1 ? 'Connections' : 'Connection'}</Typography>}
                        </TableCell>
                      }
                      {role !== 'admin' &&
                        <TableCell align='left'>{row.earnings > 0 ? `$${row.earnings}` : ' '}</TableCell>
                      }
                    </TableRow>
                  )
                }
                if (role === 'pending-candidate') {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                    >
                      {showCheckbox && <TableCell padding="checkbox" align='center'>
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>}
                      <TableCell
                        align='left'
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        sx={{ color: `${row.resume ? 'primary.main' : 'color.black'}`, cursor: `${row.resume ? 'pointer' : 'cursor'}` }}
                        onClick={(e) => viewResume(e, row.resume)}
                      >
                        {row.firstName}
                      </TableCell>
                      <TableCell align='left' title={row.recruiteeMailId}>{row.recruiteeMailId}</TableCell>
                      <TableCell align='left' title={row.phoneNo}>{row.phoneNo}</TableCell>
                      <TableCell align='left' title={row.linkedInUrl}>{row.linkedInUrl}</TableCell>
                      <TableCell align='left' title={row.role}>{row.role}</TableCell>
                      {row.recruiteeDTO.keyClients &&
                        <TableCell align='left' title={row.recruiteeDTO.keyClients}>{row.recruiteeDTO.keyClients}</TableCell>
                      }
                      {row.recruiteeDTO.keyIndustries &&
                        <TableCell align='left' title={row.recruiteeDTO.keyIndustries}>{row.recruiteeDTO.keyIndustries}</TableCell>
                      }
                      {row.recruiteeDTO.skills &&
                        <TableCell align='left' title={row.recruiteeDTO.skills}>{row.recruiteeDTO.skills}</TableCell>
                      }
                      <TableCell align='left' title={row.city}>{row.city}</TableCell>
                      <TableCell align='left' title={row.address}>{row.address}</TableCell>
                      <TableCell align='left' title={row.minW2Rate}>{`${row.minW2Rate > 0 ? `$${row.minW2Rate} ${row.subscription === 'true' ? '(Subscription)' : ''}` : ''}`}</TableCell>
                      <TableCell align='left' title={row.minC2CRate}>{`${row.minC2CRate > 0 ? `$${row.minC2CRate}` : ''}`}</TableCell>
                      <TableCell align='left' title={row.recruiterName}>{row.recruiterName}</TableCell>
                      <TableCell align='left' title={row.recruiterMailId}>{row.recruiterMailId}</TableCell>
                      <TableCell align='left' title={row.recruiterPhoneNo}>{row.recruiterPhoneNo}</TableCell>
                      {row.recruiterDTO.rolesRecruited &&
                        <TableCell align='left' title={row.recruiterDTO.rolesRecruited}>{row.recruiterDTO.rolesRecruited}</TableCell>
                      }
                      {row.recruiterDTO.companyName &&
                        <TableCell align='left' title={row.recruiterDTO.companyName}>{row.recruiterDTO.companyName}</TableCell>
                      }
                      {row.recruiterDTO.companyType &&
                        <TableCell align='left' title={row.recruiterDTO.companyType}>{row.recruiterDTO.companyType}</TableCell>
                      }
                      <TableCell align='left' title={row.offeredRate}>${row.offeredRate || 0}</TableCell>
                      <TableCell align='left' title={row.workType && row.workType.length > 15 ? row.workType : ''}>{row.workType}</TableCell>
                      <TableCell align='left' title={row.clientName}>{row.clientName}</TableCell>
                      <TableCell align='left' title={row.status || row.user.status}>{getIcon(row.status || row.user.status)}</TableCell>
                      {role && !showBlockUnblock && <TableCell align='left'>
                        {/* <Switch checked={row.user.status === 'APPROVED'} onChange={(event) => action(event.target.checked ? 'approve' : 'reject', row.id ? 'id' : 'mailId', row.id || row.user.mailId)}/> */}
                        <Typography component={'span'} sx={{ marginRight: '5rem', cursor: 'pointer', color: 'colors.green' }} title='Approve' onClick={() => checkAction(row)}><DoneAllSharp /></Typography>
                        <Typography component={'span'} sx={{ cursor: 'pointer', color: 'colors.error.red' }} title='Reject' onClick={() => action('reject', row.id ? 'id' : 'mailId', row.id || row.user.mailId)}><DoNotDisturbAltSharp /></Typography>
                      </TableCell>}
                    </TableRow>
                  )
                }
                if (viewName === 'Email count') {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                    >
                      <TableCell align='left' title={row.candidateMailId}>{row.candidateMailId}</TableCell>
                      <TableCell align='left' title={row.count}>{row.count}</TableCell>
                    </TableRow>
                  )
                }
                return (
                  viewName === 'Connected' || role ?
                    (!role || role === 'admin-candidate') ? <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                    >
                      {showCheckbox && <TableCell padding="checkbox" align='center'>
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>}
                      <TableCell align='left' title={row.firstName}>{row.firstName}</TableCell>
                      <TableCell align='left' title={row.lastName}>{row.lastName}</TableCell>
                      {row.recruiterMailId && <TableCell
                        align='left'
                        component="th"
                        scope="row"
                        padding="none"
                        title={row.recruiterMailId}
                      >
                        {row.recruiterMailId}
                      </TableCell>}
                      <TableCell
                        align='left'
                        component="th"
                        scope="row"
                        padding="none"
                        title={row.recruiteeMailId || row.user.mailId}
                      >
                        {row.recruiteeMailId || row.user.mailId}
                      </TableCell>
                      <TableCell align='left' title={row.currentRole}>{row.currentRole}</TableCell>
                      <TableCell align='left' title={row.currentCity}>{row.currentCity}</TableCell>
                      <TableCell align='left' title={row.currentAddress}>{row.currentAddress}</TableCell>
                      <TableCell
                        align='left'
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        title={row.clientName || row.keyClients}
                      >
                        {row.clientName || row.keyClients}
                      </TableCell>
                      <TableCell align='left' title={row.linkedInUrl}>{row.linkedInUrl}</TableCell>
                      {row.offeredRate && <TableCell align='left' title={row.offeredRate}>${row.offeredRate} {getRateType(row.rateType)}</TableCell>}
                      <TableCell align='left' title={row.minW2Rate}>{`${row.minW2Rate > 0 ? `$${row.minW2Rate} ${row.subscription === 'true' ? '(Subscription)' : ''}` : ''}`}</TableCell>
                      <TableCell align='left' title={row.minC2CRate}>{`${row.minC2CRate > 0 ? `$${row.minC2CRate}` : ''}`}</TableCell>
                      <TableCell sx={{ width: '2rem' }} title={row.skills && row.skills.length >= 15 ? row.skills : null} align='left'>{row.skills}</TableCell>
                      <TableCell align='left' title={row.referralinfo || ' - '}>{row.referralinfo}</TableCell>
                      <TableCell align='left' title={row.status || row.user.status}>{getIcon(row.status || row.user.status)}</TableCell>
                      {row.resume && downloadResume && <TableCell align='left' title='Download Resume'><Download cursor='pointer' onClick={() => downloadResume(row.resume, row.firstName)} /></TableCell>}
                      {role && !showBlockUnblock && <TableCell align='left'>
                        {/* <Switch checked={row.user.status === 'APPROVED'} onChange={(event) => action(event.target.checked ? 'approve' : 'reject', row.id ? 'id' : 'mailId', row.id || row.user.mailId)}/> */}
                        <Typography component={'span'} sx={{ marginRight: '5rem', cursor: 'pointer', color: 'colors.green' }} title='Approve' onClick={() => checkAction(row)}><DoneAllSharp /></Typography>
                        <Typography component={'span'} sx={{ cursor: 'pointer', color: 'colors.error.red' }} title='Reject' onClick={() => action('reject', row.id ? 'id' : 'mailId', row.id || row.user.mailId)}><DoNotDisturbAltSharp /></Typography>
                      </TableCell>}
                      {(role && showBlockUnblock) && <TableCell align='left'>
                        {/* <Switch checked={row.user.status === 'APPROVED'} onChange={(event) => action(event.target.checked ? 'approve' : 'reject', 'mailId', row.user.mailId)}/> */}
                        <ButtonComponent sx={{ width: '1rem', fontSize: '0.7rem', color: 'colors.white', height: 'auto' }}
                          label={row.user.status === 'APPROVED' ? 'BLOCK' : 'UNBLOCK'}
                          variant='contained'
                          color={row.user.status === 'APPROVED' ? 'error' : 'success'}
                          onClick={e => {
                            e.stopPropagation();
                            action(row.user.status === 'APPROVED' ? 'reject' : 'approve', 'mailId', row.user.mailId, `User ${row.user.status === 'APPROVED' ? 'Blocked' : 'Unblocked'}`)
                          }}
                        />
                      </TableCell>
                      }
                      {manage && <TableCell align='left'>
                        <ButtonComponent
                          sx={{ width: '1rem', fontSize: '0.7rem', color: 'primary.main' }}
                          label='Manage'
                          variant='text'
                          onClick={e => { e.stopPropagation(); manage(row.user.mailId) }}
                        />
                      </TableCell>}
                    </TableRow> :
                      <TableRow
                        hover
                        // onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={index}
                        selected={isItemSelected}
                      >
                        {showCheckbox && <TableCell padding="checkbox" align='center'>
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell>}
                        <TableCell
                          align='left'
                          component="th"
                          scope="row"
                          padding="none"
                          sx={{ color: `${row.resume ? 'primary.main' : 'color.black'}`, cursor: `${row.resume ? 'pointer' : 'cursor'}` }}
                          onClick={(e) => downloadResume(e, row.resume)}
                          title={row.firstName}
                        >
                          {row.firstName}
                        </TableCell>
                        <TableCell
                          align='left'
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          title={row.lastName}
                        >
                          {row.lastName}
                        </TableCell>
                        <TableCell align='left' title={row.companyName}>{row.companyName}</TableCell>
                        <TableCell align='left' title={row.companyType}>{row.companyType}</TableCell>
                        <TableCell align='left' title={row.user.phoneNo}>{row.user.phoneNo}</TableCell>
                        <TableCell align='left' title={row.rolesRecruited}>{row.rolesRecruited}</TableCell>
                        {(role && !showBlockUnblock) && <TableCell align='left'>
                          {/* <Switch checked={row.user.status === 'APPROVED'} onChange={(event) => action(event.target.checked ? 'approve' : 'reject', 'mailId', row.user.mailId)}/> */}
                          <Typography component={'span'} sx={{ marginRight: '5rem', cursor: 'pointer', color: 'colors.green' }} title='Approve' onClick={() => action('approve', 'mailId', row.user.mailId)}><DoneAllSharp /></Typography>
                          <Typography component={'span'} sx={{ cursor: 'pointer', color: 'colors.error.red' }} title='Reject' onClick={() => action('reject', 'mailId', row.user.mailId)}><DoNotDisturbAltSharp /></Typography>
                        </TableCell>
                        }
                        {(role && showBlockUnblock) && <TableCell align='left'>
                          {/* <Switch checked={row.user.status === 'APPROVED'} onChange={(event) => action(event.target.checked ? 'approve' : 'reject', 'mailId', row.user.mailId)}/> */}
                          <ButtonComponent sx={{ width: '1rem', fontSize: '0.7rem', color: 'colors.white', height: 'auto' }}
                            label={row.user.status === 'APPROVED' ? 'BLOCK' : 'UNBLOCK'}
                            variant='contained'
                            color={row.user.status === 'APPROVED' ? 'error' : 'success'}
                            onClick={e => {
                              e.stopPropagation();
                              action(row.user.status === 'APPROVED' ? 'reject' : 'approve', 'mailId', row.user.mailId, `User ${row.user.status === 'APPROVED' ? 'Blocked' : 'Unblocked'}`)
                            }}
                          />
                        </TableCell>
                        }
                      </TableRow>
                    :
                    <TableRow
                      hover
                      onClick={(event) => handlecheckBoxClick(event, row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected || row.resumeDownloaded}
                    >
                      {showCheckbox && <TableCell padding="checkbox" align='center'>
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>}
                      {(!hideConnect && viewName === 'All') || (!row.status || row.status === 'PENDING') ? <TableCell align='left'>
                        <ButtonComponent
                          sx={{ width: '1rem', fontSize: '0.7rem', color: 'primary.main' }}
                          label='Connect'
                          variant='text'
                          onClick={e => { e.stopPropagation(); connect(row) }}
                        />
                      </TableCell> : <TableCell align='left'>{row.status}</TableCell>}
                      <TableCell
                        align='left'
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        sx={{ color: `${row.resume ? 'primary.main' : 'color.black'}`, cursor: `${row.resume ? 'pointer' : 'cursor'}` }}
                        onClick={(e) => {e.stopPropagation(); downloadResume(e, row.resume, row.firstName, row.user.mailId)}}
                        title={row.firstName}
                      >
                        {row.firstName} {row.newRecruitee ? <Typography component={'span'} sx={{ color: 'colors.error.red', fontWeight: '600', fontSize: '0.7rem' }}>NEW</Typography> : ''}
                      </TableCell>
                      {!viewName && <TableCell align='left' title={row.currentRole}>{row.currentRole}</TableCell>}
                      <TableCell title={row.skills && row.skills.length >= 15 ? row.skills : null} align='left'>{row.skills}</TableCell>
                      {/*<TableCell title={row.keyIndustries && row.keyIndustries.length >= 30 ? row.keyIndustries : null} align='left'>{row.keyIndustries}</TableCell>*/}
                      <TableCell align='left' title={row.currentCity}>{row.currentCity}</TableCell>
                      <TableCell align='left' title={row.currentAddress}>{row.currentAddress}</TableCell>
                      <TableCell align='left' title={row.minW2Rate}>{`${row.minW2Rate > 0 ? `$${row.minW2Rate}` : ''}`}</TableCell>
                      {/*<TableCell align='left' title={row.minC2CRate}>{`${row.minC2CRate > 0 ? `$${row.minC2CRate}` : ''}`}</TableCell>*/}
                      <TableCell title={row.workType && row.workType.length > 15 ? row.workType : ''} align='left'>{row.workType}</TableCell>
                      <TableCell title={row.referralinfo} align='left'>{row.referralinfo}</TableCell>
                      <TableCell align='left' title={row.keyClients}>{row.keyClients}</TableCell>
                    </TableRow>
                )
              })}
          </TableBody>}
        </Table>
      </TableContainer>
      {
        !data.length && !loading && <Typography component={'span'} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2rem' }}>No Records Found</Typography>
      }

      {viewName !== 'Email count' && <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={'Rows Per Page'}
      />}

      {openResume &&
        <DialogComponent
          title='Upload Resume'
          open={(openResume.id || openResume.user.mailId) ? true : false}
          handleClose={() => setOpenResume(false)}
          content={uploadForm()}
          onConnect={() => { action('approve', openResume.id ? 'id' : 'mailId', openResume.id || openResume.user.mailId, null, fileData, openResume); setOpenResume(null) }}
          handleCancel={() => setOpenResume(false)}
          submitName='Approve'
          disable={!fileData}
        />

      }

    </Box>
  )
}
