import React from 'react'
import { Box } from '@mui/material'

export default function Terms() {
    return (
        <Box>
            <h3>Top Talent Technologies</h3>
            <h4>Terms of Service</h4>
            <p>These Terms of Service are a legal agreement made and entered into as of the sign-up day on the website (the “Effective Date”) by and between Top Talent Technologies Inc., a California corporation located at 45188 Tom Blalock Street, #311, Fremont, CA 94539 (“Marketplace”), and (“Client”, an individual who signs-up), each a “Party” and collectively the “Parties.” A person who signs-up to the website is the Client. If you do not agree with these Terms of Service, do not sign up on the website for or use our Marketplace job listing and matchmaking services.</p>
            <p>1. Services of the Marketplace. Marketplace agrees to render support and assistance to further Client’s career, including specifically providing job listing and matchmaking services for Client with potential employers in Client’s targeted industry(ies) and geographic location(s).</p>
            <p>2. Obligations of the Client. Client shall compensate Marketplace for Marketplace’s job
                listing and matchmaking services pursuant to these Terms of Service. In connection therewith,
                Client shall (a) maintain his or her resume in current and accurate form and content and (b)
                comply with the additional obligations of Client set forth in these Terms.</p>
            <p>3. Fees. Client shall compensate Marketplace with a commission fee, payable in the
                amount and under the circumstances indicated in this section.</p>
            <p>A fee of $5/hr, payable monthly for a duration of 12 months ($5/per hour for number of hours
                worked for a duration of 12 months) to (Marketplace), in the event Client accepts employment
                with hourly compensation, whether as an employee, consultant or independent contractor or
                through a limited liability company or other legal entity controlled by Client, with a company or
                business referred by Marketplace.</p>
            <p>A. Client shall be responsible for providing Marketplace with a monthly summary of Client’s
                compensation, including hours worked and hourly wage/rate if paid on an hourly basis, in a
                timely manner. Marketplace may reasonably request supporting documentation, including
                without limitation checks, paystubs, wage statements and employment, consulting, or
                independent contractor agreements, to confirm Client’s compensation.</p>
            <p>B. Marketplace shall invoice Client on a monthly basis for commission fees due under this
                service, payable on receipt. The failure of Marketplace to render an invoice shall not, however,
                relieve the Client of his or her obligation to pay Marketplace’s fees.</p>
            <p>C. Failure by Client to pay fees owed under this service on a timely basis may result in
                imposition by Marketplace of either or both late fees and interest at a rate of 1.5% per month or
                the highest rate otherwise permitted by applicable law.</p>
            <p>4. Marketplace Is Not Employer or Career Counseling Service. The services offered by
                Marketplace under this service and all related services provided by Marketplace via its talent marketplace website, The Talent Community <a href='https://www.thetalentcommunity.com' target='_blank'>https://www.thetalentcommunity.com</a> or <a href='https://www.thetalentcommunity.co' target='_blank'>https://www.thetalentcommunity.co</a>, are provided as an independent contractor.
                Notwithstanding any language in this service to the contrary, nothing herein shall be construed
                as creating an employer/employee relationship between Marketplace and Client or placing the
                Parties in a partnership or joint venture relationship. Nothing in this service shall be construed
                as creating the relationship of principal and agent between Marketplace and Client. Marketplace
                shall not be obligated to Client as a fiduciary.</p>
            <p>A. Client understands and acknowledges that Marketplace has no responsibility for
                payment of compensation due to Client or for withholding of all applicable federal, state or local
                taxes or contributions imposed under any unemployment insurance, social security, income tax
                or other tax law or regulation with respect to Client’s engagement by an employer referred by
                Marketplace.</p>
            <p>B. Client understands and acknowledges that Marketplace is not offering career counseling,
                vocational guidance, aptitude testing, executive consulting, personnel consulting or career
                management, evaluation or planning services. Marketplace is not providing its services under
                this service as an employment agency or employment counseling service.</p>
            <p>C. Client agrees that in any action or administrative complaint between Client and his or her
                employer (whether brought as an employee, consultant or independent contractor), Client shall
                not assert any claim, third-party claim, interpleader or other legal claim or defense against
                Marketplace and shall not join or seek to join Marketplace as a party to such action or
                administrative proceeding.</p>
            <p>5. Non-Circumvention/Remedies. Client acknowledges that Marketplace’s services will be
                based on the significant goodwill Marketplace has developed by virtue of its professional
                background, contacts and experience. Client accordingly agrees that Client (a) shall not
                circumvent this service by pursuing, soliciting or accepting an employment, consulting or
                independent contractor relationship with any potential employer referred by Marketplace during
                the term of this service , and (b) shall not otherwise avoid or seek to avoid the observance or
                performance of any of the material terms of this Agreement. The Parties agree that breach of
                this Section 5 by Client will cause irreparable damage to Marketplace and its goodwill, the exact
                amount of which will be difficult or impossible to ascertain, and that the remedies at law for any
                such breach are inadequate. Accordingly, Client agrees that in the event of a breach of this
                Section, in addition to any other remedy which may be available at law or in equity, Marketplace
                (c) shall be entitled to specific performance and injunctive remedies, as well as to obtain direct
                and consequential damages for breach of this service , and (d) may recover from Client as
                liquidated damages, and not as a penalty, the fees otherwise payable to Marketplace under
                Section 3 above. Any introduction via any channel (email, social media, or call, etc.) made to the
                ‘Client’ by the ‘Marketplace’ make a ‘Client’ agree to terms listed in the service. The introduction
                is not time bound.</p>
            <p>Notwithstanding the foregoing or anything else in this service to the contrary, and for the
                avoidance of doubt, nothing in this service shall be deemed to limit client’s ability to accept an

                employment, consulting or independent contractor relationship from any employer or business
                (a) that was not referred to him by Marketplace during the term of this service". In such event,
                no fee shall be owed to Marketplace</p>
            <p>6. Term. This service shall commence on the Effective Date hereof and shall continue,
                unless earlier terminated, for a period of (12) months or until such further expiration or renewal
                date as the Parties may designate in writing. All introductions are effective from the date of
                introduction made till 24 months.</p>
            <p>7. Limitation of Liability. Marketplace is not responsible for the content of any potential
                employer’s job application, messages, screener questions, skills assessments or their format or
                method of delivery. Marketplace (a) does not guarantee the identity of a potential employer or
                that any such potential employer will consider Client’s application or make a particular
                determination with regard to an application and (b) cannot make any guarantee regarding health
                and safety measures in a potential employer’s hiring or interviewing process. Nothing in this
                service constitutes a commitment, guarantee or warranty by Marketplace that Client will secure
                employment or otherwise receive compensation as a result of the job listing and matchmaking
                services provided by Marketplace.</p>
            <p>A. Career-related and hiring-related information provided by Marketplace, if any, is for
                informational purposes only, and is not to be construed as professional advice or career
                counseling. Client understands and acknowledges that Marketplace may give suggestions or
                information regarding best-practices and the suitability of potential employers, but that Client is
                ultimately responsibility to determine how to pursue his or her job search and career objectives.</p>
            <p>B. To the greatest extent permitted law, Marketplace DISCLAIMS ALL WARRANTIES,
                WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING WITHOUT
                LIMITATION THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                PURPOSE AND NON-INFRINGEMENT OF PROPRIETARY RIGHTS. MARKETPLACE
                DISCLAIMS ANY WARRANTIES REGARDING THE SECURITY, RELIABILITY, TIMELINESS
                AND PERFORMANCE OF MARKETPLACE’S TALENT MARKETPLACE WEBSITE. ALL
                MATERIALS, INFORMATION, ADVICE, RECOMMENDATIONS, REFERRALS, JOB LISTINGS
                AND SERVICES PROVIDED HEREUNDER OR AVAILABLE ON OR THROUGH
                MARKETPLACE’S TALENT MARKETPLACE ARE PROVIDED “AS IS” ON A BEST EFFORTS
                BASIS ONLY.</p>
            <p>C. UNDER NO CIRCUMSTANCES SHALL MARKETPLACE BE LIABLE TO CLIENT ON
                ACCOUNT OF CLIENT’S USE OR MISUSE OF OR RELIANCE ON MARKETPLACE’S
                TALENT MARKETPLACE WEBSITE OR MARKETPLACE’S SERVICES UNDER THIS
                SERVICE. SUCH LIMITATION OF LIABILITY SHALL FORECLOSE RECOVERY OF
                INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY AND PUNITIVE
                DAMAGES WHETHER A CLAIM IS BASED ON WARRANTY, CONTRACT, TORT (INCLUDING
                NEGLIGENCE), INDEMNITY OR OTHERWISE, EVEN IF MARKETPLACE OR ITS
                AFFILIATES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND
                NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY REMEDY. CLIENT’S

                SOLE AND EXCLUSIVE REMEDY FOR ANY CLAIM AGAINST MARKETPLACE SHALL BE
                LIMITED TO THE AMOUNT, IF ANY, PAID BY CLIENT IN FEES.</p>
            <p>7. General Provisions.</p>
            <p>A. Either Party may terminate this service in the event of material breach, if not cured by
                the other Party, after written notice, within thirty (30) days. This service shall not be assignable
                by the Client and may not be assigned by Marketplace without Client’s consent, which shall not
                unreasonably be withheld; provided however, that the Marketplace shall have the right to assign
                Marketplace’s right to receive fees payable under this service .</p>
            <p>B. This service shall be governed by and interpreted in accordance with the laws of the
                State of California, exclusive of principles of choice of law. Each of the Parties hereby consents
                to the exclusive personal jurisdiction of the federal or state courts located in San Francisco or
                Santa Clara Counties, California, and waives any right to a jury trial or class action treatment in
                connection with any action or litigation arising out of or related to this service. The prevailing
                Party in any legal action related to this service, including for breach, shall be entitled to recover
                its costs and reasonable attorneys' fees.</p>
            <p>C. It is acknowledged that the Client’s failure to pay fees owed and/or report compensation
                paid under these Terms of Service will cause Marketplace to incur economic losses in an
                amount which is difficult to ascertain with certainty, and that liquidated damages represent a fair
                estimate thereof. Accordingly, Client agrees that liquidated damages may be recovered by the
                Marketplace in the event of breach by the Client in the amount of $10,000, without limitation on
                the right of the Marketplace otherwise or in addition to terminate this Agreement. These
                liquidated damages are intended to represent estimated actual damages, not a penalty.</p>
            <p>D. In the event that any portion of this service is held be unenforceable, such portions shall
                not limit or otherwise modify or affect any other portion of this service . This service shall be
                binding upon the Parties hereto and their respective successors and assigns. This service
                constitutes the entire service between the Parties hereto with respect to the subject matter
                hereof and may not be modified, amended or otherwise altered, except in a written addendum
                signed by both Parties.</p>
            <p>E. You acknowledge that you have read these Terms of Service, understand these Terms
                and agree to be bound by these Terms.</p>
            <p>Email: anshul@thetalentcommunity.com</p>
        </Box>
    )
}