export const AppTheme = {
  palette: {
    primary: {
      main: '#3b9aff',
    },
    colors: {
      white: '#ffffff',
      black: '#000000',
      lightWhite: '#f8f8f8',
      bgColor: '#32394b',
      gray: '#808080',
      green: '#007500',
      error: {
        red: '#e56566'
      }
    }
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          // fontSize: '1.6rem',
          backgroundColor: '#f8f8f8'
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        asterisk: {
          color: 'red'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: '3rem',
          // fontSize: '1.6rem',
          color: '#ffffff',
          fontWeight: '700'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '0.9rem',
          // borderBottom: 'none',
          minWidth: '3rem',
          maxWidth: '15rem',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          minWidth: '3rem',
          maxWidth: '15rem',
          height: '3rem'
        }
      }
    }
  }
}
