import React, { useRef, useState, useEffect } from 'react'
import { Box, Stack, Grid, Typography } from '@mui/material'
import DataTable from '../../components/Datatable'
import DialogComponent from '../../components/Dialog'
import TextInput from '../../components/TextField'
import SearchComponent from '../../components/SearchComponent'
import ButtonComponent from '../../components/Button'
import FullScreenDialog from '../../components/FullScreenDIalog'
import AdvancedSearch from './AdvancedSearch'
import { Logout } from '@mui/icons-material'
import { logout } from '../../store/reducers/login'
import { useDispatch } from 'react-redux'
import Candidate from '../../client/API/Candidate'
import Search from '../../client/API/Search'
import { setMessage } from '../../store/reducers/message'
import DropDown from '../../components/DropDown'
import Loader from '../../components/Loader'
import CheckboxComponent from '../../components/Checkbox'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add';

import DocViewer, { DocViewerRenderers } from "react-doc-viewer"
import Auth from '../../client/API/Auth'
import FilterButton from '../../components/FilterButton'
import RadioButton from '../../components/RadioButton';

const Input = styled('input')({
  display: 'none',
})

// {
  //   id: 'keyIndustries',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Industry',
  //   sort: true
  // },
  // {
  //   id: 'minC2CRate',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'C2C Rate',
  //   sort: true
  // },

const headers = [
  {
    id: 'action',
    label: 'Connect',
    sort: false,
    hide: false
  },
  {
    id: 'firstName',
    numeric: false,
    disablePadding: true,
    label: 'First Name',
    sort: true
  },
  {
    id: 'skills',
    numeric: false,
    disablePadding: false,
    label: 'Skills',
    sort: true
  },
  {
    id: 'currentCity',
    numeric: false,
    disablePadding: false,
    label: 'City',
    sort: true,
  },
  {
    id: 'currentAddress',
    numeric: false,
    disablePadding: false,
    label: 'State',
    sort: true,
  },
  {
    id: 'minW2Rate',
    numeric: false,
    disablePadding: false,
    label: 'W2 Rate',
    sort: true
  },
  {
    id: 'workType',
    numeric: false,
    disablePadding: false,
    label: 'Work Type',
    sort: true
  },
  {
    id: 'refferalInfo',
    numeric: false,
    disablePadding: false,
    label: 'Work Authorization',
    sort: true
  },
  {
    id: 'keyClients',
    numeric: false,
    disablePadding: false,
    label: 'Current/Past Clients',
    sort: true
  }
]

const headers_saved = [
  {
    id: 'action',
    label: 'Connect',
    sort: false,
    hide: false
  },
  {
    id: 'firstName',
    numeric: false,
    disablePadding: true,
    label: 'First Name',
    sort: true
  },
  {
    id: 'currentRole',
    label: 'Role',
    sort: true
  },
  {
    id: 'skills',
    numeric: false,
    disablePadding: false,
    label: 'Skills',
    sort: true
  },
  {
    id: 'keyIndustries',
    numeric: false,
    disablePadding: false,
    label: 'Industry',
    sort: true
  },
  {
    id: 'currentCity',
    numeric: false,
    disablePadding: false,
    label: 'City',
    sort: true,
  },
  {
    id: 'currentAddress',
    numeric: false,
    disablePadding: false,
    label: 'State',
    sort: true,
  },
  {
    id: 'minW2Rate',
    numeric: false,
    disablePadding: false,
    label: 'W2 Rate',
    sort: true
  },
  {
    id: 'minC2CRate',
    numeric: false,
    disablePadding: false,
    label: 'C2C Rate',
    sort: true
  },
  {
    id: 'workType',
    numeric: false,
    disablePadding: false,
    label: 'Work Type',
    sort: true
  },
  {
    id: 'keyClients',
    numeric: false,
    disablePadding: false,
    label: 'Current/Past Clients',
    sort: true
  }
]

const connectedHeaders = [
  {
    id: 'firstName',
    numeric: false,
    disablePadding: true,
    label: 'First Name',
    sort: true,
  },
  {
    id: 'role',
    label: 'Role',
    sort: true
  },
  {
    id: 'city',
    numeric: false,
    disablePadding: true,
    label: 'City',
    sort: true,
  },
  {
    id: 'minW2Rate',
    numeric: false,
    disablePadding: true,
    label: 'W2 Rate',
    sort: true,
  },
  {
    id: 'minC2CRate',
    numeric: false,
    disablePadding: true,
    label: 'C2C Rate',
    sort: true,
  },
  {
    id: 'offeredRate',
    numeric: false,
    disablePadding: true,
    label: 'Offered Rate',
    sort: true,
  },
  {
    id: 'workType',
    numeric: false,
    disablePadding: true,
    label: 'Work Type',
    sort: true,
  },
  {
    id: 'clientName',
    numeric: false,
    disablePadding: true,
    label: 'Client Name',
    sort: true,
  },
  // {
  //   id: 'status',
  //   numeric: false,
  //   disablePadding: true,
  //   label: 'Status',
  //   sort: true,
  // }
]

const search_headers = [
  {
    id: 'action',
    label: 'Connect',
    sort: false,
    hide: false
  },
  {
    id: 'firstName',
    numeric: false,
    disablePadding: true,
    label: 'First Name',
    sort: true
  },
  {
    id: 'currentRole',
    label: 'Role',
    sort: true
  },
  {
    id: 'skills',
    numeric: false,
    disablePadding: false,
    label: 'Skills',
    sort: true
  },
  {
    id: 'currentCity',
    numeric: false,
    disablePadding: false,
    label: 'City',
    sort: true,
  },
  {
    id: 'currentAddress',
    numeric: false,
    disablePadding: false,
    label: 'State',
    sort: true,
  },
  {
    id: 'minW2Rate',
    numeric: false,
    disablePadding: false,
    label: 'W2 Rate',
    sort: true
  },
  {
    id: 'workType',
    numeric: false,
    disablePadding: false,
    label: 'Work Type',
    sort: true
  },
  {
    id: 'refferalInfo',
    numeric: false,
    disablePadding: false,
    label: 'Work Authorization',
    sort: true
  },
  {
    id: 'keyClients',
    numeric: false,
    disablePadding: false,
    label: 'Current/Past Clients',
    sort: true
  }
]

const referredHeaders = [
  {
    id: 'details',
    label: 'Details'
  },
  {
    id: 'invitationSent',
    label: 'Invitation Sent'
  },
  {
    id: 'signedUp',
    label: 'Signed Up'
  },
  {
    id: 'approved',
    label: 'Approved'
  },
  {
    id: 'connected',
    label: 'Connected'
  },
  {
    id: 'earnings',
    label: 'Earnings/Hr'
  }
]

const filterData = [
  {
    name: 'Role',
    key: 'currentRole',
    options: []
  },
  {
    name: 'Skills',
    key: 'skills',
    options: []
  },
  {
    name: 'Industry',
    key: 'keyIndustries',
    options: ['Retail', 'Technology', 'Banking and Finance', 'Pharmaceutical', 'Automobile', 'Information Technology and Services', 'Others']
  },
  {
    name: 'Work Type',
    key: 'workType',
    options: ['Hybrid', 'In Person', 'Remote']
  },
  {
    name: 'Current / Past Clients',
    key: 'keyClients',
    options: ['Apple', 'Meta', 'Google', 'Microsoft', 'Amazon', 'Walmart', 'Adobe', 'Intuit', 'Cisco',
      'LinkedIn', 'Wells Fargo', 'Target', 'Salesforce', 'Visa', 'Bank of the West', 'PG&E'],
  },
  {
    name: 'W2 Rate',
    key: 'minW2Rate',
    type: 'slider'
  }
]

export default function Dashboard() {

  const [value, setValue] = useState('All')
  const [open, setOpen] = useState(false)
  const [details, setDetails] = useState({ workType: [], rateType: 'W2' })
  const [error, setError] = useState({})
  const [searchOpen, setSearchOpen] = useState(false)
  const [candidates, setCandidates] = useState({})
  const [pageDetails, setPageDetails] = useState({ page: 0, limit: 10 })
  const [loading, setLoading] = useState(false)
  const [searchFormData, setSearchFormData] = useState({ criteria: [] })
  const [savedSearch, setSavedSearch] = useState([])
  const [searchResult, setSearchResult] = useState({})
  const [pageDetailsOfSearch, setPageDetailsOfSearchResult] = useState({ page: 0, limit: 10 })
  const [searchName, setSearchName] = useState('')
  const [openNamePopup, setOpenNamePopup] = useState(false)
  const [selectedSearch, setSelectedSearch] = useState(false)
  const [showLoader] = useState(false)
  const [data, setData] = useState(null)
  const [openReferal, setOpenReferal] = useState(false)
  const [referalObj, setReferalObj] = useState({ availableRoles: '', workAuth: '', name: '', email: '', resume: '' })
  const [refLoading, setRefLoading] = useState(false)
  const [selectedRole, setSelectedRole] = useState('')
  const [search, setSearch] = useState(false)
  const [name, setName] = useState('')
  const [roles, setRoles] = useState({})
  const [selectedOption, setSelectedOption] = useState({});
  const [filters, setFilters] = useState([]);
  const [showBox, setShowBox] = useState(false);

  const searchRef = useRef(null)

  const dispatch = useDispatch()

  const getAllCandidates = async (pageDetails, filter = false) => {
    setLoading(true)
    const user = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')) : null
    const res = await Candidate.all(pageDetails, user.mailId)
    if (res && res.recruitees) {
      const emails = []
      res.recruitees.forEach(r => {
        emails.push({ recruiteeMailId: r.user.mailId })
      })
      const statuses = await Candidate.getStatus({ mailId: user.mailId, emails })
      if (Array.isArray(statuses) && statuses.length) {
        statuses.forEach(s => {
          res.recruitees.forEach(r => {
            if (r.user.mailId === s.recruiteeMailId) {
              r.status = s.status
            }
          })
        })
      }
      setCandidates(res)
    } else {
      dispatch(setMessage({ type: 'error', text: res }))
    }
    setLoading(false)
  }

  const getUserName = async mailId => {
    const response = await Auth.getLoggedInUserInfo(mailId)
    if (response && response.firstName) {
      setName(response.firstName)
    }
  }

  const getRolesList = async () => {
    let res = {}
    try {
      res = await Search.getRoles('search/roles')
      if (res.roles) {
        const r = res.roles.filter(d => [
          'Project Manager', 'Program Manager', 'Java Engineer', 'Data Engineer', 'Python Engineer',
          'Business Analyst', 'Quality Analyst', 'Devops Engineer', 'UX/UI Engineer'
        ].indexOf(d.role) > -1)
        filterData[0].options = r
        setRoles({
          ...res,
          roles: r
        })
      }
    } catch (error) {
      dispatch(setMessage({ type: 'error', text: res }))
    }
  }

  const getLocationList = async () => {
    let res = {}
    try {
      res = await Search.getLocations()
      if (res.cities && res.cities.length) {
        filterData[3].options = [...new Set(res.cities.map(city => city.trim()).sort())]
      }
      if (res.states && res.states.length) {
        filterData[4].options = [...new Set(res.states.map(state => state.trim()).sort())]
      }
    } catch (error) {
      dispatch(setMessage({ type: 'error', text: res }))
    }
  }

  useEffect(() => {
    const loggedInUser = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')) : null
    getUserName(loggedInUser.mailId)
    getRolesList()
    // getLocationList()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (filters.length) {
      applyFilter({ ...pageDetails });
    } else if (value === 'All') {
      if (selectedRole) {
        searchRole(selectedRole, pageDetails)
      } else {
        getAllCandidates(pageDetails)
      }
    } else if (value === 'Saved') {
      handleDropDownClick(selectedSearch, pageDetails)
    } else if (value === 'Connected') {
      getConnectedCandidates(pageDetails)
    } else {
      getReferredCandidates(pageDetails)
    }
  }, [pageDetails]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (searchOpen) {
      triggerSearch(pageDetailsOfSearch)
    }
  }, [pageDetailsOfSearch]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedOption.update)
      applyFilter({ page: 0, limit: 10 })
  }, [selectedOption]
  )

  const handleClick = (newValue, name) => {
    setValue(newValue)
    setSelectedRole('')
    setSearch(false)
    if (newValue === 'All') {
      // setCandidates({})
      getAllCandidates({ page: 0, limit: 10 })
    } else if (newValue === 'Saved') {
      setCandidates({})
      getAllSavedSearch({ page: 0, limit: 100 }, name)
    } else if (newValue === 'Connected') {
      setCandidates({})
      getConnectedCandidates({ page: 0, limit: 10 })
    } else {
      setCandidates({})
      getReferredCandidates({ page: 0, limit: 10 })
    }
  }

  const getConnectedCandidates = async data => {
    setLoading(true)
    const user = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')) : null
    const res = await Candidate.getConnected({ ...data, mailId: user.mailId })
    if (res && res.connects) {
      setCandidates(res)
    } else {
      dispatch(setMessage({ type: 'error', text: res }))
    }
    setLoading(false)
  }

  const getReferredCandidates = async data => {
    setLoading(true)
    const user = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')) : null
    const res = await Candidate.getReferredForHR(data, user.mailId)
    if (res && res.recruiteeReferals) {
      setCandidates(res)
    } else {
      dispatch(setMessage({ type: 'error', text: res }))
    }
    setLoading(false)
  }

  const connect = candidate => {
    setOpen(candidate)
  }

  const setFormValue = (field, val) => {
    if (field === 'workType') {
      if (details.workType.indexOf(val) < 0) {
        val = [...details.workType, val]
      } else {
        details.workType.splice(details.workType.indexOf(val), 1)
        val = [...details.workType]
      }
    }
    setDetails({
      ...details,
      [field]: val
    })
    setError({})
  }

  const setRefFormValue = (field, val) => {
    setReferalObj({
      ...referalObj,
      [field]: val
    })
    setError({})
  }

  const onConnect = async (e) => {
    e.preventDefault()
    let showError = false
    const { clientName, offeredRate, skills, workType, rateType } = details
    if (!clientName) {
      return setError({
        clientName: 'Please enter Client Name'
      })
    } else if (!skills) {
      return setError({
        skills: 'Please enter Skills'
      })
    } else if (workType && !workType.length) {
      return setError({
        workType: 'Please choose Work Type'
      })
    } else if (workType && workType.length) {
      const allowed = open.workType.split(',')
      workType.forEach(type => {
        if (allowed.indexOf(type) < 0) {
          showError = true
        }
      })
      if (showError) {
        return setError({
          workType: `Candidate is only looking for ${open.workType}`
        })
      }
    }
    // else if (!offeredRate) {
    //   return setError({
    //     offeredRate: 'Please enter Minimum Rate offered'
    //   })
    // } 
    // if ((rateType === 'W2' || rateType === 'W2 (Subscription)') && !open.minW2Rate) {
    //   return setError({
    //     offeredRate: 'This Candidate is available only for C2C'
    //   })
    // } else if ((rateType === 'W2' || rateType === 'W2 (Subscription)') && Number(offeredRate) < open.minW2Rate) {
    //   return setError({
    //     offeredRate: `The minimum W2 rate is $${open.minW2Rate}`
    //   })
    // } else if (rateType === 'C2C' && !open.minC2CRate) {
    //   return setError({
    //     offeredRate: 'This Candidate is available only for W2'
    //   })
    // } else if (rateType === 'C2C' && Number(offeredRate) < open.minC2CRate) {
    //   return setError({
    //     offeredRate: `The minimum C2C rate is $${open.minC2CRate}`
    //   })
    // }
    setLoading(true)
    const user = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')) : null
    const res = await Candidate.connect({
      ...details,
      workType: `${details.workType.length ? details.workType.toString() : ''}`,
      rateType: (details.rateType === 'W2' || details.rateType === 'W2 (Subscription)') ? 'minW2Rate' : 'minC2CRate',
      recruiterMailId: user.mailId,
      recruiteeMailId: open.user.mailId,
      subscription: (details.rateType === 'W2 (Subscription)').toString()
    })
    setLoading(false)
    if (typeof res === 'boolean') {
      dispatch(setMessage({ type: 'success', text: 'Success!' }))
    } else {
      dispatch(setMessage({ type: 'error', text: res }))
    }
    closeDialog()
  }

  const closeDialog = () => {
    setDetails({ workType: [], rateType: 'W2' })
    setOpen(false)
  }

  const renderForm = () => {
    return (
      <Box sx={{ margin: '1rem 0', width: { lg: '30rem', md: '30rem' } }}>
        <TextInput
          sx={{ marginBottom: '1rem' }}
          value={details.clientName || ''}
          variant='outlined'
          name='clientName'
          label='Enter Client Name'
          onChange={setFormValue}
          error={error.clientName}
          autoFocus={true}
          required={true}
        />
        <TextInput
          sx={{ marginBottom: '1rem' }}
          value={details.skills || ''}
          variant='outlined'
          name='skills'
          label='Top skills'
          onChange={setFormValue}
          error={error.skills}
          required={true}
        />
        <TextInput
          sx={{ marginBottom: '1rem' }}
          value={details.role || ''}
          variant='outlined'
          name='role'
          label='Role'
          onChange={setFormValue}
          error={error.role}
          required={true}
        />
        {/* <Stack direction='row' spacing={1}>
          <TextInput
            sx={{ marginBottom: '1rem' }}
            value={details.offeredRate || ''}
            variant='outlined'
            name='offeredRate'
            label='Minimum rate'
            onChange={setFormValue}
            error={error.offeredRate}
            required={true}
            inputProps={{ maxLength: 3 }}
          />
          <DropDown data={['W2', 'C2C', 'W2 (Subscription)']} value={details.rateType} handleChange={value => setFormValue('rateType', value)} noDefault={true} />
        </Stack> */}
        <Box sx={{ fontWeight: 'bold', fontSize: '1rem', marginBottom: '0.5rem' }}>
          Is the Role<Typography component={'sup'} sx={{ color: 'colors.error.red' }}>*</Typography>
        </Box>
        {error.workType && <Box sx={{ color: 'colors.error.red', margin: '1rem 0', fontSize: '1rem' }}>{error.workType}</Box>}
        <Stack direction={'row'} spacing={3}>
          {
            ['Hybrid', 'In Person', 'Remote'].map(type =>
              <CheckboxComponent
                key={type}
                label={type}
                onChange={() => setFormValue('workType', type)}
              />
            )
          }
        </Stack>
      </Box>
    )
  }

  const searchData = async () => {
    setSearch(true)
    setLoading(true)
    const searchText = searchRef.current.value
    const user = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')) : null
    const res = await Candidate.search({ ...pageDetails, searchText, recruiterMailId: user.mailId })
    if (res && Array.isArray(res.recruitees)) {
      if (value === 'Connected') {
        setCandidates({
          ...res,
          connects: res.recruitees
        })
      } else if (value === 'Saved') {
        setCandidates({
          ...res,
          savedRecruitees: res.recruitees
        })
      } else if (value === 'Referred') {
        setCandidates({
          ...res,
          recruiteeReferals: res.recruitees
        })
      } else {
        setCandidates(res)
      }
    } else {
      dispatch(setMessage({ type: 'error', text: res }))
    }
    setLoading(false)
  }

  const onSearch = e => {
    e.preventDefault()
    const { current } = searchRef
    if (current.value) {
      searchData(current.value)
    }
  }

  const handleDropDownClick = async (selectedSearch, p) => {
    if (selectedSearch && selectedSearch.name) {
      setSelectedSearch(selectedSearch)
      setLoading(true)
      const res = await Search.run({ page: p ? p.page : 0, limit: p ? p.limit : 10, name: selectedSearch.name })
      const user = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')) : null
      if (res && res.recruitees) {
        const emails = []
        res.recruitees.forEach(r => {
          emails.push({ recruiteeMailId: r.user.mailId })
        })
        const statuses = await Candidate.getStatus({ mailId: user.mailId, emails })
        if (Array.isArray(statuses) && statuses.length) {
          statuses.forEach(s => {
            res.recruitees.forEach(r => {
              if (r.user.mailId === s.recruiteeMailId) {
                r.status = s.status
              }
            })
          })
        }
        setCandidates({
          savedRecruitees: res.recruitees,
          count: res.count
        })
      } else {
        dispatch(setMessage({ type: 'error', text: res }))
      }
      setLoading(false)
    }
  }

  // const getRoleListForRole = role => {
  //   switch (role) {
  //     case 'Program Manager':
  //       return ['program manatger', 'program manager', 'program manager for cisco event tools', 'technical program manager', 'senior program manager',
  //         'sr. program manager']
  //     case 'Project Manager':
  //       return ['technical project manager', 'senior technical project manager', 'sr. technical project manager', 'project manager', 'it project manager',
  //         'senior project manager', 'sr. project manager', 'program manager/project manager', 'product owner']
  //     case 'Product Manager':
  //       return [role.toLowerCase(), 'product owner']
  //     case 'Java Engineer':
  //       return ['java engineer', 'senior software architect', 'senior software engineer', 'senior software engineer / architect',
  //         'software engineer', 'sr. java developer', 'senior java developer', 'sr. full stack java developer', 'senior full stack java developer',
  //         'senior backend developer', 'java full stack developer', 'data architect', 'lead software engineer', 'sr. software engineer']
  //     case 'Quality Analyst':
  //       return [role.toLowerCase(), 'qa analyst', 'qa automation test engineer', 'software qa engineer']
  //     case 'Mobile Engineer':
  //       return [role.toLowerCase(), 'android developer', 'Android Developer']
  //     default:
  //       return [role.toLowerCase()]
  //   }
  // }

  const searchRole = async (role, pageDetails) => {
    setLoading(true)
    let res = {}
    try {
      res = await Search.roles({ page: pageDetails.page || 0, limit: pageDetails.limit || 10, role })
      if (res && res.recruitees) {
        setCandidates(res)
      }
    } catch (error) {
      dispatch(setMessage({ type: 'error', text: res }))
    }
    setLoading(false)
  }

  const filterRole = (role) => {
    if (role) {
      if (role === 'Java Engineer') {
        filterData[1].options = ['React', 'Node', 'Fullstack', 'Frontend', 'Backend', 'Angular', 'BigData', 'Core Java', 'Vue',
          'Azure', 'Cloud(Aws/Azure)', 'Data Structure', 'Algorithms', 'API', 'Microservices', 'Kafka', 'UI', 'SQL/NoSQL',
          'React JS/Vue JS', 'JavaScript/TypeScript', 'React Native Framework', 'Spring/Spring Boot', 'Hibernate',
          'Multithreading', 'RestAPI', 'Agile/SCRUM', 'GIT', 'Redux', 'Junit']
      } else if (['Project Manager', 'Program Manager'].indexOf(role) > -1) {
        filterData[1].options = ['Infrastructure', 'Security', 'Networking', 'Software Development', 'DevOps', 'Ecommerce',
          'Healthcare', 'Utility', 'Banking ', 'Pharma', 'Salesforce', 'Azure', 'AWS', 'Cloud', 'Retail',
          'Agile ', 'Scrum', 'Data Analysis', 'PMP, CSM', 'Java/.net (Coding)', 'ERP', 'CRM', 'Planning',
          'Budgeting', 'Business Analysis', 'Product Owner', 'Supply Chain', 'Release Management', 'API',
          'SAAS', 'PMP', 'CSM', 'Risk Management']
      } else {
        filterData[1].options = []
      }
      // searchRole(role, {})
    } else {
      getAllCandidates({ page: 0, limit: 10 })
    }
    setSelectedRole(role)
  }

  const getAllSavedSearch = async (data, name) => {
    const user = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')) : null
    const res = await Search.getSaved({ ...data, mailId: user.mailId })
    if (res && res.saveSearches) {
      setSavedSearch(res.saveSearches)
      if (name) {
        const record = res.saveSearches.findIndex(data => data.name === name)
        handleDropDownClick(res.saveSearches[record])
      } else {
        handleDropDownClick(res.saveSearches[0])
      }
    }
  }

  const setValues = (field, val) => {
    setSearchFormData({
      ...searchFormData,
      [field]: val
    })
  }

  const handleSearchSave = async () => {
    if (!searchName) {
      setError({ searchName: 'Please provide a name for your search' })
      return
    } else if (!searchFormData.keywords && !searchFormData.criteria.length) {
      dispatch(setMessage({ type: 'warning', text: 'Please Provide Search Details' }))
      return
    } else if (searchFormData.criteria.length) {
      searchFormData.criteria.some(d => {
        if (!d.key || !d.condition) {
          dispatch(setMessage({ type: 'warning', text: 'Please Provide Search Details' }))
          return true
        }
        return false
      })
    }
    setLoading(true)
    const filters = []
    const searchValues = { ...searchFormData }
    if (searchValues.keywords) {
      filters.push({
        criteria: {
          key: 'resumeContent',
          value: searchValues.keywords.toUpperCase(),
          condition: 'resumeContains'
        }
      })
    }
    searchValues.criteria.forEach((data, index) => {
      filters.push({
        condition: filters.length > 0 && index === 0 ? 'AND' : index === 0 ? '' : searchValues.criteria[index - 1]?.logicOp,
        criteria: {
          key: data.key,
          value: data.value,
          condition: data.condition
        }
      })
    })
    const user = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')) : null
    const res = await Search.save({
      name: searchName,
      recruiterMailId: user.mailId,
      filters: {
        filters
      }
    })
    if (res) {
      setOpenNamePopup(false)
      dispatch(setMessage({ type: 'success', text: 'Search Saved!' }))
      // if (value === 'Saved') setSelectedSearch('true')
      setSearchOpen(false)
      setCandidates({})
      setSearchFormData({ criteria: [] })
      handleClick('All', searchName)
    } else {
      dispatch(setMessage({ type: 'error', text: res }))
    }
    setLoading(false)
  }

  const triggerSearch = async ({ page, limit }) => {
    if (!searchFormData.keywords && !searchFormData.criteria.length) {
      dispatch(setMessage({ type: 'warning', text: 'Please Provide Search Details' }))
      return
    } else if (searchFormData.criteria.length) {
      searchFormData.criteria.some(d => {
        if (!d.key || !d.condition) {
          dispatch(setMessage({ type: 'warning', text: 'Please Provide Search Details' }))
          return true
        }
        return false
      })
    }
    setLoading(true)
    const filters = []
    const searchValues = { ...searchFormData }
    if (searchValues.keywords) {
      filters.push({
        criteria: {
          key: 'resumeContent',
          value: searchValues.keywords.toUpperCase(),
          condition: 'resumeContains'
        }
      })
    }
    searchValues.criteria.forEach((data, index) => {
      filters.push({
        condition: filters.length > 0 && index === 0 ? 'AND' : index === 0 ? '' : searchValues.criteria[index - 1]?.logicOp,
        criteria: {
          key: data.key,
          value: data.value,
          condition: data.condition
        }
      })
    })
    const user = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')) : null
    const res = await Candidate.advanceSearch({ page, limit, filters, mailId: user.mailId })
    if (typeof res === 'string') {
      dispatch(setMessage({ type: 'error', text: res }))
    } else {
      setSearchResult(res)
    }
    setLoading(false)
  }

  const download = async (e, name) => {
    e.preventDefault()
    if (name) {
      setData(name)
    }
  }

  const getTitle = value => {
    if (value === 'Saved') {
      return 'Candidates curated for you'
    } else if (value === 'Connected') {
      return 'Candidates you already connected with'
    } else {
      return 'All candidates are listed here'
    }
  }

  const renderReferForm = () => {
    return (
      <Box sx={{ margin: '1rem 0', width: { lg: '30rem', md: '30rem' } }}>
        <Typography component={'p'} sx={{ fontSize: '1.2rem', marginBottom: '0.5rem' }}>
          Help a candidate; who did not meet your client requirement, not a good fit or did not agree to move forward with you, etc.
        </Typography>
        <Typography component={'p'} sx={{ fontSize: '1rem', marginBottom: '1rem' }}>
          Your referred candidate will be shared across the community and you will have access to all candidates referred in the community.
        </Typography>
        <DropDown
          label='Select Available Role'
          data={roles.roles}
          value={referalObj.availableRoles}
          handleChange={value => setRefFormValue('availableRoles', value)}
          noDefault={true}
          sx={{ marginBottom: '2rem' }}
          required={true}
          dataKey='role'
          dataValue='role'
        />
        {error.availableRoles && <Box sx={{ color: 'colors.error.red', margin: '-1.5rem 0 1rem 0', fontSize: '1rem' }}>Please choose your role</Box>}
        <DropDown
          label='Select Work Authorization'
          data={['US Citizen', 'Green Card Holder', 'Green Card EAD', 'H4 EAD', 'L2 EAD', 'TN Visa']}
          value={referalObj.workAuth}
          handleChange={value => setRefFormValue('workAuth', value)}
          noDefault={true}
          sx={{ marginBottom: '2rem' }}
          required={true}
        />
        {error.workAuth && <Box sx={{ color: 'colors.error.red', margin: '-1.5rem 0 1rem 0', fontSize: '1rem' }}>Please select your Work Authorization</Box>}
        <TextInput
          sx={{ marginBottom: '1rem' }}
          value={referalObj.name || ''}
          variant='outlined'
          name='name'
          label='Candidate Name'
          onChange={setRefFormValue}
          error={error.name}
          autoFocus={true}
          required={true}
        />
        <TextInput
          sx={{ marginBottom: '1rem' }}
          value={referalObj.email || ''}
          variant='outlined'
          name='email'
          label='Email Address'
          onChange={setRefFormValue}
          error={error.email}
          required={true}
        />
        <Box sx={{ margin: '1rem 0' }}>
          {error.resume && <Box sx={{ color: 'colors.error.red', margin: '1rem 0', fontSize: '1rem' }}>Please upload Resume</Box>}
          <label htmlFor="contained-button-file">
            {/*".pdf, .doc, .docx"*/}
            <Input accept=".pdf, .doc, .docx" id="contained-button-file" multiple type="file" onChange={(evt) => setRefFormValue('resume', evt.target.files[0])} />
            <Button variant="contained" component="span">
              Upload Resume
            </Button><Typography component={'sup'} sx={{ marginLeft: '1rem', color: 'colors.error.red' }}>*</Typography>
            {referalObj.resume && <Typography sx={{ marginLeft: '0.5rem' }} component={'span'}>{referalObj.resume.name}</Typography>}
          </label>
        </Box>
      </Box>
    )
  }

  const uploadResume = async (file) => {
    if (file) {
      const formData = new FormData()
      formData.append('file', file);
      try {
        const res = await Candidate.uploadResume(formData)
        return res
      } catch (error) {
        return false
      }
    }
  }

  const refer = async () => {
    let errored = false
    Object.entries(referalObj).some(data => {
      if (!data[1]) {
        setError({
          [data[0]]: `${data[0][0].toUpperCase()}${data[0].slice(1, data[0].length)} is Required`
        })
        errored = true
        return true
      }
      return false
    })

    if (!errored) {
      setRefLoading(true)
      const checkEmail = await Candidate.checkEmail(referalObj.email)
      if (checkEmail.code !== '200') {
        setError({
          email: checkEmail.message === 'user_exists_in_the_system' ? 'Candidate exists in the community' : 'Candidate already referred'
        })
        setRefLoading(false)
        return
      }
      const fileName = await uploadResume(referalObj.resume)
      if (fileName === 'There was a problem communicating with the server. Please try again') {
        setRefLoading(false)
        return dispatch(setMessage({ type: 'error', text: 'There was a problem communicating with the server. Please try again' }))
      }
      const user = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')) : null
      const res = await Candidate.refer({
        roles: referalObj.availableRoles,
        workAuthorization: referalObj.workAuth,
        name: referalObj.name,
        mailId: referalObj.email,
        resume: fileName,
        recruiterMailId: user.mailId
      })
      setRefLoading(false)
      if (typeof res !== 'string') {
        dispatch(setMessage({ type: 'success', text: 'Candidate referred!' }))
      } else {
        dispatch(setMessage({ type: 'error', text: res }))
      }
      setOpenReferal(false)
      setReferalObj({ availableRoles: '', workAuth: '', name: '', email: '', resume: '' })
      setError({})
    }
  }

  const Header = () => {
    return (
      <Grid container spacing={2} sx={{ backgroundColor: 'colors.bgColor', padding: '1rem 1rem', color: 'colors.white', fontWeight: 600, fontSize: '1rem', overflow: 'hidden' }}
        justifyContent={'flex-start'} alignItems={'center'}>

        <Grid item lg={2.5} md={4.5} sm={12} xs={12}>
          <Stack direction={'column'} spacing={1}>
            <Box sx={{ fontSize: '1rem' }}>{`Welcome ${name}`}</Box>
            {/* <Stack direction={'row'} spacing={4} justifyContent={'flex-start'} alignItems={'center'}>
              {['All', 'Saved', 'Connected', 'Referred'].map(menu =>
                <Box key={menu}
                  sx={{ cursor: 'pointer', borderBottom: `${value === menu ? '4px solid #fff' : 'none'}`, borderBottomStyle: `${value === menu ? 'double' : ''}` }}
                  onClick={() => !loading && handleClick(menu)}>{menu}</Box>
              )}
            </Stack> */}
          </Stack>
        </Grid>
        <Grid item lg={7.5} md={5.5} sm={12} xs={12}>
          <Stack direction={'row'} spacing={0} width='100%' justifyContent={'center'} alignItems={'center'}>
            <SearchComponent search={onSearch} clearSearch={() => { searchRef.current.value = ''; handleClick(value); setSearch(false) }} searchRef={searchRef} value={searchRef?.current?.value} />
            <ButtonComponent label='Advanced Search' variant='outlined' sx={{ justifyContent: 'flex-start', width: '15rem', border: 'none', '&:hover': { border: 'none' } }} onClick={() => { getAllSavedSearch({ page: 0, limit: 100 }); setSearchOpen(true) }} />
          </Stack>
        </Grid>
        <Grid item lg={2} md={2} sm={12} xs={12}>
          <Stack direction={'row'} justifyContent={{ lg: 'flex-end', md: 'flex-end', sm: 'flex-start', xs: 'flex-start' }} alignItems={'right'} spacing={1}>
            {/* <Typography onClick={() => setOpenReferal(true)} sx={{ fontWeight: 600, fontSize: '0.8rem', width: '8rem', cursor: 'pointer', display: 'flex' }} component={'span'}><AddIcon sx={{ fontSize: '0.9rem' }} /> Refer a Candidate</Typography> */}
            <Box sx={{ cursor: 'pointer', fontSize: '0.8rem', marginTop: '2px' }} onClick={() => dispatch(logout())}>Logout</Box>
          </Stack>
        </Grid>
      </Grid>
    )
  }

  const applyFilter = async ({ page, limit }, searchName = null) => {
    const filterArray = []
    let resumeValue = null
    if (!filterArray.length) {
      Object.entries(selectedOption).forEach(([key, value], index) => {
        if (key === 'skills') {
          if (Array.isArray(value)) {
            if (value.length) {
              if (resumeValue) {
                resumeValue += ` ${selectedOption.skillCond} ${value.join(` ${selectedOption.skillCond} `)}`
              } else {
                resumeValue = value.join(` ${selectedOption.skillCond} `)
              }
            }
          } else {
            if (resumeValue) {
              resumeValue += ` ${selectedOption.skillCond} ${value}`
            } else {
              resumeValue = value
            }
          }
          // let searchKey = value
          // // if (!Array.isArray(searchKey)) {
          // //   searchKey = searchKey.split(' ')
          // // }
          // // searchKey = searchKey.join(',').split(',')
          // searchKey.forEach(k => {
            // if (resumeValue) {
            //   resumeValue += `and ${k}`
            // } else {
            //   resumeValue = k
            // }
          // })
        }
        if (key === 'currentRole') {
          filterArray.push({
            condition: null && index === 0 ? '' : 'AND',
            criteria: {
              key,
              value,
              condition: 'is'
            }
          })
        } else if (['minC2CRate'].indexOf(key) > -1) {
          filterArray.push({
            condition: index === 0 ? '' : 'AND',
            criteria: {
              key,
              lowerbound: value[0],
              upperbound: value[1],
              condition: 'between'
            }
          })
        } else if (key === 'minW2Rate') {
          value.forEach((val, valIndex) => {
            // let condition = ''
            // if (valIndex === 0 && index !== 0) {
            //   condition = 'AND'
            // } else if (valIndex !== 0) {
            //   condition = 'OR'
            // }
            let innerCondition = 'is'
            if (valIndex === 0) {
              innerCondition = '>='
              // condition = condition ? 'AND' : condition
            } else {
              innerCondition = '<='
              // condition = condition ? 'AND' : condition
            }
            filterArray.push({
              condition: valIndex === 0 ? 'AND' : ['keyClients', 'workType', 'keyIndustries'].indexOf(key) > -1 ? 'OR' : 'AND',
              criteria: {
                key,
                value: val,
                condition: innerCondition
              }
            })
          })
        } else if (['keyIndustries', 'workType', 'keyClients'].indexOf(key) > -1) {
          filterArray.push({
            condition: index === 0 ? '' : 'AND',
            criteria: {
              key,
              subCondition: true,
              value: value.length === 1 ? value[0] : `(${value.join(' OR ')})`,
              condition: '='
            }
          })
        } 
      })
      if (resumeValue) {
        if (searchName) {
          resumeValue = `${resumeValue} and ${searchName}`
        }
        filterArray.unshift({
          criteria: {
            key: 'resumeContent',
            value: `(${resumeValue.toUpperCase()})`,
            condition: 'resumeContains'
          }
        })
      }
      if (searchName && filterArray.length) {
        filterArray.push({
          condition: 'AND',
          criteria: {
            key: 'skills',
            value: searchName,
            condition: 'is'
          }
        })
      }
    }
    if (searchName && !filterArray.length) {
      filterArray.push({
        criteria: {
          key: 'resumeContent',
          value: searchName.split(' ').join(',').toUpperCase(),
          condition: 'resumeContains'
        }
      }, {
        condition: 'AND',
        criteria: {
          key: 'skills',
          value: searchName,
          condition: 'is'
        }
      })
    }
    if (filterArray.length) {
      setLoading(true)
      const user = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')) : null
      const res = await Candidate.advanceSearch({ page, limit, filters: filterArray, mailId: user.mailId })
      setFilters(filterArray)
      if (typeof res === 'string') {
        dispatch(setMessage({ type: 'error', text: res }))
      } else {
        setCandidates(res)
      }
      setLoading(false)
    }
    setFilters(filterArray);
  }

  const renderNameTextField = () => {
    return (
      <Box sx={{ margin: '1rem 0', width: { lg: '30rem', md: '30rem' } }}>
        <TextInput
          sx={{ marginBottom: '1rem' }}
          value={searchName}
          variant='outlined'
          name='searchName'
          label='Search Name'
          onChange={(field, value) => setSearchName(value)}
          autoFocus={true}
          error={error.searchName}
        />
      </Box>
    )
  }

  const saveFilter = async () => {
    try {
      setLoading(true)
      const user = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')) : null
      const res = await Search.save({
        name: searchName,
        recruiterMailId: user.mailId,
        filters: {
          filters
        }
      })
      if (res) {
        setOpenNamePopup(false)
        dispatch(setMessage({ type: 'success', text: 'Search Saved!' }))
      } else {
        dispatch(setMessage({ type: 'error', text: res }))
      }
    } catch (error) {
      dispatch(setMessage({ type: 'error', text: 'Something went wrong!' }))
    } finally {
      setError({})
      setSearchName('')
      setLoading(false)
    }
  }

  const showTextBox = () => {
    return (
      <Box sx={{ margin: '1rem 0', width: { lg: '30rem', md: '30rem' } }}>
        <TextInput
          sx={{ marginBottom: '1rem' }}
          value={searchName}
          variant='outlined'
          name='searchName'
          label='Search Skill'
          onChange={(field, value) => setSearchName(value)}
          autoFocus={true}
          error={error.searchName}
        />
      </Box>
    )
  }

  const downloadFile = async (resName, name, mailId) => {
    // showLoader(true)
    const user = localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser')) : null
    const fileType = resName.substring(resName.lastIndexOf('.') + 1, resName.length)
    const res = await Candidate.downloadResume(resName, 'recruiter')
    if (!res.type) {
      dispatch(setMessage({ type: 'error', text: res }))
      return
    }
    await Candidate.updateResumeStatus({ recruiterMailId: user.mailId, recruiteeMailId: [mailId]})
    const link = document.createElement('a')
    const blobData = new Blob([res])
    // create a blobURI pointing to our Blob
    link.href = URL.createObjectURL(blobData)
    link.download = `${name}_resume.${fileType}`
    document.body.append(link)
    link.click()
    link.remove()
    // showLoader(false)
  }

  return (
    <Box>
      <Header />
      <Box sx={{ margin: '1rem' }}>
        {value === 'Saved' &&
          <>
            <DropDown sx={{ width: '15%', marginBottom: '0' }} label='Saved Search' data={savedSearch} value={selectedSearch} handleChange={handleDropDownClick} />
            <Box sx={{ color: 'primary.main', fontWeight: 600, marginTop: '0.3rem', cursor: 'pointer', fontSize: '1rem' }} onClick={() => setSearchOpen(true)} >Create your saved search</Box>
          </>
        }
        {value === 'All' &&
          <>
            {/* <DropDown
              sx={{ width: '15%', marginBottom: '1rem' }}
              label={`All Roles ${roles.newCountTotal ? ` - ${roles.newCountTotal} New` : ''}`}
              data={roles.roles}
              value={selectedRole}
              handleChange={filterRole}
              defaultText='All Roles'
              dataKey='role'
              dataValue='role'
              displayCount={true}
              total={roles.newCountTotal}
            /> */}
            <RadioButton
              sx={{ marginLeft: '14.3rem', marginBottom: '-13.3px', width: 'auto' }}
              name={name}
              options={[
                {
                  label: 'AND',
                  value: 'AND'
                },
                {
                  label: 'OR',
                  value: 'OR'
                }
              ]}
              value={selectedOption.skillCond || 'OR'}
              handleChange={(field, value) => setSelectedOption({ ...selectedOption, skillCond: value })}
              direction='row'
            />
            <Stack direction='row' sx={{ alignItems: 'center', justifyContent: 'flex-end' }}>
              <Grid container direction={'row'} justifyContent='flex-start' alignItems='center' columnSpacing={9}>
                {filterData.map(filter =>
                  <Grid key={filter.key} item sx={{ marginBottom: '1rem' }}>
                    <FilterButton
                      id={filter.key}
                      name={filter.name}
                      options={filter.options}
                      setSelectedOption={setSelectedOption}
                      selected={selectedOption}
                      type={filter.type}
                      fetchData={(value) => filterRole(value)}
                      showTextBox={() => setShowBox(true)}
                      skillsData={filterData[1].options}
                    />
                  </Grid>
                )}
              </Grid>
              <Stack direction='row' spacing={2}>
                {/* <ButtonComponent label='Save search' onClick={() => filters.length && setOpenNamePopup(true)} /> */}
                <ButtonComponent variant='outlined' sx={{ color: '#000' }} label='Clear'
                  onClick={() => { setSelectedOption({}); setFilters([]); getAllCandidates({ page: 0, limit: 10 }) }}
                />
              </Stack>
            </Stack>
          </>
        }
        {value === 'Referred' ?
          <DataTable
            title={search ? 'Search Result' : 'All candidates referred by you'}
            viewName={value}
            headers={referredHeaders}
            data={candidates.recruiteeReferals}
            totalRecords={candidates.count}
            // connect={connect}
            showCheckbox={false}
            hideConnect={true}
            loading={loading}
            onPageChange={page => setPageDetails({ ...pageDetails, page })}
            onRowPerPageChange={limit => setPageDetails({ page: 0, limit })}
            downloadResume={download}
          /> : <DataTable
            title={search ? 'Search Result' : getTitle(value)}
            viewName={value === 'Saved' ? '' : value}
            headers={value === 'Connected' ? connectedHeaders : value === 'All' ? headers : headers_saved}
            data={value === 'Connected' ? (candidates.connects || []) : value === 'All' ? (candidates.recruitees || []) : (candidates.savedRecruitees || [])}
            totalRecords={candidates.count}
            connect={connect}
            showCheckbox={true}
            hideConnect={value === 'Connected'}
            loading={loading}
            onPageChange={page => setPageDetails({ ...pageDetails, page })}
            onRowPerPageChange={limit => setPageDetails({ page: 0, limit })}
            downloadResume={download}
            downloadAllCanResume={(e, resName, name, mailId) => downloadFile(resName, name, mailId)}
            hideDownload={value === 'All' ? false : true}
          />}
      </Box>
      {open &&
        <DialogComponent
          title='Client Details'
          open={open}
          handleClose={closeDialog}
          content={renderForm()}
          onConnect={onConnect}
          handleCancel={closeDialog}
          loading={loading}
        />
      }
      {openNamePopup &&
        <DialogComponent
          title='Enter Search Name'
          open={openNamePopup}
          handleClose={() => setOpenNamePopup(false)}
          content={renderNameTextField()}
          onConnect={saveFilter}
          handleCancel={() => setOpenNamePopup(false)}
          submitName='Save'
        />
      }
      {searchOpen &&
        <FullScreenDialog open={searchOpen} handleClose={() => { setSearchOpen(false); setCandidates({}); setSearchFormData({ criteria: [] }); setSearchResult({}); handleClick('All') }}>
          <AdvancedSearch
            saveSearch={handleSearchSave}
            handleClose={() => { setSearchOpen(false); setCandidates({}); setSearchFormData({ criteria: [] }); handleClick('All') }}
            data={searchFormData}
            setFormValue={setValues}
            loading={loading}
            savedSearch={savedSearch.map(data => data.name)}
            handleDropDownClick={handleDropDownClick}
            triggerSearch={() => triggerSearch({ page: 0, limit: 10 })}
            searchResult={searchResult}
            tableProps={{
              headers: search_headers,
              hideConnect: false,
              onPageChange: page => setPageDetailsOfSearchResult({ ...pageDetails, page }),
              onRowPerPageChange: limit => setPageDetailsOfSearchResult({ page: 0, limit })
            }}
            handleResultClose={() => { setSearchResult({}) }}
            openNamePopup={() => { setOpenNamePopup(!openNamePopup); setError({ ...error, searchName: '' }); setSearchName('') }}
            namePopup={openNamePopup}
            searchName={searchName}
            setSearchName={(field, value) => setSearchName(value)}
            error={error.searchName}
            setSearchFormData={setSearchFormData}
            downloadResume={download}
            downloadAllCanResume={(e, resName, name, mailId) => downloadFile(resName, name, mailId)}
            connect={connect}
          />
          {showLoader && <Loader sx={{ zIndex: 9999 }} show={showLoader} />}
          {data && (data.substring(data.lastIndexOf('.') + 1, data.length) === 'docx' || data.substring(data.lastIndexOf('.') + 1, data.length) === 'doc') ?
            <Loader show={data ? true : false} view={<DocViewer style={{ width: '50%', height: '100vh', marginTop: '7rem' }} pluginRenderers={DocViewerRenderers} config={{
              header: {
                disableHeader: true,
                disableFileName: true,
                retainURLParams: false
              },
            }} documents={[{ uri: `https://toptalentcommunity.s3.amazonaws.com/resumes/${data}` }]}
            />} close={() => setData(null)} />
            :
            data && <Loader show={data ? true : false} view={<iframe title='i-1' src={`https://toptalentcommunity.s3.amazonaws.com/resumes/${data}#toolbar=0&view=fitH, 50`} />} close={() => setData(null)} />
          }
        </FullScreenDialog>
      }
      {!searchOpen && showLoader && <Loader show={showLoader} />}
      {!searchOpen && data && (data.substring(data.lastIndexOf('.') + 1, data.length) === 'docx' || data.substring(data.lastIndexOf('.') + 1, data.length) === 'doc') ?
        <Loader show={data ? true : false} view={<DocViewer style={{ width: '50%', height: '100vh' }} pluginRenderers={DocViewerRenderers} config={{
          header: {
            disableHeader: true,
            disableFileName: true,
            retainURLParams: false
          },
        }} documents={[{ uri: `https://toptalentcommunity.s3.amazonaws.com/resumes/${data}` }]}
        />} close={() => setData(null)} />
        :
        !searchOpen && data && <Loader show={data ? true : false} view={<iframe title='i-1' src={`https://toptalentcommunity.s3.amazonaws.com/resumes/${data}#toolbar=0&view=fitH, 50`} />} close={() => setData(null)} />
      }
      {openReferal &&
        <DialogComponent
          title='Refer a Candidate'
          open={openReferal}
          handleClose={() => { setOpenReferal(false); setReferalObj({ availableRoles: '', workAuth: '', name: '', email: '', resume: '' }) }}
          content={renderReferForm()}
          onConnect={refer}
          handleCancel={() => { setOpenReferal(false); setReferalObj({ availableRoles: '', workAuth: '', name: '', email: '', resume: '' }) }}
          loading={refLoading}
        />
      }
      {showBox &&
        <DialogComponent
          title='Enter the skill that you want to search'
          open={showBox}
          handleClose={() => { setShowBox(false); setSearchName('') }}
          content={showTextBox()}
          onConnect={() => { applyFilter({ page: 0, limit: 10 }, searchName); setShowBox(false); setSearchName('') }}
          handleCancel={() => { setShowBox(false); setSearchName('') }}
          submitName={'Search'}
        />
      }
    </Box>
  )
}
