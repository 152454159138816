import client from "..";

export default class Candidate {

  static search = async data => {
    const searchResult = await client.get(`search?pageNo=${data.page}&recordsPerPage=${data.limit}&searchTerm=${data.searchText}&recruiterMailId=${data.recruiterMailId}`)
    return searchResult
  }

  static all = async (data, recruiterMailId) => {
    const candidates = await client.get(`search/all?pageNo=${data.page}&recordsPerPage=${data.limit}&recruiterMailId=${recruiterMailId}`)
    return candidates
  }

  static uploadResume = async data => {
    const respone = await client.post('signup/resume', data)
    return respone
  }

  static downloadResume = async (data, path = 'recruiter') => {
    try {
      const response = await client.get(`${path}/resume?fileName=${data}`)
      return response
    } catch (error) {
      return 'Please try again later. If problem persist, please contact the administrator.' 
    }
  }

  static advanceSearch = async data => {
    const response = await client.post(`search/filter?pageNo=${data.page}&recordsPerPage=${data.limit}&recruiterMailId=${data.mailId}`, { filters: data.filters })
    return response
  }

  static connect = async data => {
    const response = await client.post('connect', data)
    return response
  }

  static getConnected = async data => {
    const respone = await client.get(`connect/recruiter?pageNo=${data.page}&recordsPerPage=${data.limit}&emailId=${data.mailId}`)
    return respone
  }

  static update = async data => {
    const respone = await client.put('recruitee', data)
    return respone
  }

  static getStatus = async data => {
    const respone = await client.post(`connect/status?recruiterEmailId=${data.mailId}`, data.emails)
    return respone
  }

  static checkEmail = async email => {
    const respone = await client.get(`referal/candidate/presence?recruiteeMailId=${email}`)
    return respone
  }

  static refer = async data => {
    const respone = await client.post('referal/candidate', data)
    return respone
  }

  static getReferredForHR = async (data, mailId) => {
    const respone = await client.get(`referal/candidatebyrecruiter?pageNo=${data.page}&recordsPerPage=${data.limit}&recruiterMailId=${mailId}`)
    return respone
  }

  static getReferred = async data => {
    const respone = await client.get(`referal/candidate?pageNo=${data.page}&recordsPerPage=${data.limit}`)
    return respone
  }

  static updateEarnings = async data => {
    const respone = await client.post(`referal/updateEarningsForcandidateReferal?recruiteeMailId=${data.email}&earnings=${data.amount}`)
    return respone
  }

  static updateResumeStatus = async data => {
    const respone = await client.put(`recruiter/resumeStatus`, data)
  }

  static getSideMenu = async data => {
    const respone = await client.get(`candidate/leftmenu/${data}`)
    return respone
  }

  static sendMail = async data => {
    const response = await client.post('candidate/sendmail', data)
    return response
  }

  static add = async data => {
    const response = await client.post('candidate', data)
    return response
  }

  static uploadMenu = async data => {
    const respone = await client.post('candidate/leftmenu', data)
    return respone
  }

  static getSelectedSideMenu = async data => {
    const respone = await client.get(`candidate/tickmark/${data}`)
    return respone
  }
}
