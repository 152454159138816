import React from 'react'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'

export default function SearchComponent({ search, searchRef, clearSearch, value }) {
  return (
    <Paper
      component="form"
      sx={{ display: 'flex', alignItems: 'center', width: 500 }}
      type='submit'
      onSubmit={search}
    >

      <IconButton sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>

      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Enter Keywords"
        inputRef={searchRef}
        inputProps={{ 'aria-label': 'Enter Keywords' }}
        defaultValue={value}
      />

      <IconButton sx={{ p: '10px' }} aria-label="search" onClick={clearSearch}>
        <ClearIcon />
      </IconButton>

    </Paper>
  )
}
