import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import TextInput from '../../components/TextField'
import ButtonComponent from '../../components/Button'
import { Add, MinimizeOutlined } from '@mui/icons-material'
import DropDown from '../../components/DropDown'
import DataTable from '../../components/Datatable'
import DialogComponent from '../../components/Dialog'

export default function AdvancedSearch({
  handleClose, data, setFormValue, saveSearch, triggerSearch, loading, searchResult = {}, tableProps,
  handleResultClose, openNamePopup, namePopup, searchName, setSearchName, error, setSearchFormData, downloadResume, connect
}) {
  const fields = [{
    name: 'First Name',
    value: 'firstName'
  }, {
    name: 'Last Name',
    value: 'lastName'
  }, {
    name: 'City',
    value: 'currentCity'
  }, {
    name: 'State',
    value: 'currentAddress'
  }, {
    name: 'Minimum W2 Rate',
    value: 'minW2Rate',
    type: 'number'
  }, {
    name: 'Minimum C2C Rate',
    value: 'minC2CRate',
    type: 'number'
  }, {
    name: 'Industry',
    value: 'keyIndustries'
  }, {
    name: 'Skills',
    value: 'skills'
  }, {
    name: 'Preferred Clients',
    value: 'keyClients'
  }, {
    name: 'Work Type',
    value: 'workType'
  }, {
    name: 'Roles',
    value: 'currentRole'
  }]
  const stringLogics = [{
    name: 'is',
    value: 'is'
  }, {
    name: `isn't`,
    value: 'isNot'
  }, {
    name: 'contains',
    value: 'contains'
  }, {
    name: `doesn't contain`,
    value: 'notContains'
  }, {
    name: 'starts with',
    value: 'startsWith'
  }, {
    name: 'is empty',
    value: 'isEmpty'
  }, {
    name: 'is not empty',
    value: 'isNotEmpty'
  }]
  const numberLogics = ['=', '!=', '<', '<=', '>', '>=', 'isEmpty']

  const addCriteria = () => {
    const { criteria } = { ...data }
    criteria.push({ logicOp: 'AND' })
    setSearchFormData({
      ...data,
      criteria
    })
  }

  const removeCriteria = index => {
    const { criteria } = { ...data }
    criteria.splice(index, 1)
    setSearchFormData({
      ...data,
      criteria
    })
  }

  const setValues = (index, key, val) => {
    const { criteria } = { ...data }
    if (key === 'key') {
      fields.some(data => {
        if (data.value === val && data.type === 'number') {
          criteria[index] = {
            ...criteria[index],
            [key]: val,
            logic: numberLogics
          }
          return true
        } else if (data.value === val && !data.type) {
          criteria[index] = {
            ...criteria[index],
            [key]: val,
            logic: stringLogics
          }
          return true
        }
        return false
      })
    } else if (key === 'logic') {
      criteria[index] = {
        ...criteria[index],
        logicOp: criteria[index].logicOp === 'AND' ? 'OR' : 'AND'
      }
    } else {
      criteria[index] = {
        ...criteria[index],
        [key]: val
      }
    }
    setSearchFormData({
      ...data,
      criteria
    })
  }

  const renderNameTextField = () => {
    return (
      <Box sx={{ margin: '1rem 0', width: { lg: '30rem', md: '30rem' } }}>
        <TextInput
          sx={{ marginBottom: '1rem' }}
          value={searchName}
          variant='outlined'
          name='searchName'
          label='Search Name'
          onChange={setSearchName}
          autoFocus={true}
          error={error}
        />
      </Box>
    )
  }

  return (
    <>
      {/* <CheckboxComponent label='All Fields' checked={data.allFields} onChange={value => setFormValue('allFields', value)} /> */}
      {/* <Stack sx={{ width: '100%' }} justifyContent={'start'} alignItems={{ lg: 'end' }}>
            <DropDown sx={{ width: '20%', marginBottom: '0' }} label='Saved Search' data={savedSearch} handleChange={handleDropDownClick} />
          </Stack> */}
      {!searchResult.recruitees ? <Box>
        <Stack direction={'row'} spacing={6} sx={{ width: '100%' }}>
          <Typography sx={{ marginTop: '3rem' }} component={'label'}>All Keywords with Criteria</Typography>
          <TextInput sx={{ width: '25rem' }} placeholder='Javascript, Management, Finance' name='keywords' multiline={true} rows={4} variant='outlined' value={data.keywords || ''} onChange={setFormValue} />
        </Stack>
        <Box sx={{ margin: '1rem 0', textAlign: 'center', color: 'colors.gray', fontWeight: 600 }}>AND</Box>
        <Box sx={{ margin: '4rem 0' }}>
          {!data.criteria.length ? <ButtonComponent sx={{ color: 'primary.main', width: '10rem' }} label='Add Criteria' variant='outlined' onClick={addCriteria} />
            :
            <>
              <Box sx={{ marginBottom: '1rem', fontSize: '1.2rem', color: 'colors.gray' }}>Specify Criteria</Box>
              {data.criteria.map((criteria, index) =>
                <><Stack key={index} direction={{ lg: 'row', md: 'row', sm: 'row', xs: 'column' }} spacing={2} justifyContent={'flex-start'} alignItems={'center'} marginBottom={2}>
                  <Stack
                    sx={{
                      p: '0.6rem 1rem',
                      borderRadius: '50%',
                      border: '1px solid #000',
                      color: 'colors.black'
                    }} justifyContent={'center'} alignItems={'center'}>
                    <Box>{index + 1}</Box>
                  </Stack>
                  <DropDown label='Fields' data={fields} sx={{ minWidth: '15rem' }} handleChange={selected => setValues(index, 'key', selected)} value={criteria.key || ''} />
                  <DropDown label='Conditions' data={criteria.logic} sx={{ minWidth: '15rem' }} handleChange={selected => setValues(index, 'condition', selected)} value={criteria.condition || ''} />
                  <TextInput label='Value' sx={{ minWidth: '15rem', marginBottom: '-1rem !important' }} name='value' variant='filled' value={criteria.value || ''} onChange={(field, value) => setValues(index, 'value', value)} />
                  <Stack direction={'row'}>
                    <Box sx={{ width: '1.5rem', marginTop: '0.5rem !important' }}>
                      <MinimizeOutlined sx={{ cursor: 'pointer', color: 'colors.error.red' }} onClick={() => removeCriteria(index)} />
                    </Box>
                    <Box sx={{ width: '1.5rem' }}>
                      {data.criteria.length - 1 === index && data.criteria.length !== 5 &&
                        <Add sx={{ cursor: 'pointer', color: 'colors.green', paddingLeft: '1rem', margin: '1rem 0' }} onClick={addCriteria} />
                      }
                    </Box>
                  </Stack>
                </Stack>{index !== data.criteria.length - 1 && <Box sx={{ color: 'primary.main', fontSize: '1.2rem', cursor: 'pointer', margin: '1rem 0' }} onClick={() => setValues(index, 'logic')}>{criteria.logicOp}</Box>}</>
              )}
            </>
          }
        </Box>
        <Stack direction={{ lg: 'row', md: 'row', sm: 'row', xs: 'column' }} spacing={6} sx={{ width: { lg: '30rem', md: '30rem', sm: '30rem' } }}>
          <ButtonComponent
            label='Search'
            loading={loading}
            onClick={triggerSearch}
          />
          <ButtonComponent sx={{ color: 'primary.main' }} label='Cancel' variant='outlined' onClick={handleClose} />
        </Stack>
      </Box> :
      <>
        {/* <Stack sx={{ width: '100%' }} direction={'row'} alignItems={'flex-end'} justifyContent={'flex-end'}>
          <ButtonComponent
            sx={{ width: '10rem', marginRight: '1rem' }}
            label='Save Search'
            loading={loading}
            onClick={openNamePopup}
          />
          <ButtonComponent sx={{ color: 'primary.main', width: '10rem' }} label='Cancel' variant='outlined' onClick={handleResultClose} />
        </Stack> */}
        <DataTable
          title={''}
          headers={tableProps.headers}
          data={searchResult.recruitees}
          totalRecords={searchResult.count}
          showCheckbox={false}
          hideConnect={tableProps.hideConnect}
          onPageChange={tableProps.onPageChange}
          onRowPerPageChange={tableProps.onRowPerPageChange}
          downloadResume={downloadResume}
          connect={connect}
        />
      </>}
      {namePopup &&
        <DialogComponent
          title='Enter Search Name'
          open={namePopup}
          handleClose={openNamePopup}
          content={renderNameTextField()}
          onConnect={saveSearch}
          handleCancel={openNamePopup}
          submitName='Save'
        />
      }
    </>
  )
}
