import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView'
import { TreeItem } from '@mui/x-tree-view/TreeItem'
import CheckboxComponent from './Checkbox'

export default function TreeViewComponent({ data, onSelect, setExpanded, expanded = [], selected = [],
  selectedMainMenu = [], selectedFile = '' }) {

    return (
    <SimpleTreeView
      aria-label='company tree'
      defaultcollapseicon={<ExpandMoreIcon />}
      defaultexpandicon={<ChevronRightIcon />}
      multiSelect={true}
      onExpandedItemsChange={(_, itemIds) => setExpanded(itemIds)}
      expandedItems={expanded}
      sx={{ flexGrow: 1, maxWidth: 400, overflowY: 'auto', color: 'black' }}
    >
      {
        Object.entries(data).map(([key, values]) => {
          const community = [selectedFile.replace('File-', 'Community '), selectedFile.replace('File-', 'Category ')]
          const menu = selectedMainMenu
          .filter(s =>  community.indexOf(s.category) > -1)
          .flatMap(s => s.menu)

          return (
            <TreeItem key={key} itemId={key} label={key}
            sx={{
              '& .MuiTreeItem-content':
              { color: `${menu.indexOf(key) > -1 ? '#318CE7' : 'black' }`}
            }}>
              {key &&
                Object.entries(values).map(([item, itemValues]) => {
                  return <TreeItem
                    key={Math.random()}
                    chec
                    itemId={`${Math.random()}`}
                    label={item}
                    onClick={() => onSelect(`${key}-${item}`, itemValues)}
                    sx={{
                      '& .MuiTreeItem-label': { fontSize: '0.8rem' },
                      '& .MuiTreeItem-content':
                      { color: 'black',
                        marginBottom: '0.1rem',
                        background: `${selected.filter(s => s.name === `${key}-${item}`).length > 0 ? '#ececec' : '' }`
                      }
                    }}
                  >
                  </TreeItem>
                })
              }
            </TreeItem>
          )
        })
      }
    </SimpleTreeView>
  )
}
