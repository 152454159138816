import React from 'react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

export default function Loader({ sx, show, view = null, close = () => {} }) {
  return (
    <Backdrop
      sx={{ color: 'colors.white', zIndex: 1000, ...sx }}
      open={show}
      onClick={close}
    >
      {view ? view : <CircularProgress color="inherit" />}
    </Backdrop>
  )
}