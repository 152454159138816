import client from "..";


export default class Admin {

  static getRequests = async data => {
    const response = await client.get(`admin/connect/all?pageNo=${data.page}&recordsPerPage=${data.limit}`)
    return response
  }

  static approveRejectRequest = async (type, field, value) => {
    let url = `admin/connect/${type}?${field}=${value}`
    if (field === 'mailId') {
      url = `admin/recruiter/${type}?${field}=${value}`
    }
    const response = await client.put(url)
    return response
  }

  static getUserRequests = async data => {
    const response = await client.get(`admin/recruiter/pendings?pageNo=${data.page}&recordsPerPage=${data.limit}`)
    return response
  }

  static getCandidateRequests = async data => {
    const response = await client.get(`admin/recruitee/pendings?pageNo=${data.page}&recordsPerPage=${data.limit}`)
    return response
  }

  static approveRejectUserRequest = async (type, mailId) => {
    const response = await client.put(`admin/user/${type}?mailId=${mailId}`)
    return response
  }

  static getAllRecruiterList = async data => {
    const response = await client.get(`admin/recruiter/all?pageNo=${data.page}&recordsPerPage=${data.limit}`)
    return response
  }

  static getAllRecruiteeList = async data => {
    const response = await client.get(`admin/recruitee/all?pageNo=${data.page}&recordsPerPage=${data.limit}`)
    if (response && response.recruitees) {
      response.recruitees = response.recruitees.filter(data => data.user.status !== 'PENDING')
    }
    return response
  }
  
  static getRates = async () => {
    const response = await client.get('admin/rate')
    return response
  }

  static updateRate = async (data) => {
    const response = await client.put('admin/rate', data)
    return response
  }

  static assignVendors = async (data, canMailId) => {
    const url = `admin/recruitee/approve?mailId=${canMailId}`
    const response = await client.put(url, data)
    return response
  }

  static getMailCount = async (date) => {
    const response = await client.post(`candidate/mailcount?date=${date}`)
    return response
  }
}
