import * as React from 'react'
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import RadioButton from './RadioButton';
import TextInput from './TextField';

export default function FilterButton({
  id, name, options, selected, setSelectedOption, type = 'button', fetchData, showTextBox, skillsData
}) {
  const [open, setOpen] = React.useState(false)
  const [rateName, setRateName] = React.useState('W2 Rate')
  const anchorRef = React.useRef(null)

  const handleMenuItemClick = (event, id, value, type, update = true) => {
    if (id === 'skills' && value === 'Others') {
      showTextBox();
    } else if (!type) {
      const selectedData = selected[id] || []
      const newValues = [...selectedData]
      if (!newValues.length) {
        newValues.push(value)
      } else {
        const isExist = newValues.indexOf(value)
        if (isExist > -1) {
          newValues.splice(isExist, 1);
        } else {
          newValues.push(value)
        }
      }
      setSelectedOption({
        ...selected,
        [id]: [...new Set(newValues)],
        skillCond: selected.skillCond || 'OR',
        update
      })
      setOpen(false)
    } else if (type === 'slide') {
      if (selected.minW2Rate) {
        delete selected.minW2Rate
      } else if (selected.minC2CRate) {
        delete selected.minC2CRate
      }
      selected[rateName === 'W2 Rate' ? 'minW2Rate' : 'minC2CRate'] = value
      setSelectedOption({
        ...selected,
        skillCond: selected.skillCond || 'OR',
        update
      })
    } else {
      fetchData(value)
      if (id === 'currentRole') {
        setSelectedOption({
          ...selected,
          [id]: value,
          skills: [],
          update
        })
      } else {
        setSelectedOption({
          ...selected,
          [id]: value,
          skillCond: selected.skillCond || 'OR',
          update
        })
      }
    }
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const getDropDownName = (name, value) => {
    if (Array.isArray(value)) {
      if (value.length === 1) {
        return value[0]
      } else {
        return `${name} (${selected[id] ? selected[id].length : 0})`
      }
    } else if (name === 'Role' && value) {
      return value
    } else {
      return name
    }
  }

  const setSlideName = (field, value) => {
    if (value === 'minW2Rate') {
      setRateName('W2 Rate')
    } else {
      setRateName('C2C Rate')
    }
  }

  const applySearch = (e) => {
    e.stopPropagation();
    if (e.key === 'Enter') {
      const selectedData = selected.skills || []
      skillsData.unshift(e.target.value)
      setSelectedOption({
        ...selected,
        skills: [...selectedData, e.target.value]
      })
      e.target.value = '';
    }
  }

  return (
    <React.Fragment>
      {type === 'button' &&
        <>
          <ButtonGroup variant="contained" ref={anchorRef} sx={{ borderRadius: '20rem' }}>
            <Button
              size="small"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
              sx={{
                padding: '1rem',
                minWidth: '9.5rem !important',
                borderRadius: '20rem',
                background: selected[id] && selected[id].length ? '#007500' : '#fff',
                color: selected[id] && selected[id].length ? '#fff' : '#000',
                '&:hover': {
                  background: selected[id] && selected[id].length ? '#007500' : '#fff'
                }
              }}
            >
              {getDropDownName(name, selected[id])}
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          {!!options.length &&
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              sx={{ zIndex: 10 }}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'center top' : 'center bottom'
                  }}
                >
                  <Paper>
                  {name === 'Skills' &&
                    <TextInput
                      variant='outlined'
                      name='skill'
                      label='Add a skill'
                      onKeyDown={applySearch}
                      onClick={e => e.stopPropagation()}
                    />
                  }
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu" autoFocusItem sx={{ maxHeight: '20rem', overflowY: 'scroll' }}>
                        {options.map((option, index) => (
                          <MenuItem
                            key={option.role || option}
                            selected={selected[id] ? selected[id].indexOf(option.role || option) > -1 : false}
                            sx={{
                              '&.Mui-selected': {
                                background: '#007500',
                                color: '#fff',
                                '&:hover': {
                                  background: '#007500',
                                  color: '#fff'
                                }
                              },
                              '&:hover': {
                                background: '#007500',
                                color: '#fff'
                              }
                            }}
                            onClick={(event) => handleMenuItemClick(event, id, option.role || option, option.role)}
                          >
                            {option.role || option} {option.newCount ? <Typography sx={{ color: 'colors.error.red', paddingLeft: '0.3rem', fontSize: '0.7rem' }} component={'span'}>{` ${option.newCount} New`}</Typography> : ''}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          }
        </>
      }
      {type === 'slider' &&
        <>
              {/* {
                label: 'C2C Rate',
                value: 'minC2CRate'
              } */}
          <RadioButton
            sx={{ marginBottom: '-17px' }}
            name='slider'
            options={[
              {
                label: 'W2 Rate',
                value: 'minW2Rate'
              }
            ]}
            value={rateName === 'W2 Rate' ? 'minW2Rate' : 'minC2CRate'}
            handleChange={setSlideName}
            direction='row'
          />
          <Box sx={{ width: 150 }}>
            <Slider
              sx={{ color: '#007500' }}
              value={selected[rateName === 'W2 Rate' ? 'minW2Rate' : 'minC2CRate'] || [40, 150]}
              onChange={(event, value) => handleMenuItemClick(event, id, value, 'slide', false)}
              valueLabelDisplay="auto"
              getAriaValueText={value => value}
              min={0}
              max={150}
              onChangeCommitted={(event, value) => handleMenuItemClick(event, id, value, 'slide', true)}
            />
          </Box>
        </>
      }
    </React.Fragment>
  )
}
