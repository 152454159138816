import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import ButtonComponent from './Button'
import DialogTitle from '@mui/material/DialogTitle'

export default function DialogComponent({ open, title, handleClose = () => {}, content, onConnect, submitName = 'Send', cancelName = 'Cancel', handleCancel = () => {}, loading, showAction = true, disable = false }) {

  return (
      <Dialog open={open ? true : false} onClose={handleClose}>
        <DialogTitle><b>{title}</b></DialogTitle>
        <DialogContent>
          {content}
        </DialogContent>
        {showAction && <DialogActions sx={{ margin: '0 1rem 1rem 1rem' }}>
          <ButtonComponent sx={{ color: 'colors.black' }} label={cancelName} onClick={handleCancel} variant='outlined' />
          <ButtonComponent label={submitName} onClick={onConnect} type='submit' loading={loading} disabled={disable} />
        </DialogActions>}
      </Dialog>
  )
}
