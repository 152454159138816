import React from 'react'
import { Box } from '@mui/material'

export default function Policy() {
    return(
        <Box>
            <h1>Privacy Policy</h1>
<p>Effective Date: July 29, 2022</p>
<p>This &ldquo;Privacy Policy&rdquo; describes how Top Talent Technologies Inc. and our subsidiaries and affiliates (&ldquo;Top Talent Technologies&rdquo;, &ldquo;we&rdquo;, &ldquo;us&rdquo; or &ldquo;our&rdquo;) collect, use and share your personal information in connection with the thetalentcommunity.com website (the &ldquo;Site&rdquo;) and any other services or digital platforms that link to this Privacy Policy (together with the Site, the &ldquo;Service&rdquo;).</p>
<p>Personal Information We Collect</p>
<p>Information You Give Us.&nbsp;Personal information you may provide to us when you use the Service or otherwise includes:</p>
<p>&middot; &nbsp;&nbsp;Contact data, such as your name, email address, mailing address and phone number.</p>
<p>&middot; &nbsp;&nbsp;Profile and account data, such as the email address and password you use to create an account on our Site, demographic information, preferences and other information you include in your online profile.</p>
<p>&middot; &nbsp;&nbsp;Work experience and preferences, such as your area of expertise, employment location preferences, job search status, and information included on a resume we receive.</p>
<p>&middot; &nbsp;&nbsp;Work eligibility information, such as your visa status.</p>
<p>&middot; &nbsp;&nbsp;Application and hiring history, including records of jobs you have applied for or candidates you have reviewed.</p>
<p>&middot; &nbsp;&nbsp;Communications&nbsp;that we exchange when you contact us with questions, feedback, or otherwise.</p>
<p>&middot; &nbsp;&nbsp;Financial data,&nbsp;such as your credit card number and/or other related information that may be required from you to complete a transaction.&nbsp;</p>
<p>&middot; &nbsp;&nbsp;Marketing data, such as your preferences for receiving communications about our activities, events, and publications, and details about how you engage with our communications.</p>
<p>&middot; &nbsp;&nbsp;Other information&nbsp;that we may collect which is not specifically listed here, which we will use as described in this Privacy Policy or as otherwise disclosed at the time of collection.</p>
<p>Information We Get from Others.&nbsp;We may get information about you from other sources, and we may add this to information we collect about you through the Service. Such sources include:</p>
<p>&middot; &nbsp;&nbsp;Third party platforms&nbsp;when you choose to log into the Service using Google or another third party platform, we will collect the information shared by the platform including your username, profile data and other information. &nbsp;We will treat any information that you or the platform may provide to us in accordance with this Privacy Policy. &nbsp;When you visit or interact with our pages on one of those platforms, the platform provider&rsquo;s privacy policy will apply to your interactions and their collection, use and processing of your personal information. &nbsp;&nbsp;</p>
<p>&middot; &nbsp;&nbsp;Business partners, such as joint marketing partners or event co-sponsors.&nbsp;</p>
<p>&middot; &nbsp;&nbsp;Public sources, such as public databases, social media channels, directories and other third party sources.</p>
<p>Information Automatically Collected.&nbsp;We, our service providers and our third party partners may automatically log information about you, your computer or mobile device, and your activity over time on the Service and other online services, including:</p>
<p>&middot; &nbsp;&nbsp;Device data,&nbsp;such as your computer or mobile device operating system type and version number, manufacturer and model, browser type, screen resolution, IP address, unique identifiers, and general location information such as city or town.</p>
<p>&middot; &nbsp;&nbsp;Usage data,&nbsp;such as the website you visited before browsing to the Site, pages or screens you viewed, how long you spent on a page or screen, navigation paths between pages or screens, information about your activity on a page or screen or with our email communications, access times, and duration of access.</p>
<p>Information Collected Through Cookies and Similar Technologies.&nbsp;We and our third-party partners may collect information on our Site using &ldquo;cookies&rdquo; and similar types of technology, including:</p>
<p>&middot; &nbsp;&nbsp;Cookies, which are text files that websites store on a visitor&lsquo;s device to uniquely identify the visitor&rsquo;s browser or to store information or settings in the browser for the purpose of tracking user activity and patterns, helping you navigate between pages efficiently, remembering your preferences and generally improving your browsing experience. &nbsp;We may set cookies when you use our site to track user trends and patterns, help you navigate between pages efficiently, remember your preferences, and generally improve your browsing experience. &nbsp;Cookies used on our site may also be set by analytics service providers and advertising partners.&nbsp;</p>
<p>&middot; &nbsp;&nbsp;Web beacons, also known as pixel tags or clear GIFs, which are typically used demonstrate that a webpage or email was accessed or opened, or that certain content was viewed or clicked, typically to compile statistics about usage of websites and the success of marketing campaigns. We may use web beacons to track your use of our websites or to collect information about your interactions with our email messages, such as the links you click on and whether you open or forward a message, the date and time of these interactions and the device you use to read emails.</p>
<p>Sensitive Information.&nbsp;We ask that you not send or disclose to us any sensitive personal information (such as Social Security numbers, race or ethnic origin, health information, criminal background, information about your religion or other beliefs, union membership or political opinions) on or through the Site or otherwise. If you send or disclose any sensitive personal information to us (such as when you submit a resume through the Site), you must consent to our use of such sensitive personal information in accordance with this Privacy Policy. If you do not consent to our use of such sensitive personal information, you must not provide it.</p>
<p>How We Use Your Personal Information</p>
<p>We use your personal information for the following purposes and as otherwise described in this Privacy Policy or at the time of collection:</p>
<p>Service delivery. &nbsp;We use your personal information to:</p>
<p>&middot; &nbsp; operate, maintain, and improve our sites, products, and services</p>
<p>&middot; &nbsp; establish and maintain your user profile on the Service</p>
<p>&middot; &nbsp; facilitate your to login to the Service via third-party platforms, such as Google</p>
<p>&middot; &nbsp; provide and deliver products and services customers request</p>
<p>&middot; &nbsp; evaluate your submissions and enhance the information we have collected about you</p>
<p>&middot; &nbsp; send you surveys, promotions and marketing communication</p>
<p>&middot; &nbsp; communicate with you about the Service, including by sending you announcements, updates, security alerts, and support and administrative messages</p>
<p>&middot; &nbsp; understand your needs and interests, and personalize your experience with the Service and our communications</p>
<p>&middot; &nbsp; provide support and maintenance for the Service</p>
<p>&middot; &nbsp; to respond to your requests, questions and feedback</p>
<p>For research and development. &nbsp;We may use your personal information for research and development purposes, including to analyze and improve the Service and our business. &nbsp;As part of these activities, we may create aggregated, de-identified or other anonymous data from personal information we collect. &nbsp;We convert personal information into anonymous data by removing information that makes the data personally identifiable to you. &nbsp;We may use this anonymous data and share it with third parties for our lawful business purposes, including to analyze and improve the Service and promote our business.</p>
<p>Marketing and advertising. &nbsp;We and our third party advertising partners may collect and use your personal information for marketing and advertising purposes:</p>
<p>&middot; &nbsp; Direct marketing. We may send you thetalentcommunity.com-related or other direct marketing communications as permitted by law. &nbsp;You may opt-out of our marketing communications as described in the Opt out of marketing section below.</p>
<p>&middot; &nbsp; Interest-based advertising. &nbsp;We may contract with third-party advertising companies and social media companies to display ads on our Service and other sites. These companies may use cookies and similar technologies to try to tailor the ads you see online to your interests based on your activity over time across our Service and other sites, or your interaction with our emails. These ads are known as &quot;interest-based advertisements.&quot; You can learn more about your choices for limiting interest-based advertising, in the Advertising choices section below.</p>
<p>Compliance and protection. &nbsp;We may use your personal information to:</p>
<p>&middot; &nbsp; operate our business;</p>
<p>&middot; &nbsp; comply with applicable laws, lawful requests, and legal process, such as to respond to subpoenas or requests from government authorities;</p>
<p>&middot; &nbsp; protect our, your or others&rsquo; rights, privacy, safety or property (including by making and defending legal claims);</p>
<p>&middot; &nbsp; investigate violations of and enforce our Terms of Service; and</p>
<p>&middot; &nbsp; prevent, identify, investigate and deter fraudulent, harmful, unauthorized, unethical or illegal activity, including cyberattacks and identity theft.</p>
<p>With your consent. &nbsp;In some cases we may specifically ask for your consent to collect, use or share your personal information, such as when required by law or our agreements with third parties.&nbsp;</p>
<p>How We Share Your Personal Information</p>
<p>We may share your personal information with the following parties and as otherwise described in this Privacy Policy or at the time of collection:</p>
<p>Affiliates. &nbsp;Our corporate parent, subsidiaries and affiliates, for purposes consistent with this Privacy Policy.</p>
<p>Service providers. &nbsp;Third party companies and individuals that provide services on our behalf or help us operate the Service (such as customer support, hosting, analytics, email delivery, marketing, and database management services).</p>
<p>Potential employers and other users of the Service. &nbsp;Other parties that use our Service, such as potential employers or candidates.</p>
<p>Advertising Partners. Third party advertising companies that collect information about your activity on our sites and other online services to help us advertise our services and/or use hashed customer lists that we share with them to deliver ads to them and similar users on their platforms.&nbsp;</p>
<p>Third-party platforms. If you have enabled features or functionality that connect the Service to a third-party platform or social media network (such as by logging in to the Service using your account with the third-party, providing your API key or similar access token for the Service to a third-party, or otherwise linking your account with the Service to a third-party&rsquo;s services), we may disclose the personal information that you authorized us to share. We do not control the third party&rsquo;s use of your personal information.</p>
<p>Professional advisors. Professional advisors, such as lawyers, bankers, auditors and insurers, where necessary in the course of the professional services that they render to us.</p>
<p>Authorities and others. Law enforcement, government authorities, and private parties, as we believe in good faith to be necessary or appropriate for the compliance, fraud prevention and safety purposes described above.&nbsp;</p>
<p>Business transferees. Relevant participants in business transactions (or potential transactions) involving a corporate divestiture, merger, consolidation, acquisition, reorganization, sale or other disposition of all or any portion of the business or assets of, or equity interests in, Top Talent Technologies or our affiliates (including, in connection with a bankruptcy or similar proceedings).&nbsp;</p>
<p>Your Choices</p>
<p>In this section, we describe the rights and choices available to all users.</p>
<p>Account information. You may change any of your personal information in your account by editing your profile, changing your account settings, or you can send requests about personal information to our contact information below. You can request to change contact choices, opt-out of our sharing with others, and update or delete your personal information.</p>
<p>Opt out of marketing communications.&nbsp;&nbsp;You may opt out of marketing-related emails by following the opt-out or unsubscribe instructions at the bottom of the email, or by contacting us at support@thetalentcommunity.com. &nbsp;You may continue to receive service-related and other non-marketing emails.&nbsp;</p>
<p>Cookies &amp; Browser Web Storage.&nbsp; Most browsers let you remove or reject cookies. &nbsp;To do this, follow the instructions in your browser settings. &nbsp;Many browsers accept cookies by default until you change your settings. &nbsp;Please note that if you set your browser to disable cookies, our websites may not work properly. &nbsp;Similarly, your browser settings may allow you to clear your browser web storage.&nbsp;</p>
<p>Advertising choices.&nbsp;You can limit use of your information for interest-based advertising by blocking third party cookies in your browser or mobile device settings, using privacy browsers or ad-blocking browser plug-ins, or by changing platform settings that allow you to opt-out of the use of your information for interest-based advertising. For example, you can visit the following opt-out tools provided by some of our partners:</p>
<p>&middot; &nbsp; Google:<a href="https://adssettings.google.com/">&nbsp;https://adssettings.google.com/</a></p>
<p>&middot; &nbsp; Facebook:<a href="https://www.facebook.com/about/ads">&nbsp;https://www.facebook.com/about/ads</a></p>
<p>&middot; &nbsp; LinkedIn:<a href="https://www.linkedin.com/psettings/advertising">&nbsp;https://www.linkedin.com/psettings/advertising</a></p>
<p>&middot; &nbsp; Twitter:<a href="https://help.twitter.com/en/safety-and-security/privacy-controls-for-tailored-ads">&nbsp;https://help.twitter.com/en/safety-and-security/privacy-controls-for-tailored-ads</a></p>
<p>Some of our business partners that collect information about users&rsquo; activities on or through the Site may be members of organizations or programs that provide choices to individuals regarding the use of their browsing behavior or mobile application usage for purposes of targeted advertising. You can use the following opt-out options to limit use of your information for interest-based advertising by participating companies:</p>
<p>&middot; &nbsp; Digital Advertising Alliance:&nbsp;<a href="http://optout.aboutads.info/">&nbsp;http://optout.aboutads.info</a></p>
<p>&middot; &nbsp; Network Advertising Initiative:<a href="http://www.networkadvertising.org/managing/opt_out.asp">&nbsp;http://www.networkadvertising.org/managing/opt_out.asp</a></p>
<p>Please note that we also may work with companies that offer their own opt-out mechanisms and may not participate in the opt-out mechanisms that we linked above. Note also that because these opt-out mechanisms are specific to the device or browser on which they are exercised, you will need to opt-out on every browser and device that you use.</p>
<p>Do Not Track.&nbsp; Some Internet browsers may be configured to send &ldquo;Do Not Track&rdquo; signals to the online services that you visit. &nbsp;We currently do not respond to &ldquo;Do Not Track&rdquo; or similar signals. &nbsp;To find out more about &ldquo;Do Not Track,&rdquo; please visit http://www.allaboutdnt.com.</p>
<p>Declining to provide information. We need to collect personal information to provide certain services. &nbsp;If you do not provide the information requested, we may not be able to provide those services.</p>
<p>Third party platforms. If you choose to connect to the Service via a third-party platform or social media network, you may have the ability to limit the information that we may obtain from the third party at the time you login to the Service using the third-party&rsquo;s authentication service or otherwise connect your account. Subsequently, you may be able to control your settings through the third party&rsquo;s platform or service. For example, you may access and change your settings through your Google settings page. If you withdraw our ability to access certain information from a third party platform or social media network, that choice will not apply to information that we have already received from that third party.</p>
<p>Security</p>
<p>The security of your personal information is important to us. &nbsp;We employ a number of organizational, technical and physical safeguards designed to protect the personal information we collect. &nbsp;However, security risk is inherent in all internet and information technologies and we cannot guarantee the security of your personal information.</p>
<p>Third Party Sites and Services</p>
<p>The Service may contain links to other websites, mobile applications, and other online services operated by third parties. &nbsp;These links are not an endorsement of, or representation that we are affiliated with, any third party. &nbsp;In addition, our content may be included on web pages or in mobile applications or online services that are not associated with us. We do not control third party websites, mobile applications or online services, and we are not responsible for their actions. &nbsp;Other websites, mobile applications and services follow different rules regarding the collection, use and sharing of your personal information. &nbsp;We encourage you to read the privacy policies of the other websites, mobile applications and online services you use.</p>
<p>A note to users outside the United States</p>
<p>Top Talent Technologies Inc. is headquartered in the United States and operates a U.S. website subject to U.S. laws, including those governing the privacy and security of your information. Your personal information may be collected, used and stored in the United States or other locations outside of your state, province, country or other governmental jurisdiction, where privacy laws may not be as protective as those in your jurisdiction. We will protect your personal information as described in this Privacy Policy.</p>
<p>Children</p>
<p>The Service is not directed at, and we do not knowingly acquire or receive personal information from, children under the age of 16. If we learn that any user of the Service is under the age of 16, we will take appropriate steps to delete that individual&rsquo;s personal information and restrict that individual from future access to the Service.</p>
<p>Contact information</p>
<p>We welcome your comments or questions about this Privacy Policy. You can contact us at billing@thetalentcommunity.com or write to us at:</p>
<p>Top Talent Technologies Inc.</p>
<p>45188 Tom Blalock Street #311</p>
<p>Fremont, CA 94539</p>
<p>Changes to this Policy</p>
<p>We reserve the right to modify this Privacy Policy at any time. If we make material changes to this Privacy Policy, we will notify you by updating the date of this Privacy Policy and posting it on the Service. We may, and if required by law will, also provide notification of changes in another way that we believe is reasonably likely to reach you, such as via e-mail (if you have an account where we have your contact information) or another manner through the Service.</p>
<p>Any modifications to this Privacy Policy will be effective upon our posting the new terms and/or upon implementation of the new changes on the Service (or as otherwise indicated at the time of posting). In all cases, your continued use of the Service after the posting of any modified Privacy Policy indicates your acceptance of the terms of the modified Privacy Policy.</p>
<p>Important Information for California Residents</p>
<ol>
    <li>
        <p>California Shine the Light Act. Pursuant to Section 1798.83 of the California Civil Code, residents of California have the right to request from a business with whom the California resident has an established business relationship, certain information with respect to the types of Personal Information the business shares with third parties for direct marketing purposes by such third party and the identities of the third parties with whom the business has shared such information during the immediately preceding calendar year.To request a copy of the information disclosure provided by Us pursuant to Section 1798.83 of the California Civil Code, You must contact Us at billing@thetalentcommunity.com with &ldquo;California Privacy Request&rdquo; in the first line.Please note that under this law, We are not required to respond to Your request more than once in a calendar year, nor are We required to respond to any request that is not sent to the designated e-mail or mailing address.</p>
    </li>
    <li>
        <p>California Do Not Track Disclosure. Do Not Track is a privacy preference that some Users may set in their web browsers. When a User turns on the Do Not Track signal, the browser sends a message to websites requesting them not to track the User. At this time, We do not recognize or respond to Do Not Track browser settings or signals and We will still receive information. As a result, We may still collect information about You and Your internet activity, even if You have turned on the Do Not Track signal.</p>
    </li>
    <li>
        <p>California Consumer Protection Act. Pursuant to the California Consumer Protection Act, the following additional disclosures and consumer rights, updated, on an annual basis, apply to California residents who access or use Services:</p>
    </li>
</ol>
<p>&nbsp;</p>
<p>&nbsp;</p>

        </Box>
    )
}