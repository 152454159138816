import client from ".."
export default class Search {

  static save = async data => {
    const response = client.post('search/saved', data)
    return response
  }

  static getSaved = async data => {
    const savedSearch = client.get(`search/saved/all?pageNo=${data.page}&recordsPerPage=${data.limit}&recruiterMailId=${data.mailId}`)
    return savedSearch
  }

  static run = async data => {
    const response = client.get(`search/saved/run?pageNo=${data.page}&recordsPerPage=${data.limit}&name=${data.name}`)
    return response
  }

  static getRoles = async (url = 'signup/roles') => {
    const response = client.get(url)
    return response
  }

  static roles = async data => {
    const response = client.post(`search/role?pageNo=${data.page}&recordsPerPage=${data.limit}&role=${data.role}`)
    return response
  }

  static getLocations = async () => {
    const response = client.get('recruiter/distinctStateCity')
    return response
  }

}